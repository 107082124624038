import Env from "../env/Env";
import {CartItem} from "../store/reducers/cart";
import {ProductId} from "../models/Product";

export interface Country {
  iso_2: string
  iso_3: string
  name: string
}

const notSupportedCountryIso2Codes = ['RU', 'KR']

export interface CountryState {
  abbr: string,
  code: string
  country_iso_2: string
  name: string
}

const ProductBoxTypeMapper:{[key:string]: string} = {
  [ProductId.TactsuitX16]: "tactsuitX16",
  [ProductId.TactsuitX40]: "tactsuitX40",
  [ProductId.TactotForBackPackPc]: "tactsuitX40",
  [ProductId.TactosyForArms]: "tactosyForArms",
  [ProductId.TactosyForFeet]: "tactosyForHandsOrFeet",
  [ProductId.TactosyForHands]: "tactosyForHandsOrFeet",
  'tactal-wide': "tactalWide",
  'tactal-standard': "tactalStandard",
  [ProductId.MeshLiningForTactsuitX]: "tactsuitXMeshLining",
  [ProductId.InnerLiningForTactosyForArms]: "tactosyForArmsInnerLining",
  [ProductId.TactsuitXAudioAccessories]: "tactsuitXAudioAccessories",
}

class Shipping {
  countries: Country[] = [];

  public getCountries = async (): Promise<Country[]> => {
    if (this.countries.length === 0) {
      const res = await fetch(`${Env.ApiUrl}/shipping/countries/v1`);
      if (res.ok) {
        const countries = await res.json()
        this.countries = countries.filter((country: Country) => notSupportedCountryIso2Codes.indexOf(country.iso_2) < 0)
      } else {
        this.countries = []
      }
    }
    return this.countries;
  };

  public getCountryStates = async (iso_2_code: string): Promise<CountryState[]> => {
    if (!!iso_2_code) {
      try {
        const res = await fetch(`${Env.ApiUrl}/shipping/states?country_iso_2=${iso_2_code}`);
        if (res.ok) {
          return await res.json();
        } else {
          return []
        }
      } catch (e) {
        console.error(e)
        return []
      }
    }
    return []
  };

  private itemsToProductBoxes:(items: CartItem[]) => {type:string, qty: number}[] = (items) => {
    return items.map((item) => {
      let id = item.id
      if (id === 'tactal') {
        if (!!item.variants && item.variants[0].kind === 'wide') {
          id += '-wide'
        }
        else {
          id += '-standard'
        }
      }
      return {
        type: ProductBoxTypeMapper[id],
        qty: item.qty
      }
    })
  }

  public getPrice: (props: { country_iso_2: string, courier?: string, cartItems: CartItem[] }) => Promise<number> =
    async ({country_iso_2, courier = 'ups', cartItems}) => {
      if (!country_iso_2 || !Array.isArray(cartItems) || !cartItems.length) {
        return 0
      }
      try {
        const res = await fetch(`${Env.ApiUrl}/shipping/price/v1`, {
          method: "POST",
          body: JSON.stringify({
            country_iso_2,
            courier,
            boxes: this.itemsToProductBoxes(cartItems)
          })
        })
        if (res.ok) {
          return await res.json()
        }
        return -1
      } catch (e) {
        console.error(e)
        return -1
      }
  }
}

export default new Shipping();
