import { ConfigActionType, ConfigState, Locale } from "../reducers/config";

export const updateConfig = (config: ConfigState) => (
  dispatch: any,
  getState: any
) => {
  dispatch({ type: ConfigActionType.UpdateConfig, payload: config });
};

export const updateLocale = (locale: Locale) => (
  dispatch: any,
  getState: any
) => {
  const { config } = getState();
  dispatch({
    type: ConfigActionType.UpdateLocale,
    payload: { ...config, locale }
  });
};
