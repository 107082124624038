/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC} from "react";
import {ProductList} from "../components/shop/productList";
import Intro from "../components/shop/Intro";
import Notifier from "../components/shop/Notifier";
import Bookmark from "../components/shop/Bookmark";
import PreorderIntro from "../components/shop/PreorderIntro";

type Props = {};

const ShopPage: FC<Props> = () => {
  return (
    <div css={css`background-color: #0f1014;`}>
      {/*<PreorderIntro />*/}
      <Intro />
      <Bookmark />
      <Notifier />
      <ProductList />
    </div>
  );
};

export default ShopPage;
