/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import {FC} from "react";
import {cx} from "emotion";
import {useSelector} from "react-redux";
import {translationProductDetailsSelector} from "../../../store/selectors/translationSelector";
import {localeSelector} from "../../../store/selectors/configSelector";
import Validator from "../../../utils/Validator";
import {productSelector} from "../../../store/selectors/productsSelector";

type Props = {
  className?:string,
  id: string,
}

const Name:FC<Props> = ({id, className=''}) => {
  const product = useSelector(productSelector(id))
  const translationProductDetails = useSelector(translationProductDetailsSelector)
  const locale = useSelector(localeSelector);
  let name = Validator.tryGetValue(translationProductDetails, id, `name_${locale.languageCode}`)

  if (!name) {
    name = product.name
  }

  return (
    <span
      className={cx(
        className,
        'text-2xl xl:text-4xl font-extrabold leading-none'
      )}
    >{name}</span>
  )
}

export default Name