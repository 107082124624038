/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC} from "react";
import {useSelector} from "react-redux";
import {translationFeatureSelector, translationGeneralSelector} from "../../../store/selectors/translationSelector";
import Validator from "../../../utils/Validator";
import allInOne from './images/desktop/allInOne.png'
import detachable from './images/desktop/detachable.png'
import forty from './images/desktop/forty.png'
import lock from './images/desktop/lock.png'
import onesSizeFitsAll from './images/desktop/oneSizeFitsAll.png'
import quickSuitUp from './images/desktop/quickSuitUp.png'
import sixPoints from './images/desktop/sixPoints.png'
import twelve from './images/desktop/twelve.png'
import sixteen from './images/desktop/sixteen.png'
import desktopX40Bg from './images/desktop/img-features-x40.jpg'
import mobileX40Bg from './images/mobile/tactsuitX40.jpg'
import desktopX16Bg from './images/desktop/img-features-x16.jpg'
import mobileX16Bg from './images/mobile/tactsuitX16.jpg'
import desktopBackpackBg from './images/desktop/img-features-backpack.jpg'
import mobileBackpackBg from './images/mobile/backpack.jpg'
import desktopTactosyBg from './images/desktop/img-features-tactosy.jpg'
import mobileTactosyBg from './images/mobile/tactosy.jpg'
import vive from './images/desktop/vive.png'
import {ProductId} from "../../../models/Product";
import {localeSelector} from "../../../store/selectors/configSelector";
import {cx} from "emotion";

type Props = {
  id: ProductId,
  className?:string
};

const images: {[key: string]: string[]} = {
  'tactsuit-x40': [quickSuitUp, forty, onesSizeFitsAll, detachable],
  'tactsuit-x16': [quickSuitUp, sixteen, onesSizeFitsAll, detachable],
  'tactot-for-backpack-pc': [allInOne, forty, onesSizeFitsAll, detachable],
  'tactosy-for-arms': [twelve, quickSuitUp, onesSizeFitsAll, detachable],
  'tactosy-for-hands': [sixPoints, vive, lock, detachable],
  'tactosy-for-feet': [sixPoints, vive, lock, detachable],
}

const bgs:{[key: string]: string[]} = {
  'tactsuit-x40': [desktopX40Bg, mobileX40Bg],
  'tactsuit-x16': [desktopX16Bg, mobileX16Bg],
  'tactot-for-backpack-pc': [desktopBackpackBg, mobileBackpackBg],
  'tactosy-for-arms': [desktopTactosyBg, mobileTactosyBg],
  'tactosy-for-hands': [desktopTactosyBg, mobileTactosyBg],
  'tactosy-for-feet': [desktopTactosyBg, mobileTactosyBg],
}

const Features: FC<Props> = ({ id, className='' }) => {
  const translationFeature = useSelector(translationFeatureSelector)
  const locale = useSelector(localeSelector)
  const translationGeneral = useSelector(translationGeneralSelector)
  let head = Validator.tryGetValue(translationGeneral, 'features', locale.languageCode)
  if (!head) {
    head = 'Features'
  }

  const valid = id in images;

  let data:{[key: string]: any}[] = Validator.tryGetValue(translationFeature, id)
  if (!!data && valid) {
    data = [...data].map((value: any, index:number) => ({...value, src: images[id][index]}))
  }

  return !!data ?
    <div className={cx("w-full text-white mx-auto bg-black")}>
      <div
        css={css`background-color: #18191d;`}
        className={"max-w-screen-xl mx-auto w-full justify-center items-center flex flex-col md:flex-row"}
      >
        <div
          css={css`
            height: 34.5rem;
            @media(max-width: 767px) {height: 24rem}
          `}
          className={'flex-1 w-full'}>
          <img src={bgs[id][0]} className={'hidden sm:block object-cover h-full w-full'} />
          <img src={bgs[id][1]} className={'block sm:hidden object-cover h-full w-full'} />
        </div>
        <div className={'flex-1 w-full'}>
          <div className={'flex flex-col justify-center py-8 lg:py-0 px-5 lg:px-16'}>
            <span className={'text-3xl lg:text-4xl font-extrabold'}>{head}</span>
            <div className={'mt-5 lg:mt-10 flex flex-col'}>
              {
                data.map((value, index) => {
                  return (
                    <div
                      key={index}
                      className={'mb-2.5 lg:mb-5 flex items-center'}
                    >
                      <img
                        css={css`min-width: 3.125rem; min-height: 3.125rem; width: 3.125rem; height: 3.125rem`}
                        className={'p-0.5 object-contain'} src={value.src}
                      />
                      <div className={'ml-2.5 flex flex-col justify-center'}>
                        <span className={'text-lg md:text-xl font-bold'}>{value[`title_${locale.languageCode}`]}</span>
                        <span
                          css={css`color: #979797; line-height: 1`}
                          className={'text-sm lg:text-base mt-0.5'}>{value[`description_${locale.languageCode}`]}</span>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    :
    null
}

export default Features;
