import produce from "immer";

export interface Subscription {
  email: string,
  active: boolean
}

const initialState: Subscription = {
  email: '',
  active: true
}

export enum SubscriptionActionType {
  UpdateSubscription = "subscription/updateSubscription",
}

type SubscriptionAction =
  | {type: SubscriptionActionType.UpdateSubscription; payload: Subscription}

export const subscription = produce(
  (draft: Subscription=initialState, action: SubscriptionAction) => {
    switch (action.type) {
      case SubscriptionActionType.UpdateSubscription: {
        draft = action.payload;
        return draft
      }
      default:
        return draft
    }
  }
)