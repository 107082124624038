import produce from "immer";
import {Personal} from "../../interfaces/personal";
import {Address} from "../../models/Address";

export interface RecipientState {
  personal: Personal,
  address: Address,
}

const initialState: RecipientState = {
  personal: {
    email: '',
    firstName: '',
    lastName: '',
    tel: '',
  },
  address: {
    address1: '',
    address2: '',
    zip: '',
    city: '',
    state: '',
    country: {
      iso_2: '',
      iso_3: '',
      name: '',
    }
  }
}

export enum RecipientActionType {
  UpdateRecipient = "recipient/updateRecipient",
  UpdateRecipientAddress = "recipient/updateRecipientAddress",
  UpdateRecipientPersonal = "recipient/updateRecipientPersonal",
}

type RecipientAction =
  | {type: RecipientActionType.UpdateRecipient; payload: RecipientState}
  | {type: RecipientActionType.UpdateRecipientAddress; payload: Address}
  | {type: RecipientActionType.UpdateRecipientPersonal; payload: Personal}

export const recipient = produce(
  (draft: RecipientState=initialState, action: RecipientAction) => {
    switch (action.type) {
      case RecipientActionType.UpdateRecipient: {
        draft =  action.payload;
        return draft
      }
      case RecipientActionType.UpdateRecipientAddress: {
        draft.address =  action.payload;
        return draft
      }
      case RecipientActionType.UpdateRecipientPersonal: {
        draft.personal =  action.payload;
        return draft
      }
      default:
        return draft
    }
  }
)
