import { createSelector } from "reselect";
import { RootState } from "../reducers";
import { ConfigState } from "../reducers/config";

export const configSelector = (state: RootState): ConfigState => state.config;

export const localeSelector = createSelector(
  [configSelector],
  (config: ConfigState) => config.locale
);
