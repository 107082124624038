/** @jsx jsx */
import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  canBuySelector,
  checkedCartItemsSelector,
  checkedSubTotalSelector
} from "../store/selectors/cartSelector";
import { css, jsx } from "@emotion/core";
import CartTable from "../components/cart/CartTable";
import { cx } from "emotion";
import CartUtil from "../utils/CartUtil";
import { push } from "connected-react-router";
import {localeSelector} from "../store/selectors/configSelector";
import {translationGeneralSelector} from "../store/selectors/translationSelector";
import Validator from "../utils/Validator";
import {Routes} from "../models/Enums";
import {updateNotification} from "../store/actions/notificationAction";

const Checkout: FC<{ className?: string }> = ({ className }) => {
  const locale = useSelector(localeSelector)
  const translationGeneral = useSelector(translationGeneralSelector)

  return (
    <span
      className={cx(
        className,
        "font-extrabold text-white py-2 px-4 rounded w-40 flex justify-center mx-2"
      )}
    >
      {Validator.tryGetValue(translationGeneral, 'checkout', locale.languageCode)}
  </span>
  )
};

type Props = {};

const CartPage: FC<Props> = () => {
  const locale = useSelector(localeSelector)
  const dispatch = useDispatch()
  const translationGeneral = useSelector(translationGeneralSelector)
  const canBuy = useSelector(canBuySelector);
  const checkedCartItems = useSelector(checkedCartItemsSelector);
  const checkedSubTotal = useSelector(checkedSubTotalSelector);
  const unit = "$";

  const handleClick = (e: any) => {
    if (CartUtil.accessoriesOnly(checkedCartItems)) {
      dispatch(updateNotification({type: 'notAccessoriesOnly', on:true}))
    } else {
      dispatch(push(Routes.CheckoutPage));
    }
  };

  return (
    <div
      className={"w-full flex justify-center items-start"}
      css={css`
        @media(max-height: 100vh) {
          flex-grow: 1;
          background-color: #0f1014;
        }
        & * span {user-select: none;}
        & * input {cursor: pointer;}
        background-color: #0f1014;
      `}
    >
      <div className={"w-full max-w-screen-xl flex flex-col text-white relative items-center"}>
        <span className={"font-black text-2xl mt-10 md:mt-24 leading-10"}>
          <div>{Validator.tryGetValue(translationGeneral, 'shoppingCart', locale.languageCode)}</div>
          <div css={css`background-color: #1e1e21;`} className={"w-full h-2 my-1"} />
        </span>
        <div className={"mt-16 w-full px-1 sm:px-10 lg:px-24 xl:px-56"}>
          <CartTable />
          <span className={"flex justify-end p-4 w-full text-base md:text-2xl font-extrabold leading-10"}>
            {Validator.tryGetValue(translationGeneral, 'subtotal', locale.languageCode)} : {`${unit} ${checkedSubTotal.toFixed(2)}`}
          </span>
          <div className={"flex justify-center my-10 md:my-20 text-xl"}>
            {
              canBuy ? (
                <div className={"cursor-pointer"} onClick={handleClick}>
                  <Checkout
                    className={"hover:opacity-75"}
                    css={css`background-color: #0091ff;`} />
                </div>
              ) : (
                <Checkout className={"bg-gray-500 pointer-events-none"} />
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPage;