/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useEffect, useRef, useState} from "react";
import {Variant} from "../../../models/Product";
import {cx} from "emotion";
import Parser from "../../../utils/Parser";
import Validator from "../../../utils/Validator";
import {useSelector} from "react-redux";
import {localeSelector} from "../../../store/selectors/configSelector";
import {translationGeneralSelector} from "../../../store/selectors/translationSelector";
import DetailVariant from "./DetailAboutVariant";
import DescribeVariantType from "./DescribeVariantType";
import styled from "@emotion/styled";

type Props = {
  className?:string
  variants?: Variant[],
  onVariants: (variants: Variant[]) => void,
  id: string,
}

const Line = styled.span`
background-color: #303030;
height: 1px;
display: none;
@media(max-width: 639px) {
  width: 100%;
  display: block;
}
`

const Kind = (props:any) =>
  <span
    css={css`
      color: ${props.on ? "#ffffff" : "#878787"};
      min-width: 5rem;
    `}
    className={cx('capitalize leading-none w-20 pointer-events-none')}
  >
    {props.kind}
  </span>

const Circle = (props: any) =>
  <span
    css={css`
      background-color: ${props.on ? '#0091ff' : ''};
      min-width: 0.875rem; 
      min-height: 0.875rem;
    `}
    className={'h-3.5 w-3.5 rounded-full bg-transparent inline-block'}
  />

const SelectVariants:FC<Props> = ({id, className='', variants=undefined, onVariants}) => {
  const locale = useSelector(localeSelector)
  const translationGeneral = useSelector(translationGeneralSelector)
  const ref:any = useRef();
  const [state, setState] = useState<any>({
    variants: []
  })
  const [detail, setDetail] = useState({
    on: false,
    type: '',
    kind: ''
  })

  const handleClickOutside = ({ target }:any) => {
    if (ref.current && !ref.current.contains(target))  {
      setDetail((state: any) => ({...state, on: false}));
    }
    document.removeEventListener("click",handleClickOutside);
  };

  useEffect(() => {
    if (!!variants && !!variants.length) {
      const types = Parser.getVariantsTypes(variants)
      setState((state:any) => ({...state, variants: types.map((type, index) => ({type, kind: ''}))}))
    }
  }, [variants])

  let merged:any = null
  if (!!variants && !!variants.length) {
    merged = Parser.groupVariantsByTypes(variants)
  }

  const updateVariant = (type: string, kind: any) => (e:any) => {
    document.addEventListener("click",handleClickOutside);
    const nextVariants = state.variants.map((variant: any, index: number) => variant.type === type ? {type, kind} : variant)
    setState((state:any) => ({
      ...state,
      variants:nextVariants
    }))
    setDetail((state:any) => ({...state, kind, type, on: true}))
    ref.current = e.target
    onVariants(nextVariants)
  }

  const toggleDetail = (e:any) => {
    e.stopPropagation()
    setDetail((state: any) => ({...state, on: !state.on}))
    document.removeEventListener("click",handleClickOutside);
  }

  return (
    <div className={cx(className)}>
      {
        !!merged &&
        Object
          .keys(merged)
          .map((key, index) => {
            const kinds:string[] = merged[key]
            const friendly = (key: string) => {
              const res = Validator.tryGetValue(translationGeneral, `select${key}`, locale.languageCode)
              switch (key) {
                case 'Size': {
                  return !!res ? res : 'Select Size'
                }
                case 'TactSuit': {
                  return !!res ? res : 'Select TactSuit X'
                }
                default: {
                  return !!res ? res : `Select ${key}`
                }
              }
            }

            let type = Validator.tryGetValue(translationGeneral, key, locale.languageCode)
            if (!type) {
              type = key
            }
            const filtered = state.variants.filter((variant: any, index: number) => variant.type === key)
            const varianted = !!filtered && !!filtered.length && !!filtered[0].kind

            return (
              <div className={'flex px-4 md:px-0 py-1.5 flex-col'} key={index}>
                <span
                  className={cx(
                    varianted ? 'h-0' : 'h-8',
                    'font-bold transition-all duration-1000 ease-in-out overflow-hidden text-base'
                  )}
                  css={css`color: #ff0000;;`}
                >
                  {friendly(key)}
                </span>
                <div className={cx('flex')}>
                  <div css={css`background-color: #303030;`} className={'flex items-center justify-center'}>
                    <span className={'capitalize font-bold px-1.5'}>{type}</span>
                  </div>
                  <div className={'flex flex-grow flex-col pl-5'}>
                    <Line />
                    {
                      kinds.map((kind, kindIndex) => {
                        const last = kinds.length - 1 === kindIndex
                        const on = !!filtered.length && filtered[0].kind === kind
                        let _kind = Validator.tryGetValue(translationGeneral, kind, locale.languageCode)
                        if (!_kind) {
                          _kind = kind;
                        }
                        const target = detail.on && detail.type === key && detail.kind === kind
                        return (
                          <div key={kindIndex} className={'flex flex-col items-baseline sm:py-2.5'}>
                            <div
                              className={'flex pt-4 sm:pt-0 items-baseline cursor-pointer'}>
                              <div
                                onClick={updateVariant(key, kind)}
                                className={'h-5 w-5 relative rounded-full flex items-center justify-center'}
                                css={css`
                                background-color: #323234;
                                min-width: 1.25rem; min-height: 1.25rem;
                              `}
                              >
                                <Circle on={on} />
                              </div>
                              <div className={'ml-2 flex flex-col sm:flex-row'}>
                                <Kind kind={_kind} on={on} />
                                <DescribeVariantType
                                  css={css`
                                  margin-left: 0.875rem;
                                  @media(max-width: 639px) {margin-left: 0}
                                `}
                                  id={id} variant={{type: key, kind}} />
                              </div>
                            </div>
                            <Line className={cx({'hidden': last}, 'mt-4')} />
                            {target && <DetailVariant id={id} ref={ref} onToggle={toggleDetail} variant={{type: detail.type, kind: detail.kind}} />}
                          </div>
                        )
                      })
                    }
                    <Line />
                  </div>
                </div>
              </div>
            )
          })
      }
    </div>
  )
}

export default SelectVariants