/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC, Suspense, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import ShopPage from "./pages/ShopPage";
import CartPage from "./pages/CartPage";
import ProductPage from "./pages/ProductPage";
import CheckoutPage from "./pages/CheckoutPage";
import TrackingPage from "./pages/TrackingPage";
import OfferPage from "./pages/OfferPage";
import Navbar from "./components/navbar";
import GaUtil from "./utils/GaUtil";
import { useDispatch, useSelector } from "react-redux";
import { locationSelector } from "./store/selectors/routerSelector";
import Notification from "./components/notification";
import Cookies from "./components/cookies";
import { importFiles } from "./utils/CommonUtil";
import { fetchTranslations } from "./store/actions/translationAction";
import Footer from "./components/footer";
import { localeSelector } from "./store/selectors/configSelector";
import NewShopTrackingPage from "./pages/NewShopTrackingPage";
import NewShopOfferPage from "./pages/NewShopOfferPage";
import NewShopPage from "./pages/NewShopPage";
import Loading from "./components/loading";
import NotFoundPage from "./pages/NotFoundPage";
import LocaleUtil from "./utils/LocaleUtil";
import { fetchProducts } from "./store/actions/productsAction";
import { productsData } from "./data/productsData";
import { Routes } from "./models/Enums";
import { cx } from "emotion";

const App: FC<{}> = ({}) => {
  const dispatch = useDispatch();
  const location = useSelector(locationSelector);
  const locale = useSelector(localeSelector);
  const whitelist = ['127.0.0.1', "shop2.bhaptics.com", 'dev-shop.bhaptics.com']

  console.log(whitelist.indexOf(window.location.hostname));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    initialize();
  }, []);


  const initialize = () => {
    GaUtil.initialize();
    GaUtil.logEvent("app_started");
    const translations = importFiles(
      require.context("./translations", false, /\.(csv)$/)
    );
    dispatch(fetchProducts(productsData));
    dispatch(fetchTranslations(translations));
  };

  return (
    <div
      css={css`
          outline: none;
          &:focus {outline: none;}
        }
        font-family: ${LocaleUtil.getFontFamily(locale.languageCode)};
      `}
      className="App min-h-screen w-full flex flex-col items-center"
    >
      <Suspense fallback={null}>
        <Cookies />
        <Notification />
        {whitelist.indexOf(window.location.hostname) < 0 && <Loading />}
        <Navbar />
        <div className={cx("w-full flex-grow flex-col flex")}>
          {
            whitelist.indexOf(window.location.hostname) >= 0 ?
              <Switch>
                <Route exact path={Routes.IndexPage} component={ShopPage} />
                <Route exact path={Routes.ProductPage} component={ProductPage} />
                <Route exact path={Routes.CartPage} component={CartPage} />
                <Route exact path={Routes.CheckoutPage} component={CheckoutPage} />
                <Route path={"*"} component={NotFoundPage} />
                <Route exact path={Routes.OfferPage} component={OfferPage} />
                <Route exact path={Routes.TrackingPage} component={TrackingPage}/>
                {/*<Route path={"*"} component={NewShopPage} />*/}
              </Switch>
              :
              <Switch>
                {/*<Route exact path={Routes.IndexPage} component={ShopPage} />
                <Route exact path={Routes.ProductPage} component={ProductPage} />
                <Route exact path={Routes.CartPage} component={CartPage} />
                <Route exact path={Routes.CheckoutPage} component={CheckoutPage} />
                <Route path={"*"} component={NotFoundPage} /> */}
                <Route exact path={Routes.OfferPage} component={NewShopOfferPage} />
                <Route exact path={Routes.TrackingPage} component={NewShopTrackingPage}/>
                <Route path={"*"} component={NewShopPage} />
              </Switch>
          }
        </div>
      </Suspense>
      <Footer />
    </div>
  );
};

export default App;
