class StringUtils {
  public static isEmptyOrSpaces = (str: string): boolean => {
    if (str === undefined) {
      return true;
    }

    return str === null || str.match(/^ *$/) !== null;
  };

  public static toCapitalize = (str: string): string => {
    const [first, ...rest] = str
    return `${first.toUpperCase()}${rest.join("")}`
  }

  public static generateNumberString = (): string => {
    const num = Math.floor(Math.random() * 999) + 1;

    if (num < 10) {
      return "00" + num;
    }

    if (num < 100) {
      return "0" + num;
    }

    return "" + num;
  };
}

export default StringUtils;
