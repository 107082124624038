import {
  ConfigState,
  initialConfigState,
} from "../store/reducers/config";

class ConfigLocalStorageService {
  private configPrefix = "_bhaptics_shop_config__v1__";
  private localStorage: any;
  constructor() {
    this.localStorage = localStorage;
  }

  public setConfig = (config: ConfigState) => {
    const data = {
      config,
      time: new Date().getTime()
    };
    this.localStorage.setItem(this.configPrefix, JSON.stringify(data));
  };

  public getConfig = (): ConfigState => {
    const dataStr = this.localStorage.getItem(this.configPrefix);
    const data = JSON.parse(dataStr);
    if (!!data) {
      const currentTime = new Date().getTime();
      if (!!data.time && currentTime - data.time > 1000 * 3600 * 24) {
        return initialConfigState;
      }
      try {
        // todo - if error occurs
        return data.config;
      } catch (e) {
        console.log(e);
      }
    }
    return initialConfigState;
  };
}

export default new ConfigLocalStorageService();
