/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useEffect} from "react";
import { useHistory } from "react-router-dom";
import {useDispatch} from "react-redux";
import {startLoading, stopLoading} from "../store/actions/loadingAction";
import {Routes} from "../models/Enums";

type Props = {
}

const NotFoundPage:FC<Props> = () => {
  let history = useHistory();
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(startLoading)
    const id = setTimeout(() => {
      if (!!history) {
        history.push(Routes.IndexPage)
      }
    }, 2000)

    return () => {
      dispatch(stopLoading)
      if (!!id) {
        clearTimeout(id)
      }
    }
  }, [])

  return (
    <div
      css={css`
        @media(max-height: 100vh) {
          flex-grow: 1;
          background-color: #0f1014;
        }
      `}
      className={"w-full flex items-center h-full justify-center bg-black"}
    >
      <div className={'flex flex-col items-center justify-center'}>
        <span className={'text-white text-3xl'}>Page Not Found</span>
        <span className={'text-white text-xl'}>Will redirect to main page</span>
      </div>
    </div>
  )
}

export default NotFoundPage