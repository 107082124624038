/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC, useEffect, useMemo, useState } from "react";
import Portal from "../portal";
import { cx } from "emotion";
import { keyframes } from "@emotion/core";
import { useDispatch, useSelector } from "react-redux";
import { loadingSelector } from "../../store/selectors/loadingSelector";
import { stopLoading } from "../../store/actions/loadingAction";

const spin = keyframes`
0% {transform: rotate(0deg);}
100% {transform: rotate(360deg);}
`;

type Props = {
  className?: string;
};

const Loading: FC<Props> = ({ className = "" }) => {
  const loading = { on: true };
  const dispatch = useDispatch();
  let timeout: any;

  return loading.on ? (
    <Portal
      css={css`
        z-index: 999 !important;
      `}
    >
      <div
        css={css`
          background: rgba(0, 0, 0, 0.25);
          backdrop-filter: blur(1px);
        `}
        className={
          "z-50 fixed inset-0 w-full h-full flex items-center justify-center"
        }
      >
        <div
          className={"w-24 sm:w-32 h-24 sm:h-32"}
          css={css`
            background-color: #1e1e21;
            border: 16px solid #f3f3f3;
            border-top: 16px solid #3498db;
            border-radius: 50%;
            animation: ${spin} 2s linear infinite;
          `}
        />
      </div>
    </Portal>
  ) : null;
};

export default Loading;
