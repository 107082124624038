/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useEffect, useState} from "react";
import {Product, Variant} from "../../../models/Product";
import {importFiles} from "../../../utils/CommonUtil";
import {cx} from "emotion";
import ProductSlider from "./ProductSlider";
import SelectVariants from "./SelectVariants";
import Count from "./Count";
import equal from "fast-deep-equal/react";
import soldOut_en from './images/desktop/soldOut-en.png'
import soldOut_cn from './images/desktop/soldOut-cn.png'
import soldOut_jp from './images/desktop/soldOut-jp.png'
import {useDispatch, useSelector} from "react-redux";
import {localeSelector} from "../../../store/selectors/configSelector";
import Parser from "../../../utils/Parser";
import {cartItemsSelector} from "../../../store/selectors/cartSelector";
import {addCartItem, updateCartItemV2} from "../../../store/actions/cartAction";
import ToDetailPage from "./ToDetailPage";
import {push} from "connected-react-router";
import {Routes} from "../../../models/Enums";
import CartUtil from "../../../utils/CartUtil";
import Notify from "./Notify";
import Added from "./Added";
import Button from "./Button";
import Summary from "./Summary";
import Name from "./Name";
import Validator from "../../../utils/Validator";

type Props = {
  product:Product,
  className?:string
}

const soldOutSrcs: any = {
  'en': soldOut_en,
  'cn': soldOut_cn,
  'jp': soldOut_jp,
}

const Disabled = () =>
  <div className={'absolute inset-0 z-10 pointer-events-none select-none cursor-not-allowed'} />

const ProductCard:FC<Props> = ({className='', product}) => {
  let timer:any;
  const dispatch = useDispatch()
  const [added, setAdded] = useState(false)
  const [notification, setNotification] = useState({type: '', on: false})
  const cartItems = useSelector(cartItemsSelector)
  const [countable, setCountable] = useState(true)
  const soldOut = ['tactot', 'tactot-for-backpack-pc'].includes(product.id)
  const [range, setRange] = useState({
    max: 9,
    offset: 0
  })
  const [candidate, setCandidate] = useState<{
    count: number,
    variants: undefined | Variant[]
  }>({
    count: 1,
    variants: undefined
  })
  const locale = useSelector(localeSelector)

  useEffect(() => {
    return () => {
      if (!!timer) {
        clearTimeout(timer)
      }
    }
  }, [])

  useEffect(() => {
    if (!!product && soldOut) {
      setCandidate((state: any) => ({
        ...state,
        count: 0
      }))
    }
    if (!!product.variants && !!product.variants.length) {
      const types = Parser.getVariantsTypes(product.variants)
      setCandidate((state: any) => ({
        ...state,
        variants: types.map((type) => ({type, kind: ''}))
      }))
    }
  }, [product])

  useEffect(() => {
    trySetRange()
    tryActivateCount()
  }, [cartItems, candidate.variants])

  const tryActivateCount = () => {
    const canVariantsSelectable = Array.isArray(product.variants) && product.variants.length >= 2;
    if (canVariantsSelectable && candidate.variants) {
      const allSettled = candidate.variants
        .map((variant) => Validator.getAllValues(variant))
        .reduce((prev, current, index) => prev.concat(current), [])
        .filter(value => value === '').length === 0
      setCountable(allSettled)
    }
    else {
      setCountable(true)
    }
  }

  const trySetRange = () => {
    let filtered = cartItems.filter((cartItem) => cartItem.id === product.id)
    if (filtered.length) {
      if (!!candidate.variants) {
        filtered = filtered.filter((cartItem) => equal(cartItem.variants, candidate.variants))
        if (!!filtered[0]) {
          setRange((state:any) => ({...state, offset: filtered[0].qty}))
        }
        else {
          setRange((state:any) => ({...state, offset: 0}))
        }
      }
      else {
        setRange((state:any) => ({...state, offset: filtered[0].qty}))
      }
    }
  }

  const images = importFiles(require.context("./images/desktop", false, /\.(png|jpe?g|svg)$/));
  const imagesKeys = Object.keys(images).filter(key =>
    new RegExp(`^${product.id}-[0-9].png`, "g").test(key)
  );
  const srcs = imagesKeys.map((key) => images[key])
  const variants = 'variants' in product ? product.variants : undefined
  let clickable = !!candidate.count && (range.max > range.offset);

  if (candidate.variants !== undefined) {
    clickable = clickable && !candidate.variants.filter((variant) => !variant.kind).length
  }

  const updateCount = (count: number) => {
    setCandidate((state: any) => ({...state, count}))
  }

  const addToCart = (e:any) => {
    let filtered = cartItems.filter((cartItem) => cartItem.id === product.id)
    if (!filtered.length) {
      dispatch(addCartItem({...product, checked: true, variants: candidate.variants, qty: candidate.count}))
      setCandidate((state: any) => ({...state, count: range.max > candidate.count ? 1 : 0}))
    }
    else {
      filtered = filtered.filter((cartItem) => equal(cartItem.variants, candidate.variants))
      if (!!filtered[0]) {
        dispatch(updateCartItemV2({...filtered[0], qty: filtered[0].qty + candidate.count}))
        setCandidate((state: any) => ({...state, count: range.max > (filtered[0].qty + candidate.count) ? 1: 0}))
      }
      else {
        dispatch(addCartItem({...product, checked: true, variants: candidate.variants, qty:candidate.count}))
        setCandidate((state: any) => ({...state, count: range.max > candidate.count ? 1 : 0}))
      }
    }
    setAdded((state:any) => {
      timer = setTimeout(() => {
        setAdded(false)
      }, 1000)
      return true
    })
  }

  const buyNow = (e:any) => {
    const accessoriesOnly =
      ((!cartItems || !cartItems.length) && product.category === 'accessory') ||
      (CartUtil.accessoriesOnly(cartItems) && product.category === 'accessory')

    if (accessoriesOnly) {
      setNotification({type: 'notAccessoriesOnly', on: true})
    }
    else {
      addToCart(e)
      dispatch(push(Routes.CheckoutPage));
    }
  }

  const updateVariants = (variants: Variant[]) => {
    setCandidate((state:any) => ({...state, variants}))
  }

  const handleNotify = (actionType: string) => (e:any) => {
    setNotification((state: any) => ({...state, on: false}))
    if (actionType === 'cancel') {}
    else if (actionType === 'contact') {
      window.open("mailto:contact@bhaptics.com", "_top");
    }
  }

  return (
    <div className={cx(className, 'flex flex-col')}>
      {added && !notification.on && <Added />}
      {notification.on && <Notify type={notification.type} onNotify={handleNotify} />}
      <ProductSlider slides={{srcs: srcs, id: product.id}} />
      <div
        css={css`
          & * {
            cursor: ${soldOut ? 'not-allowed' : ''};
            pointer-events: ${soldOut ? 'none' : ''};
          }
          background-image: url(${soldOut ? soldOutSrcs[locale.languageCode] : ''});
          background-color: #1e1e21;
        `}
        className={'px-5 pt-4 relative bg-no-repeat bg-center h-104 flex flex-col pb-5'}
      >
        {soldOut && <Disabled />}
        <Name id={product.id} />
        <Summary id={product.id} />
        {!soldOut && <SelectVariants id={product.id} onVariants={updateVariants} variants={variants} />}
        <Count
          css={css`pointer-events: ${countable ? 'auto' : 'none'}`}
          count={candidate.count} onCount={updateCount}
               className={cx({'opacity-25': soldOut}, 'mt-2.5')}
               max={range.max - range.offset} />
        <div className={'mt-4 grid grid-cols-2 gap-x-2'}>
          <Button
            soldOut={soldOut}
            id={'addToCart'}
            onClick={addToCart}
            clickable={clickable}
            style={{
              borderColor: `${soldOut ? '#606060' : '#0091ff'}`,
              backgroundColor: '#1e1e21'
            }}
          >
          </Button>
          <Button
            soldOut={soldOut}
            id={'buyNow'}
            onClick={buyNow}
            style={{
              borderColor: `${soldOut ? '#606060' : '#0091ff'}`,
              backgroundColor: `${soldOut ? '#606060' : '#0091ff'}`}}
            clickable={clickable}
          >
          </Button>
        </div>
      </div>
      <ToDetailPage to={`/shop-products/${product.id}`} />
    </div>
  )
}

export default ProductCard
