import {useEffect, useState} from 'react';

const useScript = async (url: string) => {
  const [script, setScript] = useState<any>('')

  useEffect(() => {
    const _script = document.createElement('script');
    _script.src = url;
    _script.async = true;

    document.body.appendChild(_script);
    setScript(_script)
    return () => {
      document.body.removeChild(_script);
    }
  }, [url]);

  return new Promise((resolve => {
    if (!!script) {
      resolve(script)
    }
    else {
      resolve()
    }
  }))
};

export default useScript;