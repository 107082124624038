/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC} from "react";
import desktopBg from "./images/desktop/default.jpg";
import {cx} from "emotion";
import {useSelector} from "react-redux";
import {categorySelector} from "../../../store/selectors/productsSelector";
import {localeSelector} from "../../../store/selectors/configSelector";
import {translationGeneralSelector, translationNoticeSelector} from "../../../store/selectors/translationSelector";
import Validator from "../../../utils/Validator";
import {ProductId} from "../../../models/Product";
import whiteArrow from './images/desktop/icon-leftArrow-white.png'
import blackArrow from './images/desktop/icon-leftArrow-black.png'

type Props = {
  id: ProductId;
};

const Circle = () =>
  <div
    css={css`min-width: 1.5rem; min-height: 1.5rem`}
    className={'hidden sm:flex w-6 h-6 items-center justify-center'}>
    <div
      css={css`min-width: .5rem; min-height: .5rem`}
      className={'mb-2 lg:mb-0 inline-block rounded-full bg-black w-2 h-2'}
    />
  </div>

const Notice: FC<Props> = ({ id }) => {
  const locale = useSelector(localeSelector);
  const translationGeneral = useSelector(translationGeneralSelector)
  const translationNotice = useSelector(translationNoticeSelector)
  const category = useSelector(categorySelector(id));
  let head = Validator.tryGetValue(translationGeneral, 'notice', locale.languageCode)
  if (!head) {
    head = 'Notice'
  }

  let translation =[]
  if (!!translationNotice && !!category) {
    translation = id === ProductId.TactsuitXAudioAccessories ? translationNotice['hardware'] : translationNotice[category]
  }

  return (
    <div className={"w-full bg-white sm:bg-black text-black flex mx-auto"}>
      <div
        css={css`
          min-height: 552px;
          @media(max-width: 639px) {
            min-height: unset;
          }
        `}
        className={'max-w-screen-xl relative mx-auto w-full flex flex-col justify-center'}>
        <img className={'hidden sm:block absolute inset-0 h-full w-full object-cover object-right lg:object-center'} src={desktopBg} />
        <div className={'py-10 md:py-0 pl-5 pr-5 lg:pl-36 flex z-10 flex-col'}>
          <span className={'text-3xl lg:text-4xl font-extrabold'}>{head}</span>
          <div className={'flex flex-col mt-5 lg:mt-12'}>
            {
              !!translation.length && translation.map((value: any, index: number) => {
                return (
                  <div className={'flex mb-5 flex-no-wrap'} key={index}>
                    <Circle />
                    <div className={'sm:ml-4 flex-col md:flex-row flex flex-grow flex-wrap items-start'}>
                      <div className={'flex sm:mb-4'}>
                        <span
                          css={css`
                            color: ${value.color};
                            line-height: 1;
                          `}
                          className={'text-sm lg:text-base flex-grow leading-tight'}>
                          {value[`description_${locale.languageCode}`]}
                        </span>
                      </div>
                      <div
                        css={css`
                          @media(max-width: 639px) {
                            width: 100%;
                            & > a:first-of-type {width: 65%; max-width: 230px}
                          }
                        `}
                        className={cx({'hidden': !value[`label_${locale.languageCode}`]},
                        'flex md:px-4 mt-2.5 sm:-mt-3 flex-grow justify-start')
                        }
                      >
                        <a
                          css={css`
                            &:hover {
                              & > span:first-of-type {color: white}
                              & > img:first-of-type {display: none}
                              & > img:nth-of-type(2) {display: block}
                            }
                          `}
                          className={'border w-48 flex justify-between px-3 sm:px-6 items-center py-2.5 border-black bg-transparent hover:bg-black'}
                           target={"_blank"} href={value.href}>
                          <span className={'text-sm lg:text-base flex-grow whitespace-no-wrap'}>
                            {value[`label_${locale.languageCode}`]}
                          </span>
                          <img className={'block'} src={blackArrow} />
                          <img className={'hidden'} src={whiteArrow} />
                        </a>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notice;