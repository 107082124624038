import Env from "../env/Env";
import { Offer } from "../store/reducers/offer";

export default new (class OfferAPI {
  public getOffer = async (id: string) => {
    try {
      const offer = await fetch(`${Env.ApiUrl}/offers/get?id=${id}`);
      return await offer.json();
    } catch (e) {
      console.error(e);
    }
  };

  public updateOffer = async (offer: Offer) => {
    try {
      const result = await fetch(`${Env.ApiUrl}/offers/update`, {
        method: "POST",
        body: JSON.stringify(offer),
        headers: {
          "Content-Type": "application/json"
        }
      });
      return result.json();
    } catch (e) {
      console.error(e);
    }
  };
})();
