import SalesLocalStorageService from "../../storage/SalesLocalStorageService";

export interface SalesState {
  until: string;
  visitTime: string;
}

export const initialSalesState: SalesState = {
  until: new Date("Tue Jun 02 2020 10:00:00 GMT+0900 (Korean Standard Time").toISOString(),
  visitTime: new Date().toISOString()
};

export enum SalesActionType {
  UpdateSales = "sales/updateSales",
  UpdateUntil = "sales/updateUntil",
  UpdateVisitTime = "sales/UpdateVisitTime",
  InitializeSales = "sales/initializeSales"
}

type SalesAction =
  | { type: SalesActionType.UpdateSales; payload: SalesState }
  | { type: SalesActionType.UpdateUntil; payload: SalesState }
  | { type: SalesActionType.UpdateVisitTime; payload: SalesState }
  | { type: SalesActionType.InitializeSales; payload: SalesState };

export const sales = (
  state: SalesState = SalesLocalStorageService.getSales(),
  action: SalesAction
) => {
  switch (action.type) {
    case SalesActionType.UpdateSales: {
      SalesLocalStorageService.setSales(action.payload);
      return action.payload;
    }
    case SalesActionType.UpdateUntil: {
      SalesLocalStorageService.setSales(action.payload);
      return action.payload;
    }
    case SalesActionType.UpdateVisitTime: {
      SalesLocalStorageService.setSales(action.payload);
      return action.payload;
    }
    case SalesActionType.InitializeSales: {
      SalesLocalStorageService.setSales(initialSalesState);
      return initialSalesState;
    }
    default:
      return state;
  }
};
