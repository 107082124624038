/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC, Fragment } from "react";
import Checkbox from "./Checkbox";
import { cx } from "emotion";
import { FaTrash } from "react-icons/fa";
import { CartItem } from "../../store/reducers/cart";
import {
  removeCartItem,
  updateCartItem,
  updateCartItems
} from "../../store/actions/cartAction";
import { useDispatch, useSelector } from "react-redux";
import { cartItemsSelector } from "../../store/selectors/cartSelector";
import { importFiles } from "../../utils/CommonUtil";
import {localeSelector} from "../../store/selectors/configSelector";
import {translationGeneralSelector, translationProductDetailsSelector} from "../../store/selectors/translationSelector";
import Validator from "../../utils/Validator";
import CartUtil from "../../utils/CartUtil";
import Count from "./Count";

const Label: FC<{}> = ({ children }) => (
  <span className={"text-base md:text-2xl font-extrabold leading-snug text-center"}>
    {children}
  </span>
);

const ProductLabel: FC<{ className?: string }> = ({
  children,
  className = ""
}) => (
  <span
    className={cx(
      className,
      "text-sm md:text-lg font-semibold text-left"
    )}
  >
    {children}
  </span>
);

const ProductPrice: FC<{}> = ({ children }) => (
  <span className={"text-base md:text-xl font-semibold leading-normal whitespace-no-wrap"}>
    {children}
  </span>
);

const unit = "$";

type Props = {};

const CartTable: FC<Props> = () => {
  const locale = useSelector(localeSelector)
  const translationGeneral = useSelector(translationGeneralSelector)
  const translationProductDetails = useSelector(translationProductDetailsSelector)
  const dispatch = useDispatch();
  const images = importFiles(require.context("./images", false, /\.(png|jpe?g|svg)$/));
  const cartItems = useSelector(cartItemsSelector);
  const checkedAll = cartItems.filter(cartItem => !cartItem.checked).length === 0;

  const updateCount = (cartItem: CartItem) => (count:number) => {
    dispatch(updateCartItem({...cartItem, qty: count}))
  }

  const handleRemove = (cartItem: CartItem) => (e: any) => {
    dispatch(removeCartItem(cartItem));
  };

  const handleChange = (cartItem: CartItem) => (e: any) => {
    dispatch(updateCartItem({ ...cartItem, checked: !cartItem.checked }));
  };

  const handleChangeAll = (e: any) => {
    dispatch(
      updateCartItems([
        ...cartItems.map(cartItem => ({ ...cartItem, checked: !checkedAll }))
      ])
    );
  };

  return (
    <table className={"w-full"}>
      <thead>
        <tr className={"border-b"}>
          <th className={"pb-3 flex justify-start"}>
            <div
              css={css`
                border-color: #979797;
                background-color: #303030;
              `}
              className={"w-5 h-5 md:w-8 md:h-8 border"}
            >
              <Checkbox checked={checkedAll} onChange={handleChangeAll} />
            </div>
          </th>
          <th className={"pb-3 w-5/12"}>
            <Label>{Validator.tryGetValue(translationGeneral, 'item', locale.languageCode)}</Label>
          </th>
          <th className={"pb-3"}>
            <Label>{Validator.tryGetValue(translationGeneral, 'qty', locale.languageCode)}</Label>
          </th>
          <th className={"pb-3 flex justify-end"}>
            <Label>{Validator.tryGetValue(translationGeneral, 'price', locale.languageCode)}</Label>
          </th>
        </tr>
      </thead>
      <tbody>
        {cartItems.map((cartItem, index) => {
          const name = Validator.tryGetValue(translationProductDetails, cartItem.id, `name_${locale.languageCode}`)
          let variants = ''
          if (!!cartItem.variants && !!cartItem.variants.length) {
            variants = CartUtil.describeVariants(cartItem.variants)
          }
          const isLast = cartItems.length - 1 === index;
          const isFirst = index === 0;
          const max = 9
          return (
            <Fragment key={index}>
              <tr>
                <td className={cx({ "pb-10": isLast }, { "pt-10": isFirst })}>
                  <div
                    css={css`
                      border-color: #979797;
                      background-color: #303030;
                    `}
                    className={"w-5 h-5 md:w-8 md:h-8 border"}
                  >
                    <Checkbox
                      checked={cartItem.checked}
                      onChange={handleChange(cartItem)}
                    />
                  </div>
                </td>
                <td className={cx({ "pb-10": isLast }, { "pt-10": isFirst })}>
                  <div className={"flex items-center justify-start p-1"}>
                    <div
                      css={css`background-color: #1e1e21;`}
                      className={"hidden md:block md:my-2"}
                    >
                      <img
                        className={"w-full"}
                        alt={cartItem.id}
                        src={images[`${cartItem.id}.png`]}
                        css={css`
                          min-width: 120px;
                          max-width: 120px;
                        `}
                      />
                    </div>
                    <div className={"flex flex-row flex-nowrap items-center"}>
                      <ProductLabel
                        css={css`line-height: 1.15`}
                        className={"md:ml-4 mr-2"}>
                        {`${name}${!!variants ? `(${variants})` : ``}`}
                      </ProductLabel>
                      <div
                        className={"hover:opacity-75 cursor-pointer rounded py-1 sm:p-2 w-auto"}
                        onClick={handleRemove(cartItem)}
                      >
                        <FaTrash />
                      </div>
                    </div>
                  </div>
                </td>
                <td className={cx({ "pb-10": isLast }, { "pt-10": isFirst })}>
                  <Count max={max} onCount={updateCount(cartItem)} count={cartItem.qty} />
                </td>
                <td
                  className={cx(
                    { "pb-10": isLast },
                    { "pt-10": isFirst },
                    "w-40"
                  )}
                >
                  <div className={"flex items-center justify-end"}>
                    <ProductPrice>
                      {unit} {(cartItem.price * cartItem.qty).toFixed(2)}
                    </ProductPrice>
                  </div>
                </td>
              </tr>
            </Fragment>
          );
        })}
        <tr className={"border-t"}>
          {
            [...new Array(4)].map((item: any, index: number) => (
              <td className={"mt-10"} key={index} />
            ))
          }
        </tr>
      </tbody>
    </table>
  );
};

export default CartTable;
