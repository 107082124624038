/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC} from "react";
import Validator from "../../utils/Validator";
import {useSelector} from "react-redux";
import {translationGeneralSelector} from "../../store/selectors/translationSelector";
import {localeSelector} from "../../store/selectors/configSelector";

type Props = {
};

const Bookmark: FC<Props> = ({}) => {
  const translationGeneral = useSelector(translationGeneralSelector)
  const locale = useSelector(localeSelector)
  let label = Validator.tryGetValue(translationGeneral, 'product', locale.languageCode)

  if (!label) {
    label = 'Product'
  }

  return (
    <div
      css={css`background-color: #0f1014;`}
      className={"w-full mx-auto"}
    >
      <div className={'max-w-screen-xl mx-auto mb-8 lg:mb-10 mt-12 lg:mt-15 flex flex-col items-center'}>
        <div className={'flex flex-col'}>
          <span className={'text-white font-black text-2xl'}>{label}</span>
          <span css={css`background-color: #1e1e21;`} className={'inline-block rounded mt-2.5 h-2'} />
        </div>
      </div>
    </div>
  );
};

export default Bookmark;