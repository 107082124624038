/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC, Fragment} from "react";
import {OrderState} from "../../pages/TrackingPage";
import {cx} from "emotion";
import {Tracking, TrackingState} from "../../types/Tracking";
import DateUtils from "../../utils/DateUtils";

type Props = {
  className?:string,
  orderStates: OrderState[],
  tracking: Tracking,
}

const VerticalOrderStatesFlowchart:FC<Props> = ({tracking, className='', orderStates}) => {
  const orderStateIndex = tracking.state === TrackingState.orderPlaced ? 0 :
    tracking.state === TrackingState.packing ? 1 :
      tracking.state === TrackingState.shipped ? 2 :
        -1;

  return (
    <Fragment>
      {
        orderStates.map((state, index) => {
          const dotColor = index <= orderStateIndex ? '#6dd400' : '#d8d8d8'
          const lineColor = index < orderStateIndex ? '#6dd400' : '#d8d8d8'

          let description = state.description;
          let shippingDate:any = undefined
          let trackingUrl:any = undefined
          let trackingNumber:any = undefined


          if (state.id === TrackingState.packing) {
            const date = DateUtils.toLocaleDate(!!tracking.shippingMillis ? tracking.shippingMillis : 0, 9)
            const dateString = date.toDateString()
            const splitted = dateString.split(" ")
            shippingDate = `${splitted[1]} ${splitted[2]}`
          }

          if (!!tracking && 'shippingDate' in tracking) {
            //@ts-ignore
            const _splitted = tracking.shippingDate.split(" ")
            shippingDate = `${_splitted[1]} ${_splitted[2]}`
          }

          if (state.id === TrackingState.shipped) {
            trackingUrl = tracking.trackingUrl
            trackingNumber = tracking.trackingNumber
          }

          if (index > orderStateIndex) {
            description = 'Will be updated'
            shippingDate = ""
            trackingUrl = ''
            trackingNumber = ''
          }

          return (
            <div
              className={'h-56 flex w-full'}
              key={index}
            >
              <div className={'h-full pl-2 sm:pl-8 flex items-start relative'}>
                <div
                  css={css`
                    width: 3px;
                    margin-left: 4.5px;
                    background-color: ${lineColor};
                  `}
                  className={cx('absolute h-full', {'hidden': index === orderStates.length - 1})} />
                <span
                  css={css`background-color: ${dotColor}; min-width: .75rem;`}
                  className={'w-3 h-3 z-10 rounded-full'}
                />
                <img className={'h-24 -mt-10'} src={state.icons[+(index <= orderStateIndex)]} />
                <div className={'flex -mt-4 flex-col'}>
                    <span
                      className={'text-2xl font-medium'}
                      css={css`color: ${index <= orderStateIndex ? '#ffffff' : '#898989'};`}
                    >
                      {state.label}
                    </span>
                  <div
                    className={'leading-tight'}
                    css={css`& > * {color: ${index <= orderStateIndex ? '#ffffff' : '#898989'};}`}>
                    <span className={'text-start text-sm mt-2'}>{`${description}`}</span>
                    <span className={'font-bold'}>{`${!!shippingDate ? shippingDate : ''}${!!shippingDate ? '.' : ''}`}</span>
                    <a href={trackingUrl} target={'_blank'} className={'hover:opacity-75'}>
                      <span>{trackingNumber}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
    </Fragment>
  )
}

export default VerticalOrderStatesFlowchart