/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CartState} from "../../store/reducers/cart";
import {recipientSelector} from "../../store/selectors/recipientSelector";
import {shippingPriceSelector} from "../../store/selectors/shippingSelector";
import {startLoading, stopLoading} from "../../store/actions/loadingAction";
import {cx} from "emotion";
import CommonPayRequest from "../../firebase/CommonPayRequest";
import {Offer} from "../../store/reducers/offer";

type Props = {
  className?: string,
  data: (CartState | Offer),
  onComplete: (callback?: any) => void;
}

type DataOnApprove = {
  billingToken: string | null
  facilitatorAccessToken: string | null
  orderID: string
  payerID: string | null
  paymentID: string | null
}

const PaypalButtonV2:FC<Props> = ({onComplete, className='', data}) => {
  const dispatch = useDispatch()
  const recipient = useSelector(recipientSelector)
  const shippingPrice = useSelector(shippingPriceSelector)
  const discountPrice = 'discountPrice' in data ? data.discountPrice : 0
  const {address: {country}} = recipient;
  const offerId = 'id' in data ? data.id : '';

  useEffect(() => {
    initialize()
  }, [])

  //@ts-ignore
  const requiredItems = data.items.map((item) => ({
    hsCode: item.hsCode,
    id: item.id,
    name: item.name,
    price: item.price,
    qty: item.qty,
    weight: item.weight,
    variants: item.variants
  }))

  const shippingAddress = {
    address1: recipient.address.address1,
    address2: recipient.address.address2,
    zip: recipient.address.zip,
    city: recipient.address.city,
    state: recipient.address.state,
    country: country.name,
    email: recipient.personal.email,
    firstName: recipient.personal.firstName,
    lastName: recipient.personal.lastName,
    tel: recipient.personal.tel,
  }

  let param = {
    items: requiredItems,
    shippingPrice,
    shippingAddress,
    discountPrice,
    offerId
  }

  const initialize = () => {
    //@ts-ignore
    paypal.Buttons({
      createOrder: async () => {
        const res = await CommonPayRequest.createPaypalOrder(param)
        // todo - if not res.orderId, please notify it
        return res.orderId
      },
      onApprove: async (_data: DataOnApprove) => {
        dispatch(startLoading)
        const res = await CommonPayRequest.capturePaypalOrder({
          orderId: _data.orderID,
          ...param
        })
        if (!res.message) {
          onComplete(() => dispatch(stopLoading))
        }
        else {
          dispatch(stopLoading)
          window.alert(res.message)
        }
      },
      /*onShippingChange: (data: any, actions: any) => {
        if (!!data) {
          // todo
          console.log(data)
        }
      },*/
      onCancel: (data: any) => {
        if (!!data) {
          console.log(data)
          dispatch(stopLoading)
        }
      },
      onError: (err: any) => {
        if (!!err) {
          dispatch(stopLoading)
          window.alert(err.message)
        }
      }
    }).render('#paypal-button-container');
  }

  return (
    <div className={cx(className, {'pointer-events-none opacity-25': shippingPrice < 0})}>
      <div id="paypal-button-container" />
    </div>
  )
}

export default PaypalButtonV2