/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC} from "react";
import {cx} from "emotion";
import {push} from "connected-react-router";
import {useDispatch} from "react-redux";
import {Routes} from "../../models/Enums";
import {GoLocation} from 'react-icons/go'

type Props = {
  className?:string
}

const TrackingIcon:FC<Props> = ({className=''}) => {
  const dispatch = useDispatch()

  const handleRoute = (route: string) => (e: any) => {
    dispatch(push(route));
  }

  return (
    <div className={cx(className, "flex items-center cursor-pointer opacity-50 hover:opacity-100")}>
      <div
        className={cx("flex items-center cursor-pointer hover:opacity-75")}
        onClick={handleRoute(Routes.TrackingPage)}
      >
        <GoLocation className={'h-7 w-7 text-white'} />
      </div>
    </div>
  )
}

export default TrackingIcon