/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useEffect, useRef, useState} from "react";
import { useSelector } from "react-redux";
import { localeSelector } from "../../../store/selectors/configSelector";
import {translationGeneralSelector, translationOverviewSelector} from "../../../store/selectors/translationSelector";
import Validator from "../../../utils/Validator";
import {importFiles} from "../../../utils/CommonUtil";
import {ProductId} from "../../../models/Product";
import {FiChevronLeft, FiChevronRight} from "react-icons/fi";
import {cx} from "emotion";
import Slider from "react-slick";

type Props = {
  id: ProductId;
};

const PaginationDot:FC<{className?: string, selected: boolean, onClick:(e:any) => void}> = ({onClick, selected, className=''}) =>
  <div
    onClick={onClick}
    css={css`
      opacity: ${selected ? '1' : '.45'};
      &:hover {opacity: .7;}
    `}
    className={cx(className)}
  />

const Chevron = (props: any) =>
  props.rtl ?
    <FiChevronRight
      onClick={props.onClick}
      className={cx(props.className, 'w-12 z-10 h-12 text-white opacity-50 hover:opacity-100 cursor-pointer')}
    />
    :
    <FiChevronLeft
      onClick={props.onClick}
      className={cx(props.className, 'w-12 z-10 h-12 text-white opacity-50 hover:opacity-100 cursor-pointer')}
    />

const Overview: FC<Props> = ({id}) => {
  const ref = useRef<any>(null);
  const [state, setState] = useState<any>({
    index: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    slides: 3
  });
  const pages = Math.ceil(state.slides / state.slidesToScroll);
  const page = Math.floor(state.index / state.slidesToShow) + 1;
  const locale = useSelector(localeSelector);
  const translationGeneral = useSelector(translationGeneralSelector)
  const translationOverview = useSelector(translationOverviewSelector)
  const images = importFiles(require.context("./images", false, /\.(png|jpe?g|svg)$/));
  let head = Validator.tryGetValue(translationGeneral, 'overview', locale.languageCode)
  if (!head) {
    head = 'Overview'
  }

  let data = Validator.tryGetValue(translationOverview, id)
  if (data) {
    data = data.map((item: any, index: number) => {
      const imageSrc_cn = images[`cn-${id}-${index+1}.jpg`] || images[`cn-${id}-${index+1}.png`]
      const imageSrc_en = images[`en-${id}-${index+1}.jpg`] || images[`en-${id}-${index+1}.png`]
      const imageSrc_jp = images[`jp-${id}-${index+1}.jpg`] || images[`jp-${id}-${index+1}.png`]
      const imageSrc_kr = images[`en-${id}-${index+1}.jpg`] || images[`en-${id}-${index+1}.png`]
      return {
        ...item,
        imageSrc_cn,
        imageSrc_en,
        imageSrc_jp,
        imageSrc_kr
      }
    })
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          // infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0
        }
      }
    ],
    beforeChange: (currentSlide: number, nextSlide: number) => {
      if (state.index !== nextSlide) {
        setState((state: any) => ({ ...state, index: nextSlide }));
      }
    },
    onInit: () => {
      if (!!ref && !!ref.current) {
        console.log(ref.current.props);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeEvent);
    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, []);

  useEffect(() => {
    initialize();
  }, [ref.current]);

  const initialize = () => {
    if (ref.current) {
      const {
        state: { breakpoint },
        props
      } = ref.current;
      const { children } = props;
      if (breakpoint === null) {
        const { slidesToShow, slidesToScroll } = props;
        setState((state: any) => ({
          ...state,
          slidesToShow,
          slidesToScroll,
          slides: children.length
        }));
      } else {
        const responsive = props.responsive.filter(
          (item: any) => item.breakpoint === breakpoint
        )[0];
        const { slidesToShow, slidesToScroll } = responsive.settings;
        setState((state: any) => ({
          ...state,
          slidesToShow,
          slidesToScroll,
          slides: children.length
        }));
      }
      ref.current.slickGoTo(0);
    }
  };

  const handleResizeEvent = () => initialize();

  const handlePrev = (e: any) => !!ref.current && ref.current.slickPrev();

  const handleNext = (e: any) => !!ref.current && ref.current.slickNext();

  const handlePageTo = (index: number) => (e: any) => {
    ref.current.slickGoTo(index);
  }

  return (
    !!data && !!data.length ?
      <div className={"w-full bg-black mx-auto"}>
        <div
          css={css`background-color: #0f1014;`}
          className={'text-white max-w-screen-xl mx-auto py-8 lg:py-20 w-full flex flex-col items-center'}>
          <span className={'text-3xl lg:text-4xl font-extrabold'}>{head}</span>
          <div className={'w-full relative flex items-center'}>
            <Chevron
              onClick={handlePrev}
              className={cx('left-0 absolute hidden sm:block lg:hidden')}
            />
            <div className={'sm:px-7 w-full flex items-center'}>
              <Slider
                ref={ref}
                css={css`
                  & .slick-arrow {visibility: hidden; display: none !important;}
                  & .slick-track {
                    @media(max-width: 639px) {height: 25rem;}
                  }
                  & .slick-slide > div {height: 100%}
                `}
                {...settings}
                className={'w-full'}
              >
                {
                  data.map((value: any, index: number) => {
                    return (
                      <div className={'flex lg:px-8 items-center justify-center w-full h-full'} key={index}>
                        <img className={'object-contain sm:object-cover w-full h-full'} src={value[`imageSrc_${locale.languageCode}`]} />
                      </div>
                    )
                  })
                }
              </Slider>
            </div>
            <Chevron
              rtl={true}
              onClick={handleNext}
              className={cx('right-0 absolute hidden sm:block lg:hidden')}
            />
            <div className={'absolute lg:hidden bottom-0 left-0 mt-4 right-0 w-full flex justify-center'}>
              {
                [...new Array(pages)].map((page, index) => (
                  <PaginationDot
                    selected={index === state.index / state.slidesToShow}
                    className={'w-3 h-3 mx-1.5 bg-white rounded-full'}
                    onClick={handlePageTo(index * state.slidesToShow)} key={index} />
                ))
              }
            </div>
          </div>
        </div>
      </div>
      :
      null
  );
};

export default Overview;