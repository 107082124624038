export enum LoadingType {
  Circular =  'circular',
  Progress = 'progress'
}

export interface Loading {
  for: string,
  on: boolean,
  type: LoadingType,
  lifespan: number
}

export const initialLoading:Loading = {
  for: '',
  on:false,
  type: LoadingType.Circular,
  lifespan: 60000 // 1 min
}

export enum LoadingActionType {
  UpdateLoading = 'loading/updateLoading',
}

type LoadingAction =
  | {type: LoadingActionType.UpdateLoading; payload: Loading}

export const loading = (state: Loading = initialLoading, action: LoadingAction) => {
  switch (action.type) {
    case LoadingActionType.UpdateLoading: {
      return action.payload
    }
    default:
      return state
  }
}