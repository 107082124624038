/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC, useEffect } from "react";
import Portal from "../portal";
import { FaCheck } from "react-icons/fa";
import {useSelector} from "react-redux";
import {localeSelector} from "../../store/selectors/configSelector";
import {translationGeneralSelector} from "../../store/selectors/translationSelector";
import Validator from "../../utils/Validator";
import LocaleUtil from "../../utils/LocaleUtil";

type Props = {
  onClose: () => void;
  redirectMessage?:string
};

const OnPaymentSuccess: FC<Props> = ({ onClose, redirectMessage }) => {
  const locale = useSelector(localeSelector)
  const translationGeneral = useSelector(translationGeneralSelector);

  useEffect(() => {
    setTimeout(() => {
      onClose();
    }, 2000);
  }, []);
  return (
    <Portal css={css`font-family: ${LocaleUtil.getFontFamily(locale.languageCode)};`} className={"z-10"}>
      <div
        className={"z-50 fixed inset-0 w-full h-full flex items-center justify-center"}
      >
        <div
          css={css`background-color: #0f1014;`}
          className={"flex flex-col rounded text-white items-center justify-center pb-8 pt-10 px-20"}
        >
          <FaCheck
            css={css`color: #6dd400;`}
            className={"mb-4 text-6xl"}
          />
          <span className={"block text-xl"}>
            {Validator.tryGetValue(translationGeneral, 'thankYouForYourPurchase', locale.languageCode)}
          </span>
          <span className={"block text-sm mt-4"}>
            {!!redirectMessage ? redirectMessage : Validator.tryGetValue(translationGeneral, 'willRedirectToTheShopPageSoon', locale.languageCode)}
          </span>
        </div>
      </div>
    </Portal>
  );
};

export default OnPaymentSuccess;
