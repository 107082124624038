/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";
import ProductCard from "../productCard";
import Notice from "../notice";
import YouMayLike from "../youMayLike";
import {Product, ProductId} from "../../../models/Product";
import ComesWith from "../comesWith";

type Props = {
  id: ProductId;
  product: Product
};

const Accessory: FC<Props> = ({ id, product }) => {
  return (
    <div>
      <ProductCard product={product} />
      <ComesWith id={id} />
      <Notice id={id} />
      <YouMayLike id={id} />
    </div>
  );
};

export default Accessory;
