import React, { FC } from "react";
import { cx } from "emotion";
import { FaCheck } from "react-icons/fa";

type Props = {
  className?: string;
  checked: boolean;
  onChange: (e: any) => void;
};

const Checkbox: FC<Props> = ({ className = "", checked, onChange }) => {
  return (
    <div onClick={onChange} className={cx(className, "w-full h-full")}>
      {checked && <FaCheck className={"w-full h-full p-1 text-white"} />}
    </div>
  );
};

export default Checkbox;
