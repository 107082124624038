/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC, useState} from "react";
import {cx} from "emotion";
import {MdClose} from 'react-icons/md'
import {IoIosArrowDown} from "react-icons/io";

type Props = {
  className?:string,
  onClick:(e:any) => void
}

interface Route {
  label: string,
  href?: string,
  open?: boolean,
  routes?: Route[]
}

const initialRoutes:Route[] = [
  {
    label: 'Tactsuit',
    open: false,
    routes: [
      {label: 'TactSuit X40', href: 'https://www.bhaptics.com/tactsuit/tactsuit-x40'},
      {label: 'TactSuit X16', href: 'https://www.bhaptics.com/tactsuit/tactsuit-x16'},
      {label: 'Compare', href: 'https://www.bhaptics.com/tactsuit/compare'},
      {label: 'Tactal', href: 'https://www.bhaptics.com/tactsuit/tactal'},
      {label: 'Tactosy for Arms', href: 'https://www.bhaptics.com/tactsuit/tactosy-for-arms'},
      {label: 'Tactosy for Hands', href: 'https://www.bhaptics.com/tactsuit/tactosy-for-hands'},
      {label: 'Tactosy for Feet', href: 'https://www.bhaptics.com/tactsuit/tactosy-for-feet'},
    ]
  },
  {
    label: 'Experiences',
    open: false,
    routes: [
      {label: 'VR', href: 'https://www.bhaptics.com/experiences/vr'},
      {label: 'PC', href: 'https://www.bhaptics.com/experiences/pc'},
      {label: 'Console', href: 'https://www.bhaptics.com/experiences/console'},
      {label: 'Movie/Music', href: 'https://www.bhaptics.com/experiences/movie-music'},
    ]
  },
  {
    label: 'News',
    href: 'https://www.bhaptics.com/news'
  },
  {
    label: 'Support',
    open: false,
    routes: [
      {label: 'Setup', href: 'https://www.bhaptics.com/support/setup'},
      {label: 'FAQ', href: 'https://www.bhaptics.com/support/faq'},
      {label: 'Downloads', href: 'https://www.bhaptics.com/support/downloads'},
      {label: 'Developers', href: 'https://www.bhaptics.com/support/developers'},
      {label: 'Contact Us', href: 'https://www.bhaptics.com/support/contact-us'},
    ]
  },
  {
    label: 'Shop',
    href: 'https://www.bhaptics.com/shop'
  },
]

const MobileNavbar:FC<Props> = ({className='', onClick}) => {
  const [routes, setRoutes] = useState<Route[]>(initialRoutes)

  const handleRoute = (route: Route) => (e:any) => {
    e.preventDefault()
    //@ts-ignore
    const subrouted = 'routes' in route && !!route.routes.length
    if (!subrouted) {
      window.location.href = !!route.href ? route.href : ""
    }
    else {
      setRoutes((state: Route[]) => {
        const nextState = state.map((_route, _index) =>
          _route.label === route.label ?
            {...route, open: !route.open}
            :
            _route
        )
        return nextState
      })
    }
  }

  return (
    <div
      css={css`color: #212630;`}
      className={'px-5 flex flex-col justify-end'}
    >
      <div className={'flex py-4 self-end'}>
        <MdClose
          onClick={onClick}
          css={css`left: .25rem`}
          className={'text-2xl relative text-black active:opacity-75'}
        />
      </div>
      <div className={'mt-2.5'}>
        {
          routes.map((route, index) => {
            //@ts-ignore
            const subrouted = 'routes' in route && !!route.routes.length
            const open = 'open' in route && route.open
            return (
              <div className={'flex flex-col'} key={index}>
                <div
                  onClick={handleRoute(route)}
                  className={'flex justify-between items-center active:opacity-75 cursor-pointer py-4'}
                >
                  <span className={'text font-bold capitalize'}>{route.label}</span>
                  <IoIosArrowDown
                    className={cx(
                      !subrouted ? '-rotate-90' : open ? 'rotate-180' : '',
                      'transform transition duration-300 ease-in-out'
                    )} />
                </div>
                <span css={css`background-color: #212630;`} className={'opacity-10 h-px w-full inline-block'} />
                {
                  subrouted && open &&
                  <div className={'py-5'}>
                    {
                      //@ts-ignore
                      route.routes.map((_route, _index) => {
                        return (
                          <a
                            className={'py-4 flex active:opacity-75 self-center'}
                            key={_index}
                            href={_route.href}
                          >
                            <span>{_route.label}</span>
                          </a>
                        )
                      })
                    }
                  </div>
                }
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default MobileNavbar