import React, { FC, useEffect, useState } from "react";
import { createPortal } from "react-dom";

type Props = {
  style?: string;
  children?: any;
  className?: string;
  root?: any;
  onClose?:(e:any) => void;
  fixed?: boolean
};

const Portal: FC<Props> = ({
  root,
  fixed=false,
  onClose = () => {},
  style = "position:fixed; z-index: 10; height: 100vh; width: 100vw; left: 0; top: 0; right: 0; bottom: 0",
  children,
  className = ""
}) => {
  const base = !!root ? root : document.getElementById("root");
  const body = document.getElementsByTagName('body')
  const portalRoot = document.createElement("div");
  portalRoot.setAttribute("style", style);
  portalRoot.setAttribute("class", className);

  const [state, setState] = useState({
    portalRoot
  });

  useEffect(() => {
    if (base) {
      document.addEventListener('click', handleClickEventListener)
      base.appendChild(state.portalRoot);
    }
    if (!!body && fixed) {
      body[0].style.overflowY = 'hidden'
    }
    return () => {
      if (base) {
        base.removeChild(state.portalRoot);
        document.removeEventListener('click', handleClickEventListener)
      }
      if (!!body) {
        body[0].style.overflowY = ''
      }
    };
  }, []);

  const handleClickEventListener = (e:any) => {
    try {
      const divShouldHappen =portalRoot.childNodes[0].childNodes[0]
      if (!divShouldHappen.contains(e.target)) {
        !!onClose && onClose(e)
      }
    }
    catch (e) {
      !!onClose && onClose(e)
    }
  }

  return createPortal(children, state.portalRoot);
};

export default Portal;
