export interface TranslationState {
  [key: string]: any;
  battery?: TranslationBattery | any
  support?: TranslationSupport | any
  messages?: TranslationMessages | any
  productSpecs?: TranslationProductSpecs | any
  productDetails?: TranslationProductDetails | any
  comesWith?: TranslationComesWith | any
  general?: TranslationGeneral | any
  feature?: TranslationFeature | any
  notice?: TranslationNotice | any
  overview?: TranslationOverview | any
}

interface Label {
  label_en: string,
  label_cn: string,
  label_jp: string,
  label_kr: string
}

interface Description {
  description_en: string,
  description_cn: string,
  description_jp: string,
  description_kr: string,
}

interface GeneralName {
  generalName_en: string,
  generalName_cn: string,
  generalName_jp: string,
  generalName_kr: string,
}

interface MainFeature {
  mainFeature_en: string,
  mainFeature_cn: string,
  mainFeature_jp: string,
  mainFeature_kr: string,
}

interface Title {
  title_en: string,
  title_cn: string,
  title_jp: string,
  title_kr: string,
}

interface Name {
  name_en: string,
  name_cn: string,
  name_jp: string,
  name_kr: string,
}

interface AddToCart {
  addToCart_en: string,
  addToCart_cn: string,
  addToCart_jp: string,
  addToCart_kr: string,
}

interface BuyNow {
  buyNow_en: string,
  buyNow_cn: string,
  buyNow_jp: string,
  buyNow_kr: string,
}

interface Summary {
  summary_en: string,
  summary_cn: string,
  summary_jp: string,
  summary_kr: string,
}

interface ImageSrc {
  imageSrc_en: string,
  imageSrc_cn: string,
  imageSrc_jp: string,
  imageSrc_kr: string,
}

interface Backorder {
  backorder_en: string,
  backorder_cn: string,
  backorder_jp: string,
  backorder_kr: string,
}

export interface TranslationBattery extends Label {
  device: string,
}

export interface TranslationGeneral {
  cn: string,
  en: string,
  jp: string,
  kr: string,
  key: string,
}

export interface TranslationSupport extends Label, Description {
  href: string
}

export interface TranslationMessages extends AddToCart, BuyNow {
  device: string
}

export interface TranslationProductSpecs extends Description, Label {
  device: string
}

export interface TranslationOverview extends ImageSrc {
  device: string
}

export interface TranslationComesWith extends Label {
  iconSrc: string,
  device: string
}

export interface TranslationFeature extends Description, Title {
  iconSrc: string,
  device: string
}

export interface TranslationNotice extends Description, Label {
  color: string,
  href: string,
  category: string,
}

export interface TranslationProductDetails extends GeneralName, MainFeature, Name, Backorder, Summary, Description {
  device: string
}

export const initialTranslationState: TranslationState = {};

export enum TranslationActionType {
  UpdateTranslation = "translation/updateTranslation",
}

type ConfigAction =
  | { type: TranslationActionType.UpdateTranslation; payload: TranslationState }

export const translation = (
  state: TranslationState = initialTranslationState,
  action: ConfigAction
) => {
  switch (action.type) {
    case TranslationActionType.UpdateTranslation: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
};
