/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC, useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { locationSelector } from "../store/selectors/routerSelector";
import OfferAPI from "../firebase/OfferAPI";
import SummarizeOffer from "../components/offer/SummarizeOffer";
import { fetchOffer } from "../store/actions/offerAction";
import { Offer } from "../store/reducers/offer";
import { offerSelector } from "../store/selectors/offerSelector";
import {recipientSelector} from "../store/selectors/recipientSelector";
import {updateRecipientAddress} from "../store/actions/recipientAction";
import {updateShippingPrice} from "../store/actions/shippingAction";
import {Routes} from "../models/Enums";
import Env from "../env/Env";
import useScript from "../utils/useScript";
import Header from "../components/offer/Header";
import ProcessCheckout from "../components/offer/processCheckout";

type Props = {};

const OfferPage: FC<Props> = () => {
  const scriptUrl = `https://www.paypal.com/sdk/js?client-id=${Env.PayPalClientId}`
  useScript(scriptUrl).then()
  const dispatch = useDispatch();
  const offer = useSelector(offerSelector);
  const {pathname} = useSelector(locationSelector);
  const recipient = useSelector(recipientSelector);

  useEffect(() => {
    const offerId = pathname.replace("/offer/", "");
    OfferAPI.getOffer(offerId).then((offer: Offer) => {
      if (!!offer) {
        if (offer.purchased) {
          alert("This offer link is already used.");
          window.location.pathname = Routes.IndexPage;
        } else {
          dispatch(fetchOffer(offer));
          dispatch(updateRecipientAddress({...recipient.address, country: offer.shippingAddress!.country}))
          dispatch(updateShippingPrice(offer.shippingPrice))
        }
      }
    });
  }, []);

  return (
    <Fragment>
      {offer.items.length > 0 && (
        <div css={css`background-color: #f3f3f3;`} className={"w-full flex-grow bg-gray-200"}>
          <div className={'max-w-screen-xl mx-auto w-full flex flex-col'}>
            <Header />
            <div className={'flex mb-2 px-1 lg:px-2 flex-wrap'}>
              <ProcessCheckout className={'w-full lg:w-1/2 px-1 lg:px-2'} />
              <SummarizeOffer className={'w-full lg:w-1/2 px-1 lg:px-2'} />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default OfferPage;