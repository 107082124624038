/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useState} from "react";
import {cx} from "emotion";
import {MdRemove, MdAdd} from 'react-icons/md'
import {clamp} from "../../utils/CommonUtil";
import {useDispatch} from "react-redux";
import {updateNotification} from "../../store/actions/notificationAction";

type Props = {
  className?:string,
  max: number,
  onCount: (count: number) => void,
  count: number
}

const Count:FC<Props> = ({count, className='', max, onCount}) => {
  const dispatch = useDispatch()
  const [internal, setInternal] = useState<{ count: any }>({
    count
  })

  const handleChange = (e:any) => {
    const value = e.target.value
    const numberRegex = /^[0-9]*$/;
    const spaceRegex = /^[\s]*$/;
    // const numberSpaceRegex = /^[0-9\s]*$/;
    if (spaceRegex.test(value)) {
      setInternal({count: ''})
    }
    else if (numberRegex.test(value)) {
      const clamped = clamp(0, max, Number(e.target.value))
      setInternal({count: clamped})
      onCount(clamped)
    }
  }

  const handleBlur = (e:any) => {
    if (internal.count === '') {
      setInternal({count: 0})
      onCount(0)
    }
  }

  const handleCount = (diff: number) => (e:any) => {
    if (count + diff > max) {
      dispatch(updateNotification({type: 'bulkOffer', on: true}))
    }
    const clamped = clamp(0, max, count + diff)
    setInternal({count: clamped})
    onCount(clamped)
  }

  return (
    <div className={cx(className, 'flex flex-wrap justify-center items-center')}>
      <div
        css={css`border-color: #727272;`}
        className={'flex items-center bg-black border mr-2.5'}
      >
        <div
          onClick={handleCount(-1)}
          className={cx(
            count === 0 || max === 0 ?
              'opacity-25 pointer-events-none'
              :
              'opacity-50 hover:opacity-100 cursor-pointer',
            'flex p-2 items-center'
          )}
        >
          <MdRemove className={'text-2xl leading-none'} />
        </div>
        <div
          css={css`border-color: #727272;`}
          className={'flex p-2 border-l border-r items-center'}>
          <input
            onChange={handleChange}
            onBlur={handleBlur}
            value={max === 0 ? max : internal.count === '' ? internal.count : count}
            type={'text'}
            className={cx(
              max === 0 ? 'opacity-25 pointer-events-none' : 'opacity-75 hover:opacity-100 focus:opacity-100',
              'bg-transparent w-10 text-white text-center'
            )} />
        </div>
        <div
          onClick={handleCount(1)}
          className={cx(
            count === max || max === 0 ?
              'opacity-25'
              :
              'opacity-75 hover:opacity-100 cursor-pointer',
            'flex p-2 items-center'
          )}
        >
          <MdAdd className={'text-2xl leading-none'} />
        </div>
      </div>
    </div>
  )
}

export default Count