import {CartItem} from "../store/reducers/cart";
import CartUtil from "./CartUtil";
import {Variant} from "../models/Product";

/**
 * Match with bhaptics-api
 * */
export interface OrderItem {
  id: string;
  name: string;
  qty: number;
  price: number;
  weight: number;
  hsCode: string;
  desc: string;
  variants: Variant[] | null
}

class ApiUtil {
  static getOrderItems = (cartItems: CartItem[]):OrderItem[] =>
    cartItems.map(cartItem => ({
      id: cartItem.id,
      name: cartItem.name,
      qty: cartItem.qty,
      price: cartItem.price,
      weight: cartItem.weight,
      hsCode: cartItem.hsCode,
      desc: (!!cartItem.variants && !!cartItem.variants.length) ? CartUtil.describeVariants(cartItem.variants) : '',
      variants: !cartItem.variants ? null : cartItem.variants
    }));
}

export default ApiUtil;
