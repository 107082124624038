export default class DateUtils {
  public static yyyymmdd = () => {
    const date = new Date();
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [
      date.getFullYear(),
      (mm > 9 ? "" : "0") + mm,
      (dd > 9 ? "" : "0") + dd
    ].join("");
  };

  public static toLocaleDate = (millis: number, timeZoneFrom:number=9):Date => {
    const timeZoneOffset = (new Date().getTimezoneOffset()) / (60)
    let date = new Date(millis)
    date.setHours(date.getHours() + timeZoneFrom + timeZoneOffset)
    return date
  }
}
