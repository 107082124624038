/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useEffect, useRef, useState} from "react";
import {cx} from "emotion";

type Props = {
  className?:string,
  price: number
}

const PriceTag:FC<Props> = ({className='', price}) => {
  return (
    <div className={cx(className, 'px-4 py-2 flex self-center rounded-full')}>
      <span className={'font-semibold text-white leading-none'}>{`$ ${price}`}</span>
    </div>
  )
}

export default PriceTag

