/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useEffect, useState} from "react";
import TrackingRequestForm from "../components/tracking/TrackingRequestForm";
import {Tracking, TrackingState} from "../types/Tracking";
import {Order} from "../firebase/CommonPayRequest";
import Validator from "../utils/Validator";
import Line from "../components/tracking/Line";
import OrderSummary from "../components/tracking/OrderSummary";
import ShippingInformation from "../components/tracking/ShippingInformation";
import Parser from "../utils/Parser";
import TrackingRequest from "../firebase/TrackingRequest";
import orderPlacedOnIcon from "../components/tracking/images/orderPlacedOn.png";
import packingOffIcon from "../components/tracking/images/packingOff.png";
import packingOnIcon from "../components/tracking/images/packingOn.png";
import shippedOffIcon from "../components/tracking/images/shippedOff.png";
import shippedOnIcon from "../components/tracking/images/shippedOn.png";
import VerticalOrderStatesFlowchart from "../components/tracking/VerticalOrderStatesFlowchart";
import HorizontalOrderStatesFlowchart from "../components/tracking/HorizontalOrderStatesFlowchart";
import Portal from "../components/portal";
import SendAnEmail from "../components/tracking/SendAnEmail";
import {useDispatch} from "react-redux";
import {startLoading, stopLoading} from "../store/actions/loadingAction";

export interface OrderState {
  id: TrackingState,
  label: string,
  description: string,
  icons: string[]
}

const orderStates: OrderState[] = [
  {
    id: TrackingState.orderPlaced,
    label: 'Order Placed',
    description: 'We have received your order. Please give us 2 business days to process your order.\nWe will update estimated ship date once we start preparing your order. In-stock orders are usually shipped out within 3 business days.',
    icons: ['', orderPlacedOnIcon]
  },
  {
    id: TrackingState.packing,
    label: 'Packing',
    description: 'Your order has been confirmed and we are preparing your order.\nYour order is currently expected to be shipped out on ',
    icons: [packingOffIcon, packingOnIcon]
  },
  {
    id: TrackingState.shipped,
    label: 'Shipped',
    description: 'We have shipped your order via UPS. Tracking information: ',
    icons: [shippedOffIcon, shippedOnIcon]
  }
]

type Props = {
  className?:string
}

const TrackingPage:FC<Props> = ({className=''}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (!!window) {
      const orderId = Parser.getOrderId(window)
      if (!!orderId) {
        dispatch(startLoading)
        TrackingRequest.get('', '', orderId).then((res:any) => {
          if (!!res) {
            dispatch(stopLoading)
            setState((state: any) => ({
              ...state,
              ...res
            }))
          }
        })
      }
    }

  }, [])
  const [state, setState] = useState<{
    tracking?: Tracking,
    order?: Order,
    orderNumber: string,
    email: string
  }>({
    tracking: undefined,
    order: undefined,
    orderNumber: '',
    email: ''
  })

  const [open, setOpen] = useState({
    'sendAnEmail': false
  })

  const handleOpen = (key: string) => (e:any) => {
    setOpen((state: any) => ({
      ...state,
      [key]: true
    }))
  }

  const handleClose = (key: string) => (e:any) => {
    setOpen((state: any) => ({
      ...state,
      [key]: false
    }))
  }

  const handleSuccess = (data: {order: any, tracking: any}) => {
    setState((state: any) => ({
      ...state,
      ...data
    }))
  }

  return (
    <div
      css={css`
        @media(max-height: 100vh) {
          flex-grow: 1;
          background-color: #0f1014;
        }
      `}
      className={'w-full'}>
      {
        !state.tracking &&
        <div
          css={css`background-color: #0f1014;`}
          className={'w-full flex items-center justify-center'}
        >
          <div
            css={css`box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.5);`}
            className={'max-w-screen-sm px-2 mt-20 w-full justify-center items-center flex flex-col pt-5 pb-6'}
          >
            <span className={'text-3xl text-white text-center font-extrabold leading-none'}>Order Status</span>
            <span className={'font-medium mt-1 text-white text-lg text-center leading-tight'}>Track your order with your Order ID.</span>
          </div>
        </div>
      }
      {!state.tracking && <TrackingRequestForm onSuccess={handleSuccess}/>}
      {!!state.tracking && (
        <div
          css={css`background-color: #0f1014;`}
          className={'w-full flex items-center justify-center'}
        >
          <div className={'max-w-screen-lg px-2 mt-20 w-full flex flex-col'}>
            <div className={'w-full flex flex-col items-center justify-center'}>
              <p className={'text-white pt-5 text-2xl font-black leading-tight'}>Hello, <span css={css`color: #f7b500`}>{Validator.tryGetValue(state, 'order', 'shippingAddress', 'firstName')}!</span></p>
              <span className={'text-white text-2xl font-black leading-tight text-center'}>Thank you for your order.</span>
              <Line className={'my-4'} width={'85%'} />
              <span className={'text-white text-sm leading-tight text-center font-semibold'}>{`Order #(${Validator.tryGetValue(state, 'order', 'invoiceNumber')})`}</span>
            </div>
            <span className={'text-2xl mt-32 ml-10 text-white italic font-semibold leading-tight'}>Order Status</span>
            <div className={'mt-5 w-full bg-black py-12 px-2 sm:px-8 flex flex-col items-center justify-center'}>
              <div className={'flex sm:hidden flex flex-col w-full'}>
                <VerticalOrderStatesFlowchart
                  tracking={state.tracking}
                  orderStates={orderStates} />
              </div>
              <div className={'sm:flex w-full flex-col hidden items-center justify-center'}>
                <HorizontalOrderStatesFlowchart
                  tracking={state.tracking}
                  orderStates={orderStates} />
              </div>
            </div>
            <span className={'text-2xl mt-20 ml-10 text-white italic font-semibold leading-tight'}>Order Summary</span>
            <OrderSummary className={'mt-5'} order={state.order} />
            <div className={'flex items-center justify-end'}>
              <span className={'italic mt-2 leading-tight font-medium'} css={css`color: #0091ff;`}>Taxes and duties are not included in the price!</span>
            </div>
            <span className={'text-2xl mt-20 ml-10 text-white italic font-semibold leading-tight'}>Shipping Information</span>
            <ShippingInformation className={'mt-5'} shipping={Validator.tryGetValue(state.order, 'shippingAddress')} />
            <div
              css={css`color: #747474`}
              className={'mt-16 flex flex-col items-center justify-center'}>
              <span className={'font-semibold text-lg text-center leading-tight'}>Is there an issue with your order?</span>
              <span className={'font-semibold text-lg text-center leading-tight'}>Contact us at <a target={"_top"} className={'hover:opacity-75'} href={"mailto:support@bhaptics.com"}>support@bhaptics.com</a> with your Order No.</span>
            </div>
            <div className={'flex my-12 flex-col md:flex-row items-center justify-center'}>
              <button
                onClick={handleOpen('sendAnEmail')}
                className={'border px-12 mx-2 mt-4 mb-16 md:mb-32 hover:opacity-75 py-4 w-56'}
                css={css`
                  border-image-source: linear-gradient(to bottom, #eeeeee, #0f1014 149%);
                  border-image-slice: 1;
                `}
              >
                <span className={'text-white'}>Send an e-mail</span>
              </button>
              <a
                href={'/'}
                css={css`
                  border-image-source: linear-gradient(to bottom, #eeeeee, #0f1014 149%);
                  border-image-slice: 1;
                `}
                className={'border flex justify-center px-12 mx-2 mt-4 mb-16 md:mb-32 hover:opacity-75 py-4 w-56'}>
                <span className={'text-white'}>Back to Shop</span>
              </a>
            </div>
          </div>
          {
            open.sendAnEmail &&
            <Portal>
              <SendAnEmail
                onClose={handleClose('sendAnEmail')}
                orderId={state.tracking.orderId} trackingId={state.tracking.id} />
            </Portal>
          }
        </div>
      )}
    </div>
  )
}

export default TrackingPage