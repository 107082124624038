/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC} from "react";
import {FaCheck} from "react-icons/fa";
import {useSelector} from "react-redux";
import {cartItemsSelector} from "../../../store/selectors/cartSelector";
import {localeSelector} from "../../../store/selectors/configSelector";
import {translationProductDetailsSelector} from "../../../store/selectors/translationSelector";
import Validator from "../../../utils/Validator";
import CartUtil from "../../../utils/CartUtil";

type Props = {};

const Added: FC<Props> = () => {
  const cartItems = useSelector(cartItemsSelector);
  const locale = useSelector(localeSelector)
  const translationProductDetails = useSelector(translationProductDetailsSelector)

  return (
    <div
      className={"z-50 fixed inset-0 w-full h-full flex items-center justify-center"}
    >
      <div
        css={css`background-color: #0f1014;`}
        className={"flex flex-col rounded text-white items-center justify-center pb-8 pt-10 px-20"}
      >
        <FaCheck
          css={css`color: #6dd400;`}
          className={"mb-4 text-6xl"}
        />
        <div className={"text-center whitespace-pre-wrap leading-snug mb-4"}>
          {cartItems.map((cartItem, index) => {
            let name = Validator.tryGetValue(translationProductDetails, cartItem.id, `name_${locale.languageCode}`)
            let variants = ''
            if (!!cartItem.variants && !!cartItem.variants.length) {
              variants = CartUtil.describeVariants(cartItem.variants)
            }

            return (
              <span
                key={index}
                className={"block text-left whitespace-no-wrap"}
              >
                {`${name}${!!variants ? `(${variants})` : ``}: ${cartItem.qty}`}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Added;
