/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";
import desktopBg from "./images/desktop/img-support.jpg";
import mobileBg from "./images/mobile/default.jpg";
import { useSelector } from "react-redux";
import { localeSelector } from "../../../store/selectors/configSelector";
import whiteArrow from './images/desktop/icon-leftArrow-white.png'
import blackArrow from './images/desktop/icon-leftArrow-black.png'
import {
  translationGeneralSelector,
  translationSupportSelector
} from "../../../store/selectors/translationSelector";
import Validator from "../../../utils/Validator";

type Props = {};

const Dot = () =>
  <div
    css={css`min-width: 1.5rem; min-height: 1.5rem`}
    className={'w-6 h-6 hidden sm:flex items-center justify-center'}
  >
    <div
      css={css`min-width: .5rem; min-height: .5rem`}
      className={'mb-2 lg:mb-0 inline-block rounded-full w-2 h-2 bg-white'}
    />
  </div>

const Support: FC<Props> = () => {
  const locale = useSelector(localeSelector);
  const translationSupport = useSelector(translationSupportSelector)
  const translationGeneral = useSelector(translationGeneralSelector)
  let head = Validator.tryGetValue(translationGeneral, 'support', locale.languageCode)
  if (!head) {
    head = 'Support'
  }

  return (
    !!translationSupport ?
      <div className={"w-full bg-black mx-auto"}>
        <div
          css={css`background-color: #18191d;`}
          className={"w-full max-w-screen-xl text-white mx-auto flex flex-col md:flex-row"}
        >
          <div
            css={css`
              height: 34.5rem;
              @media(max-width: 767px) {height: 24rem}
            `}
            className={'flex-1 w-full'}
          >
            <img src={desktopBg} className={'hidden md:block object-cover w-full h-full'} />
            <img src={mobileBg} className={'block md:hidden object-cover w-full h-full'} />
          </div>
          <div className={'flex-1 w-full'}>
            <div className={'flex flex-col justify-center h-full py-10 md:py-0 px-5 lg:px-16'}>
              <span className={'text-3xl lg:text-4xl font-extrabold'}>{head}</span>
              <div className={'py-5 lg:py-10 flex flex-col'}>
                {
                  translationSupport.map((value: any, index: number) => {
                    return (
                      <div className={'flex mb-5 flex-no-wrap'} key={index}>
                        <Dot />
                        <div className={'sm:ml-4 flex flex-col md:flex-row justify-between flex-grow flex-wrap items-start'}>
                          <div className={'flex sm:mb-4'}>
                            <span className={'text-sm lg:text-base flex-grow leading-tight'}>
                              {value[`description_${locale.languageCode}`]}
                            </span>
                          </div>
                          <div
                            css={css`
                              @media(max-width: 639px) {
                                width: 100%;
                                & > a:first-of-type {width: 65%; max-width: 230px}
                              }
                            `}
                            className={'flex mt-2.5 sm:-mt-3 flex-grow justify-start sm:justify-end'}>
                            <a
                              css={css`
                                &:hover {
                                  & > span:first-of-type {color: black}
                                  & > img:first-of-type {display: none}
                                  & > img:nth-of-type(2) {display: block}
                                }
                              `}
                              className={'border flex items-center justify-between w-48 px-3 sm:px-6 py-2.5 border-white hover:bg-white'} target={"_blank"} href={value.href}>
                              <span className={'text-sm lg:text-base flex-grow whitespace-no-wrap'}>
                                {value[`label_${locale.languageCode}`]}
                              </span>
                              <img className={'block'} src={whiteArrow}/>
                              <img className={'hidden'} src={blackArrow}/>
                            </a>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      :
      null
  );
};

export default Support;
