import {Tracking} from "../types/Tracking";
import Env from "../env/Env";

class TrackingsRequest {
  private trackingsUrl = `${Env.ApiUrl}/trackings`

  public get:(invoiceNumber?:string, email?: string, orderId?: string) => any = async (invoiceNumber='', email='', orderId='') => {
    try {
      const res = await fetch(`${this.trackingsUrl}/get?invoiceNumber=${invoiceNumber}&email=${email}&orderId=${orderId}`);
      if (res.status === 200) {
        return res.json()
      } else {
        console.error(res)
      }
    }
    catch (e) {
      console.error(e)
    }
  }

  public update = async (tracking: Tracking) => {
    try {
      const res = await fetch(`${this.trackingsUrl}/update`, {
        method: "POST",
        body: JSON.stringify(tracking),
        headers: {
          "Content-Type": "application/json"
        }
      })
      return res
    } catch (e) {
      console.error(e)
    }
  }
}

export default new TrackingsRequest()