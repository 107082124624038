/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC} from "react";
import { useSelector } from "react-redux";
import { productsSelector } from "../../../store/selectors/productsSelector";
import DisplayProducts from "../DisplayProducts";
import {cx} from "emotion";
import ContactButton from "./ContactButton";

type Props = {};

export const ProductList: FC<Props> = () => {
  const products = useSelector(productsSelector);
  let categories: string[] = []
  if (products.length > 0) {
    categories = products.reduce((prev: string[], current, index) => {
      if (prev.includes(current.category)) {
        return prev
      }
      else {
        prev = [...prev, current.category]
        return prev
      }
    }, [])
  }

  return (
    <div
      css={css`background-color: #0f1014;`}
      className={"w-full mx-auto text-white"}
    >
      <div className={'max-w-screen-xl pb-12.5 mx-auto'}>
        <div className={'flex flex-col md:px-12 xl:px-36'}>
          {
            !!categories.length &&
              categories.map((category, index) => {
                const filtered = products.filter((product) => product.category === category)

                const friendly = (key: string) => {
                  switch (key) {
                    case 'accessory': {
                      return {
                        head: 'Accessories',
                        subhead: '*Accessories cannot be purchased on their own.'
                      }
                    }
                    default: {
                      return {
                        head: '',
                        subhead: ''
                      }
                    }
                  }
                }

                return (
                  <div
                    css={css`
                      & > span:first-of-type {line-height: 1}
                      & > span:nth-of-type(2) {line-height: 1}
                    `}
                    className={'flex flex-col'} key={index}>
                    <span className={cx({'hidden': !friendly(category).head}, 'px-5 md:px-0 mt-5 capitalize text-2xl font-bold')}>{friendly(category).head}</span>
                    <div className={'px-5 md:px-0'}>
                      <span
                        css={css`color: #979797;`}
                        className={cx({'hidden': !friendly(category).subhead}, 'mt-2.5 text-sm')}>{friendly(category).subhead}</span>
                      {
                        category === 'accessory' &&
                        <div className={'flex flex-wrap items-center'}>
                          <span>{`To purchase accessories only, contact`}&nbsp;</span>
                          <ContactButton className={'underline opacity-50 hover:opacity-100'} />
                        </div>
                      }
                    </div>
                    <div className={'my-7.5'}>
                      <DisplayProducts products={filtered} />
                    </div>
                  </div>
                )
              })
          }
        </div>
      </div>
    </div>
  );
};
