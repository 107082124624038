/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useState} from "react";
import {cx} from "emotion";
import {useDispatch, useSelector} from "react-redux";
import {recipientSelector} from "../../../store/selectors/recipientSelector";
import PaymentwallButton from "../../checkout/PaymentwallButton";
import PaymentwallRequest from "../../../firebase/PaymentwallRequest";
import Popup from "../../popup/Popup";
import {startLoading, stopLoading} from "../../../store/actions/loadingAction";
import Validator from "../../../utils/Validator";
import {localeSelector} from "../../../store/selectors/configSelector";
import {translationGeneralSelector} from "../../../store/selectors/translationSelector";
import PaypalButtonV2 from "../../payments/PaypalButtonV2";
import {offerSelector} from "../../../store/selectors/offerSelector";
import OfferAPI from "../../../firebase/OfferAPI";

type Props = {
  className?:string,
  active: boolean,
  onComplete: (callback?: any) => void
}

const DoPayment:FC<Props> = ({className='', onComplete, active}) => {
  const dispatch = useDispatch()
  const offer = useSelector(offerSelector);
  const locale = useSelector(localeSelector)
  const translationGeneral = useSelector(translationGeneralSelector)
  const [url, setUrl] = useState("");
  const recipient = useSelector(recipientSelector)
  const {address: {country}} = recipient;
  const discountPrice = 'discountPrice' in offer ? offer.discountPrice : 0

  const requiredItems = offer.items.map((item) => ({
    hsCode: item.hsCode,
    id: item.id,
    name: item.name,
    price: item.price,
    qty: item.qty,
    weight: item.weight,
    variants: item.variants
  }))
  const shippingAddress = {
    address1: recipient.address.address1,
    address2: recipient.address.address2,
    zip: recipient.address.zip,
    city: recipient.address.city,
    state: recipient.address.state,
    country: country.name,
    email: recipient.personal.email,
    firstName: recipient.personal.firstName,
    lastName: recipient.personal.lastName,
    tel: recipient.personal.tel,
  }

  const handleClickPaymentwall = async () => {
    dispatch(startLoading)
    let param = {
      items: requiredItems,
      shippingPrice: offer.shippingPrice,
      shippingAddress,
      discountPrice,
      offerId: offer.id
    }

    const widget = await PaymentwallRequest.getWidgetV2(
      param,
      async (data:any) => {
        dispatch(startLoading)
        window.removeEventListener("message", widget.callbackRef);
        setUrl("")
        onComplete(() => dispatch(stopLoading))
      }
    )
    dispatch(stopLoading)
    if (!widget.url) {
      // todo - if not widget.url, please notify/alert it
    }
    else {
      setUrl(widget.url)
    }
  }

  const handleClosePopup = () => {
    dispatch(stopLoading)
    setUrl('')
  }

  return (
    <div className={cx(className)}>
      <div className={'flex items-center justify-between'}>
        <span className={'text-xl font-semibold'}>
          {Validator.tryGetValue(translationGeneral, 'payment', locale.languageCode)}
        </span>
      </div>
      {
        active &&
        <div className={'mt-8'}>
          <PaypalButtonV2 data={offer} onComplete={onComplete} />
          <PaymentwallButton className={'mt-4'} onClick={handleClickPaymentwall} />
          {
            url &&
              <Popup closePopup={handleClosePopup}>
                <iframe src={url} />
              </Popup>
          }
        </div>
      }
    </div>
  )
}

export default DoPayment