import React, {FC} from "react";
import {css, jsx} from "@emotion/core";
import {Link} from "react-router-dom";
import Validator from "../../../utils/Validator";
import {useSelector} from "react-redux";
import {translationGeneralSelector} from "../../../store/selectors/translationSelector";
import {localeSelector} from "../../../store/selectors/configSelector";

type Props = {
  className?:string
  to: string
}

const ToDetailPage:FC<Props> = ({className='', to}) => {
  const generalTranslation = useSelector(translationGeneralSelector)
  const locale = useSelector(localeSelector)

  let toDetailPage = Validator.tryGetValue(generalTranslation, 'toDetailPage', locale.languageCode)
  if (!toDetailPage) {
    toDetailPage = 'To Detail Page'
  }

  return (
    <Link to={to}>
      <div
        css={css`border-color: #4e4e51;background-color: #1e1e21;`}
        className={'py-2 opacity-50 hover:opacity-100 flex self-stretch text-center'}>
        <span className={'text-xs leading-none w-full'}>{toDetailPage}</span>
      </div>
    </Link>
  )
}

export default ToDetailPage