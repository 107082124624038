export interface Product {
  id: string,
  name: string,
  category: string,
  price: number,
  hsCode: string,
  weight: number,
  variants?: Variant[]
}

export interface Variant {
  type: string,
  kind: any
}

export enum ProductId {
  TactsuitX40 = "tactsuit-x40",
  TactsuitX16 = "tactsuit-x16",
  TactotForBackPackPc = "tactot-for-backpack-pc",
  TactosyForHands = "tactosy-for-hands",
  TactosyForArms = "tactosy-for-arms",
  TactosyForFeet = "tactosy-for-feet",
  Tactal = "tactal",
  TactsuitXAudioAccessories = "tactsuit-x-audio-accessories",
  MeshLiningForTactsuitX = "mesh-lining-for-tactsuit-x",
  InnerLiningForTactosyForArms = "inner-lining-for-tactosy-for-arms",
}

export enum Category {
  Hardware = "hardware",
  Accessory = "accessory"
}