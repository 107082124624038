import { RouterState } from "connected-react-router";
import {ProductId} from "../models/Product";

class RouterUtil {
  static getProductId = (router: RouterState): ProductId => {
    const split = router.location.pathname.split("/");
    const last = split[split.length - 1];
    if (last !== "") {
      return last as ProductId;
    }
    return split[split.length - 2] as ProductId;
  };
}

export default RouterUtil;
