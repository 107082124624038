/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useState} from "react";
import {cx} from "emotion";
import {MdRemove, MdAdd} from 'react-icons/md'
import {FaTrashAlt} from 'react-icons/fa'
import {clamp} from "../../../utils/CommonUtil";
import {useDispatch} from "react-redux";
import {updateNotification} from "../../../store/actions/notificationAction";

type Props = {
  className?:string,
  max: number,
  onCount: (count: number) => void,
  count: number
}

const Minus = (props:any) =>
  <div
    onClick={props.onClick}
    className={cx(
      props.className,
      props.count === 0 || props.max === 0 ?
        'opacity-25 pointer-events-none'
        :
        'opacity-50 hover:opacity-100 cursor-pointer',
      'items-center'
    )}
  >
    <MdRemove className={'text-2xl leading-none'} />
  </div>

const Plus = (props:any) =>
  <div
    onClick={props.onClick}
    className={cx(
      props.className,
      props.count === props.max || props.max === 0 ?
        'opacity-25'
        :
        'opacity-75 hover:opacity-100 cursor-pointer',
      'items-center'
    )}
  >
    <MdAdd className={'text-2xl leading-none'} />
  </div>

const Input = (props:any) => (
  <div
    css={css`border-color: #727272;`}
    className={cx(props.className, 'flex items-center')}>
    <input
      onChange={props.onChange}
      onBlur={props.onBlur}
      value={props.value}
      type={'text'}
      className={cx(
        props.max === 0 ? 'opacity-25 pointer-events-none' : 'opacity-75 hover:opacity-100 focus:opacity-100',
        'bg-transparent w-11 lg:w-20 text-white text-center'
      )} />
  </div>
)

const Count:FC<Props> = ({count, className='', max, onCount}) => {
  const dispatch = useDispatch()
  const [internal, setInternal] = useState<{ count: any }>({
    count
  })

  const handleChange = (e:any) => {
    const value = e.target.value
    const numberRegex = /^[0-9]*$/;
    const spaceRegex = /^[\s]*$/;
    // const numberSpaceRegex = /^[0-9\s]*$/;
    if (spaceRegex.test(value)) {
      setInternal({count: ''})
    }
    else if (numberRegex.test(value)) {
      const clamped = clamp(0, max, Number(e.target.value))
      setInternal({count: clamped})
      onCount(clamped)
    }
  }

  const handleBlur = (e:any) => {
    if (internal.count === '') {
      setInternal({count: 0})
      onCount(0)
    }
  }

  const handleCount = (diff: number) => (e:any) => {
    if (count + diff > max) {
      dispatch(updateNotification({type: 'bulkOffer', on: true}))
    }
    const clamped = clamp(0, max, count + diff)
    setInternal({count: clamped})
    onCount(clamped)
  }

  const reset = (e:any) => {
    setInternal({count: 0})
    onCount(0)
  }

  return (
    <div className={cx(className, 'flex flex-wrap items-center')}>
      <div
        css={css`border-color: #727272;`}
        className={'flex items-center h-full bg-black border mr-2.5'}
      >
        <Minus className={'hidden p-1.5 lg:flex'} onClick={handleCount(-1)} count={count} max={max} />
        <Input onChange={handleChange}
               className={'lg:p-1.5 lg:border-l lg:border-r'}
               max={max}
               onBlur={handleBlur}
               value={max === 0 ? max : internal.count === '' ? internal.count : count} />
        <Plus className={'hidden p-1.5 lg:flex'} onClick={handleCount(1)} count={count} max={max} />
        <div
          css={css`border-color: #727272;`}
          className={'flex border-l h-full px-0.5 flex-col lg:hidden'}>
          <Minus css={css`& svg {font-size: 1rem}`} className={'flex flex-1'} onClick={handleCount(-1)} count={count} max={max} />
          <Plus css={css`& svg {font-size: 1rem}`} className={'flex flex-1'} onClick={handleCount(1)} count={count} max={max} />
        </div>
      </div>
      <FaTrashAlt
        onClick={reset}
        css={css`color: #969696;`}
        className={cx(
          count === 0 || max === 0 ?
            'pointer-events-none opacity-25' :
            'cursor-pointer opacity-75 hover:opacity-100',
          'text-2xl leading-none hidden lg:block'
        )} />
    </div>
  )
}

export default Count