import {
  combineReducers,
  createStore,
  applyMiddleware,
  compose,
  Reducer
} from "redux";
import {
  connectRouter,
  routerMiddleware,
  RouterState
} from "connected-react-router";
import thunk from "redux-thunk";
import { products } from "./products";
import { cart, CartState } from "./cart";
import { config, ConfigState } from "./config";
import { offer, Offer as OfferState } from "./offer";
import { sales, SalesState } from "./sales";
import {translation, TranslationState} from "./translation";
import { createBrowserHistory } from "history";
import {ShippingState, shipping} from "./shipping";
import {recipient, RecipientState} from "./recipient";
import {payer, PayerState} from "./payer";
import {notification, Notification} from "./notification";
import {countries} from "./countries";
import {Country} from "../../firebase/Shipping";
import {Subscription, subscription} from "./subscription";
import {Loading, loading} from "./loading";
import {Product} from "../../models/Product";

export const history = createBrowserHistory();

const rootReducer = combineReducers<Reducer>({
  router: connectRouter(history),
  products,
  cart,
  sales,
  config,
  offer,
  translation,
  shipping,
  recipient,
  payer,
  countries,
  subscription,
  loading,
  notification,
});

export type RootState = {
  router: RouterState;
  products: Product[];
  cart: CartState;
  config: ConfigState;
  offer: OfferState;
  sales: SalesState;
  translation: TranslationState;
  shipping: ShippingState;
  recipient: RecipientState;
  payer: PayerState;
  countries: Country[];
  subscription: Subscription;
  loading: Loading;
  notification: Notification
};

const store = createStore(
  rootReducer,
  compose(applyMiddleware(routerMiddleware(history), thunk))
);

export default store;
