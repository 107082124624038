import { initialSalesState, SalesState } from "../store/reducers/sales";

class Sales implements SalesState {
  until = "";
  visitTime = new Date().toISOString();
  constructor(data: any) {
    this.until = "until" in data ? data.until : new Date().toISOString();
    this.visitTime =
      "visitTime" in data ? data.visitTime : new Date().toISOString();
  }
}

class SalesLocalStorageService {
  private salesPrefix = "_bhaptics_shop_sales__v1__";
  private localStorage: any;
  constructor() {
    this.localStorage = localStorage;
  }

  public setSales = (sales: SalesState) => {
    const data = {
      sales,
      time: new Date().getTime()
    };
    this.localStorage.setItem(this.salesPrefix, JSON.stringify(data));
  };

  public getSales = (): SalesState => {
    const dataStr = this.localStorage.getItem(this.salesPrefix);
    const data = JSON.parse(dataStr);
    if (!!data) {
      const currentTime = new Date().getTime();
      const renewalTimeInMillis = 0;
      if (!!data.time && currentTime - data.time > renewalTimeInMillis) {
        this.setSales(initialSalesState);
        return initialSalesState;
      }
      try {
        return new Sales({ ...data.sales });
      } catch (e) {
        console.log(e);
        this.setSales(initialSalesState)
        return initialSalesState;
      }
    } else {
      this.setSales(initialSalesState);
      return initialSalesState;
    }
  };
}

export default new SalesLocalStorageService();