/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import * as React from "react";
import {IoMdClose} from 'react-icons/io'
import {useEffect, useRef, useState} from "react";
import {getDimension} from "../../utils/CommonUtil";

interface Props {
  closePopup: () => void;
  children: any;
  width?: string;
  height?: string;
}

const Popup: React.FC<Props> = ({
  width,
  height,
  closePopup,
  children = ""
}) => {
  const ref = useRef<any>(null)
  const [state, setState] = useState({
    height: 900
  });

  useEffect(() => {
    updateDimension();
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [ref]);

  const updateDimension = () => {
    if (ref && ref.current) {
      const dimension = getDimension(ref.current)
      setState(state => ({ ...state, height: dimension.height }));
    }
  };

  return (
    <div
      ref={ref}
      css={css`
        background-color: rgba(0, 0, 0, 0.5);
        transition: opacity 500ms;
        z-index: 100;
      `}
      className={
        "w-full h-full inset-0 m-auto fixed flex justify-center items-center"
      }
    >
      <div
        css={css`
          & > iframe:first-of-type {
            width: 100%;
            height: 100%;
          }
          height: ${state.height >= 912 ? "912px" : "100%"};
          transition: all 5s ease-in-out;
        `}
        className={
          "bg-white border border-white max-w-screen-lg overflow-y-auto relative flex flex-col justify-center items-center rounded-sm"
        }
        onMouseDown={e => {e.stopPropagation();}}
        onClick={e => {e.stopPropagation();}}
      >
        <div className={'flex bg-black w-full items-center justify-end py-4'}>
          <IoMdClose
            onClick={closePopup}
            css={css`color: #FFCA2B`}
            className={'text-2xl mr-4 cursor-pointer hover:opacity-75'} />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Popup;
