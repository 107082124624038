class LocaleUtil {
  public static getFontFamily = (languageCode: string) => {
    const fontFamilies: any = {
      'cn': 'Muli, Helvetica, Arial, "Microsoft Yahei","微软雅黑", STXihei, "华文细黑", sans-serif;',
      'jp': 'Muli, Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;',
      'en': 'Muli, -apple-system, "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
      'kr': 'Muli, -apple-system, "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif'
    }
    if (languageCode in fontFamilies) {
      return fontFamilies[languageCode]
    }
    else {
      return fontFamilies['en']
    }
  }

  public static toKoreaWon = (money: number) => {
    const str = money.toString().split('.');
    if (str[0].length >= 5) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  }
}

export default LocaleUtil