/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useState} from "react";
import {cx} from "emotion";
import {useSelector} from "react-redux";
import {localeSelector} from "../../../store/selectors/configSelector";
import {translationGeneralSelector} from "../../../store/selectors/translationSelector";
import Validator from "../../../utils/Validator";
import {ContinueButton} from "../../checkout/Common";
import {shippingSelector} from "../../../store/selectors/shippingSelector";

type Props = {
  className?:string
  editable: boolean,
  active: boolean,
  onUpdate: (stepIndex: number) => (e:any) => void
}

const AcceptTerms:FC<Props> = ({className='', editable, active, onUpdate}) => {
  const locale = useSelector(localeSelector)
  const translationGeneral = useSelector(translationGeneralSelector)
  const [checked, setChecked] = useState(false)
  const shipping = useSelector(shippingSelector)

  const handleContinue = (e:any) => {
    onUpdate(2)(e)
  }

  const handleChecked = (e:any) => {
    const nextChecked = e.target.checked
    setChecked(nextChecked)
  }

  return (
    <div className={cx(className)}>
      <div className={'flex items-center justify-between'}>
        <span className={'text-xl font-semibold'}>
          {Validator.tryGetValue(translationGeneral, 'terms', locale.languageCode)}
        </span>
        <button
          onClick={onUpdate(1)}
          className={cx(
            editable ? 'opacity-100 hover:opacity-75' : 'pointer-events-none opacity-0',
            'border rounded px-2 py-1')}
        >
          <span>Edit</span>
        </button>
      </div>
      {
        editable &&
          <div className={'my-2 flex flex-col justify-center'}>
            <span className={'font-semibold'}>{Validator.tryGetValue(translationGeneral, 'acceptTermsPhrase', locale.languageCode)}</span>
            <span className={'mt-2'}>I agree</span>
          </div>
      }
      {
        active &&
        <div className={'my-2 flex flex-col justify-center'}>
          <span className={'font-medium leading-tight'}>
            {Validator.tryGetValue(translationGeneral, 'acceptTermsPhrase', locale.languageCode)}
            <a
              className={'ml-4'}
              target={"_blank"}
              href={"https://www.bhaptics.com/legals/terms-and-conditions/"}
            >
              <span className={'italic underline hover:opacity-75 text-gray-700'}>{Validator.tryGetValue(translationGeneral, 'readMore', locale.languageCode)}</span>
            </a>
          </span>
          <div className={'flex items-center mt-2'}>
            <input
              checked={checked}
              onChange={handleChecked}
              className={"mr-2 leading-tight"} type={"checkbox"}
            />
            <span className={'no-underline'}>I agree</span>
          </div>
          <div className={'px-4'}>
            <ContinueButton
              className={cx( !checked || shipping.price < 0 ? 'pointer-events-none opacity-25' : 'opacity-100 hover:opacity-75', "cursor-pointer mt-3 py-2")}
              onClick={handleContinue}
            >
              {Validator.tryGetValue(translationGeneral, 'continue', locale.languageCode)}
            </ContinueButton>
          </div>
        </div>
      }
    </div>
  )
}

export default AcceptTerms