/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import {FC} from "react";
import {cx} from "emotion";
import {useSelector} from "react-redux";
import {translationProductDetailsSelector} from "../../../store/selectors/translationSelector";
import {localeSelector} from "../../../store/selectors/configSelector";
import Validator from "../../../utils/Validator";
import {productSelector} from "../../../store/selectors/productsSelector";
import {ProductId} from "../../../models/Product";

type Props = {
  className?:string,
  id: string,
}

const Name:FC<Props> = ({id, className=''}) => {
  const product = useSelector(productSelector(id as ProductId))
  const translationProductDetails = useSelector(translationProductDetailsSelector)
  const locale = useSelector(localeSelector);
  let name = Validator.tryGetValue(translationProductDetails, id, `name_${locale.languageCode}`)

  if (!name) {
    name = product.name
  }

  return (
    <span
      css={css`min-height: 3rem`}
      className={cx(
        className,
        'text-2xl font-bold leading-none whitespace-pre-wrap'
      )}
    >{name}</span>
  )
}

export default Name