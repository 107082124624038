/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC} from "react";
import {Locale as LocaleDefinition} from "../../store/reducers/config";
import {cx} from "emotion";
import {useDispatch} from "react-redux";
import {updateLocale} from "../../store/actions/configAction";
import locales from './data/locales.json';
import LocaleUtil from "../../utils/LocaleUtil";

type Props = {
  className?:string,
  onClose: (e:any) => void
}

const Locales:FC<Props> = ({className='', onClose}) => {
  const dispatch = useDispatch()
  const handleClick = (locale: LocaleDefinition) => (e: any) => {
    dispatch(updateLocale(locale))
    onClose(e)
  }

  return (
    <div className={cx(className, 'flex flex-col border rounded')}>
      {
        locales.map((locale: LocaleDefinition, index: number) => {
          return (
            <div
              css={css`font-family: ${LocaleUtil.getFontFamily(locale.languageCode)};`}
              onClick={handleClick(locale)}
              key={index} className={'flex p-1 hover:bg-gray-800 items-center justify-between'}>
              <span>{locale.languageName}</span>
            </div>
          )
        })
      }
    </div>
  )
}

export default Locales

