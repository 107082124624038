import {CartActionType, CartItem} from "../reducers/cart";
import {cartItemsSelector} from "../selectors/cartSelector";
import CartLocalStorageService from "storage/CartLocalStorageService";
import GaUtil from "../../utils/GaUtil";
import equal from "fast-deep-equal/react";

export const updateCartItems = (cartItems: CartItem[]) => async (
  dispatch: any,
  getState: any
) => {
  const items = cartItems.filter(c => c.qty > 0);
  CartLocalStorageService.setCart(items);
  dispatch({
    type: CartActionType.UpdateCartItems,
    payload: items
  });

  GaUtil.updateCart(items)
};

export const updateCartItem = (cartItem: CartItem) => async (
  dispatch: any,
  getState: any
) => {
  const cartItems = cartItemsSelector(getState());
  const nextItems = cartItems.map((_cartItem) => {
    if (!cartItem.variants || !cartItem.variants.length) {
      return _cartItem.id === cartItem.id ? cartItem : _cartItem
    }
    else {
      if (!_cartItem.variants || !_cartItem.variants.length) {
        return _cartItem;
      }
      else {
        const stringifiedA = JSON.stringify([...cartItem.variants].sort((a, b) => a.type.localeCompare(b.type)))
        const stringifiedB = JSON.stringify([..._cartItem.variants].sort((a, b) => a.type.localeCompare(b.type)))
        return stringifiedA === stringifiedB && cartItem.id === _cartItem.id ? cartItem : _cartItem
      }
    }
  });
  dispatch(updateCartItems(nextItems));
  GaUtil.updateCart(nextItems)
};

export const updateCartItemV2 = (cartItem: CartItem) => async (dispatch: any, getState: any) => {
  const cartItems = cartItemsSelector(getState())
  let nextCartItems = []
  if ('variants' in cartItem) {
    nextCartItems = cartItems.map((_cartItem, index) => {
      if (
        equal(
          {id: cartItem.id, variants: cartItem.variants},
          {id: _cartItem.id, variants: _cartItem.variants}
          )
      ) {
        return cartItem
      }
      return _cartItem
    })
  }
  else {
    nextCartItems = cartItems.map((_cartItem, index) =>
      _cartItem.id === cartItem.id ? cartItem : _cartItem
    )
  }
  dispatch(updateCartItems(nextCartItems))
}

export const removeCartItem = (cartItem: CartItem) => async (
  dispatch: any,
  getState: any
) => {
  const cartItems = cartItemsSelector(getState());
  const nextCartItems = cartItems.filter((_cartItem) => {
    if (!cartItem.variants || !cartItem.variants.length) {
      return cartItem.id !== _cartItem.id
    }
    else {
      if (!_cartItem.variants || !_cartItem.variants.length) {
        return true
      }
      else {
        const stringifiedA = JSON.stringify([...cartItem.variants].sort((a, b) => a.type.localeCompare(b.type)))
        const stringifiedB = JSON.stringify([..._cartItem.variants].sort((a, b) => a.type.localeCompare(b.type)))
        return !(stringifiedA === stringifiedB && cartItem.id === _cartItem.id)
      }
    }
  });
  dispatch(updateCartItems(nextCartItems));
  GaUtil.removeCart(cartItem)
};

export const addCartItem = (cartItem: CartItem) => (
  dispatch: any,
  getState: any
) => {
  const cartItems = cartItemsSelector(getState());
  dispatch(updateCartItems([...cartItems, cartItem]));
  GaUtil.updateCart([...cartItems, cartItem])
};
