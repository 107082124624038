import Env from "../env/Env";

class OrdersRequest {
  private ordersUrl = `${Env.ApiUrl}/orders`

  public sendEmail:(email:string, orderId:string, template: string) => any = async (email, orderId, template) => {
    try {
      const res = await fetch(`${this.ordersUrl}/send?email=${email}&orderId=${orderId}&template=${template}`);
      if (res.status === 200) {
        return true
      }
      else {
        console.error(res)
        return false
      }
    }
    catch (e) {
      console.error(e)
      return false
    }
  }
}

export default new OrdersRequest()