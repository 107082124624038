/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { cx } from "emotion";
import { useSelector } from "react-redux";
import { localeSelector } from "../../../store/selectors/configSelector";
import { youMayLikeData } from "./data";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {
  translationGeneralSelector,
  translationProductDetailsSelector
} from "../../../store/selectors/translationSelector";
import Validator from "../../../utils/Validator";
import {ProductId} from "../../../models/Product";
import desktopCes from './images/ces.png'
import Ranking from "./Ranking";

type Props = {
  id: ProductId;
};

type State = {
  index: number;
  slidesToShow: number;
  slidesToScroll: number;
  slides: number;
};

const YouMayLike: FC<Props> = ({id}) => {
  const ref = useRef<any>(null);
  const translationGeneral = useSelector(translationGeneralSelector)
  const [state, setState] = useState<State>({
    index: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    slides: 1
  });
  const from = 'December 15, 2020 15:00:00 UTC';
  const locale = useSelector(localeSelector);
  const translationProductDetails = useSelector(translationProductDetailsSelector)
  const pages = Math.ceil(state.slides / state.slidesToScroll);
  const page = Math.floor(state.index / state.slidesToShow) + 1;
  let head = Validator.tryGetValue(translationGeneral, 'youMayLike', locale.languageCode)
  if (!head) {
    head = 'You may like'
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 0,
          // infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0
        }
      },
      {
        breakpoint: 440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0
        }
      }
    ],
    beforeChange: (currentSlide: number, nextSlide: number) => {
      if (state.index !== nextSlide) {
        setState(state => ({ ...state, index: nextSlide }));
      }
    },
    onInit: () => {
      if (!!ref && !!ref.current) {
        console.log(ref.current.props);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeEvent);
    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, []);

  useEffect(() => {
    initialize();
  }, [ref.current]);

  const initialize = () => {
    if (ref.current) {
      const {
        state: { breakpoint },
        props
      } = ref.current;
      const { children } = props;
      if (breakpoint === null) {
        const { slidesToShow, slidesToScroll } = props;
        setState(state => ({
          ...state,
          slidesToShow,
          slidesToScroll,
          slides: children.length
        }));
      } else {
        const responsive = props.responsive.filter(
          (item: any) => item.breakpoint === breakpoint
        )[0];
        const { slidesToShow, slidesToScroll } = responsive.settings;
        setState(state => ({
          ...state,
          slidesToShow,
          slidesToScroll,
          slides: children.length
        }));
      }
      ref.current.slickGoTo(0);
    }
  };

  const handleResizeEvent = () => initialize();

  const handlePrev = (e: any) => {
    e.stopPropagation()
    !!ref.current && ref.current.slickPrev();
  }

  const handleNext = (e: any) => {
    e.stopPropagation()
    !!ref.current && ref.current.slickNext();
  }

  return (
    <div
      css={css`& *, &:focus {outline: none;}`}
      className={"w-full mx-auto text-white bg-black"}
    >
      <div
        css={css`@media(max-width: 639px) {padding-top: 50px}`}
        className={'max-w-screen-xl mx-auto pt-28 md:pt-32 xl:pt-40 w-full flex flex-col items-center'}>
        <span className={'text-3xl lg:text-4xl font-extrabold'}>{head}</span>
        <div className={'w-full px-8 xl:px-20 pt-8 sm:pt-14 pb-14 sm:pb-20 flex items-center'}>
          <Slider
            ref={ref}
            css={css`
              & .slick-arrow {visibility: hidden; display: none !important;}
              & .slick-track {min-height: 16rem;}
              & .slick-slide > div {height: 100%}
            `}
            {...settings}
            className={'w-full'}
          >
            {
              youMayLikeData
                .sort((a: any, b: any) => (a.ranking > b.ranking ? 1 : -1))
                .map((value, index) => {
                  const name = Validator.tryGetValue(translationProductDetails, value.productId, `name_${locale.languageCode}`)
                  const cesable = ['tactsuit-x40'].includes(value.productId)

                  return (
                    <div key={index} className={"h-full flex items-center justify-center px-4 xl:px-10"}>
                      <span
                        className={cx("text-white font-bold text-2xl",
                          {"invisible": value.ranking !== 1}
                        )}
                      >
                        Best
                      </span>
                      <Link to={value.to}>
                        <div
                          css={css`background-color: #1e1e21;`}
                          className={'flex h-full text-center relative flex-col items-center w-full'}
                        >
                          <div className={'flex-grow relative'}>
                            <Ranking className={cx({'hidden': value.ranking > 3})} ranking={value.ranking} />
                            {
                              cesable &&
                              (Date.now() > Date.parse(from)) &&
                              <img src={desktopCes} className={'w-12 z-10 absolute top-0 right-0 mt-2 mr-2'} />}
                            <img className={"object-contain w-full"} src={value.src}/>
                          </div>
                          <span className={"px-1.5 leading-tight whitespace-pre-wrap h-10 font-black mb-4"}>{name}</span>
                        </div>
                      </Link>
                    </div>
                  )
                })
            }
          </Slider>
        </div>
        <div className={"w-full mb-28 text-xl justify-center items-center flex flex-row"}>
          <button className="button mr-2.5" onClick={handlePrev}>
            <FaChevronLeft className={"hover:opacity-75"} />
          </button>
          <span>{page}</span>
          <span className={"px-1"}>/</span>
          <span>{pages}</span>
          <button className="button ml-2.5" onClick={handleNext}>
            <FaChevronRight className={"hover:opacity-75"} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default YouMayLike;
