/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC} from "react";
import {Variant} from "../../../models/Product";
import {useSelector} from "react-redux";
import {localeSelector} from "../../../store/selectors/configSelector";
import {cx} from "emotion";
import {TiWarningOutline} from 'react-icons/ti'

type Props = {
  className?:string,
  id: string,
  variant: Variant
}

const data: {
  [id: string]: {
    [locale: string]: {
      [type: string]: {
        [kind: string]: string;
      };
    };
  };
} = {
  'tactal': {
    en: {
      Size: {
        Standard: "Valve Index*, HTC Vive, Oculus Quest 1*, Quest 2*, Rift CV1*, and Samsung Odyssey",
        Wide: "HTC Vive Pro, Focus, Focus Plus, Pimax VR Series"
      }
    },
    kr: {
      Size: {
        Standard: "Valve Index*, HTC Vive, Oculus Quest 1*, Quest 2*, Rift CV1*, and Samsung Odyssey",
        Wide: "HTC Vive Pro, Focus, Focus Plus, Pimax VR Series"
      }
    },
    cn: {
      Size: {
        Standard: "Valve Index*, HTC Vive, Oculus Quest 1*, Quest 2*, Rift CV1*, and Samsung Odyssey",
        Wide: "HTC Vive Pro, Focus, Focus Plus, Pimax VR Series"
      }
    },
    jp: {
      Size: {
        Standard: "Valve Index*, HTC Vive, Oculus Quest 1*, Quest 2*, Rift CV1*, and Samsung Odyssey",
        Wide: "HTC Vive Pro, Focus, Focus Plus, Pimax VR Series"
      }
    }
  }
};

const warnings:{
  [id: string]: {
    [locale: string]: {
      [type: string]: {
        [kind: string]: string;
      };
    };
  };
} = {
  tactal: {
    en: {
      Size: {
        Standard: "",
        Wide: ""
      }
    },
    kr: {
      Size: {
        Standard: "",
        Wide: ""
      }
    },
    cn: {
      Size: {
        Standard: "Oculus Quest 2 NOT Supported",
        Wide: ""
      }
    },
    jp: {
      Size: {
        Standard: "Oculus Quest 2 NOT Supported",
        Wide: ""
      }
    }
  }
}

const DescribeVariantType:FC<Props> = ({className='', id, variant}) => {
  const locale = useSelector(localeSelector);
  let description = ''
  let warning = ''
  if (id in data) {
    description = data[id][locale.languageCode][variant.type][variant.kind]
  }
  if (id in warnings) {
    warning = warnings[id][locale.languageCode][variant.type][variant.kind]
  }
  return (
    !!description ?
      <div
        css={css`margin-left: 0.875rem;`}
        className={'flex flex-col'}>
        <span className={cx("leading-tight whitespace-pre-wrap")}>
          {description}
        </span>
        {
          !!warning &&
          <div className={'flex items-center'}>
            <TiWarningOutline className={'text-xl text-red-600 font-bold'} />
            <span className={cx("ml-1.5 leading-tight whitespace-pre-wrap font-bold text-red-600")}>{warning}</span>
          </div>
        }
      </div>
      :
      null
  )
}

export default DescribeVariantType