/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import {FC} from "react";
import {cx} from "emotion";
import {useSelector} from "react-redux";
import {translationProductDetailsSelector} from "../../../store/selectors/translationSelector";
import {localeSelector} from "../../../store/selectors/configSelector";
import Validator from "../../../utils/Validator";
import Backorders from "./Backorders";

type Props = {
  className?:string,
  id: string,
}

const summaries: {[key: string]: string} = {
  'tactsuit-x40': 'Wearable haptic vest with\n40 vibration points',
  'tactot': 'Wearable haptic vest with\n40 vibration points',
  'tactsuit-x16': 'Wearable haptic vest with\n16 vibration points',
  'tactot-for-backpack-pc': 'Wearable haptic vest for backpack\nPC with 40 vibration points',
  'tactal': 'Haptic face cushion for VR HMDS\nwith 6 vibration points',
  'tactosy-for-arms': 'Haptic armor for hands with\n6 vibration points for each arm',
  'tactosy-for-hands': 'Haptic device for feet with\n3 vibration points for each hand',
  'tactosy-for-feet': 'Wearable haptic vest for backpack\nPC with 40 vibration points',
  'tactsuit-x-audio-accessories': 'Spring audio cable for wired\nAudio-to-Haptics with console,\nQuest/Quest 2, etc.',
  'mesh-lining-for-tactsuit-x': 'Additional mesh lining for TactSuit X',
  'inner-lining-for-tactosy-for-arms': 'Additional lining for Tactosy for Arms',
}

const Summary:FC<Props> = ({id, className=''}) => {
  const translationProductDetails = useSelector(translationProductDetailsSelector)
  const locale = useSelector(localeSelector);
  let summary = Validator.tryGetValue(translationProductDetails, id, `summary_${locale.languageCode}`)
  if (!summary) {
    summary = summaries[id]
  }
  return (
    <div
      css={css`color: #979797;`}
      className={'flex mt-3.5 flex-col flex-grow'}
    >
      <span
        css={css`min-height: 4rem; line-height: 1.15rem;`}
        className={cx(className, 'font-sm flex')}>{summary}</span>
      <Backorders id={id} className={'flex-grow mt-2'}  />
    </div>
  )
}

export default Summary
