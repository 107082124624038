/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC} from "react";
import {cx} from "emotion";

type Props = {
  className?:string
  shipping:any
}

const ShippingInformation:FC<Props> = ({shipping, className=''}) => {
  return (
    <div className={cx(className, 'w-full bg-black py-12 flex flex-col')}>
      <div
        className={'px-6 flex flex-col sm:flex-row'}>
        <div className={'w-full sm:w-1/4'}>
          <span className={'text-white text-lg font-bold'}>Shipping To</span>
        </div>
        <div
          css={css`
            & * {color: #505050;}`}
          className={'w-full sm:w-3/4 flex flex-col'}
        >
          <span className={'text-white font-semibold text-lg'}>{`${shipping.firstName} ${shipping.lastName}`}</span>
          <span className={'text-white mt-1 font-semibold text-lg'}>{`${shipping.address1} ${shipping.address2}`}</span>
          <span className={'text-white mt-1 font-semibold text-lg'}>{`${shipping.city} ${shipping.state} ${shipping.zip}`}</span>
          <span className={'text-white mt-1 font-semibold text-lg'}>{`${shipping.country}`}</span>
        </div>
      </div>
      <div
        className={'px-6 flex flex-col mt-1 sm:flex-row'}>
        <div className={'w-full sm:w-1/4'}>
          <span className={'text-white text-lg font-bold'}>T. :</span>
        </div>
        <div
          css={css`
            & * {color: #505050;}`}
          className={'w-full sm:w-3/4 flex flex-col'}
        >
          <span className={'text-white font-semibold text-lg'}>{`${shipping.tel}`}</span>
        </div>
      </div>
      <div
        className={'px-6 flex flex-col mt-1 sm:flex-row'}>
        <div className={'w-full sm:w-1/4'}>
          <span className={'text-white text-lg font-bold'}>E-mail :</span>
        </div>
        <div
          css={css`
            & * {color: #505050;}`}
          className={'w-full sm:w-3/4 flex flex-col'}
        >
          <span className={'text-white font-semibold text-lg'}>{`${shipping.email}`}</span>
        </div>
      </div>
    </div>
  )
}

export default ShippingInformation