import {Loading, LoadingActionType} from "../reducers/loading";

export const updateLoading = (loading: Loading) => (dispatch: any, getState: any) => {
  dispatch({ type: LoadingActionType.UpdateLoading, payload: loading });
};

export const startLoading = (dispatch: any, getState: any) => {
  const loading = getState().loading
  dispatch(updateLoading({...loading, on:true}))
}

export const stopLoading = (dispatch: any, getState: any) => {
  const loading = getState().loading
  dispatch(updateLoading({...loading, on:false}))
}