import { CartItem } from "../store/reducers/cart";
import {Category, Variant} from "../models/Product";
import equal from "fast-deep-equal";
import StringUtils from "./StringUtils";

class CartUtil {
  static describeVariants = (variants: Variant[]) => {
    const description = variants.reduce((prev, current, index) => {
      const {type, kind} = current;
      const tail = variants.length - 1 === index;
      prev += `${StringUtils.toCapitalize(type)}: ${StringUtils.toCapitalize(kind)}`
      if (!tail) {
        prev += ','
      }
      return prev
    }, '')

    return description
  }

  static tryGetCartItem = (cartItems: CartItem[], candidate: CartItem) => {
    if (!cartItems || !cartItems.length) {
      return undefined
    }
    const filteredById = cartItems.filter((cartItem: CartItem) => cartItem.id === candidate.id)
    if (!!candidate.variants && !!candidate.variants.length) {
      const sorted =
        [...candidate.variants]
          .sort((a, b) =>
            (a.type).localeCompare(b.type))
      const stringified = JSON.stringify(sorted)
      const filtered = filteredById.filter((filtered) => {
        const _sorted = [...filtered.variants]
          .sort((a: any, b: any) =>
            (a.type).localeCompare(b.type))
        const _stringified = JSON.stringify(_sorted)
        return equal(_stringified, stringified)
      })
      return filtered[0]

    }
    else {
      return filteredById[0]
    }
  }

  static accessoriesOnly = (cartItems: CartItem[]) => {
    if (!cartItems.length) {
      return false
    }
    const notAccessories = cartItems.filter((cartItem) =>
      !!cartItem.qty &&
      cartItem.category !== 'accessory' &&
      cartItem.checked
    )
    return !notAccessories.length
  }

  static isBulkOffer = (cartItems: any) =>
    !cartItems.length ? false
      :
      cartItems
        .filter((cartItem: any) =>
          cartItem.qty > 9 &&
          cartItem.category !== Category.Accessory &&
          cartItem.checked)
        .length > 0;
}

export default CartUtil;
