/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useState} from "react";
import {cx} from "emotion";
import styled from "@emotion/styled";
import Validator from "../../utils/Validator";
import TrackingRequest from "../../firebase/TrackingRequest";
import Portal from "../portal";
import AboutOrderID from "./AboutOrderID";

const Span = styled.span`
font-weight: 500;
`

const Input = (props:any) => {
  const {className, ...rest} = props
  return (
    <input
      {...rest}
      className={cx(
        className,
        'rounded-none mt-1 mb-5 text-white appearance-none w-full py-2 px-3 focus:outline-none',
      )}
      css={css`
        border: solid 1px #505050;
        background-color: #1e1e21;
        &:active, &:focus {
          background: #0f1014;
        }
      `}
    />
  )
}

type Props = {
  className?: string,
  onSuccess:(data: {order: any, tracking: any}) => void
}

const TrackingRequestForm:FC<Props> = ({className='', onSuccess}) => {
  const [state, setState] = useState({
    invoiceNumber: '',
    email: '',
    tried: false,
    message: ''
  })

  const [open, setOpen] = useState({
    aboutOrderID: false
  })

  const handleChange = (e: any) => {
    const name = e.target.name
    const value = e.target.value
    setState((state: any) => ({
      ...state,
      [name]: value.trim()
    }))
  }

  const requestTracking = async (e:any) => {
    let {invoiceNumber, email} = state;
    setState((state:any) => ({
      ...state,
      tried: true,
      message: ''
    }))

    if (
      Validator.isValidInvoice(invoiceNumber) &&
      Validator.isValidEmail(email)
    ) {
      TrackingRequest.get(invoiceNumber, email).then((data:any) => {
        if (!data) {
          setState((state:any) => ({
            ...state,
            message: `Can't find tracking information.\n Please check your invoice number and email address`
          }))
        } else {
          onSuccess(data)
        }
      })
    }
  }

  const handleOpen = (key: string) => (e:any) => {
    setOpen((state: any) => ({
      ...state,
      [key]: true
    }))
  }

  const handleClose = (key: string) => (e:any) => {
    setOpen((state: any) => ({
      ...state,
      [key]: false
    }))
  }

  return (
    <div
      css={css`background-color: #0f1014;`}
      className={cx(
        className,
        'w-full flex items-center justify-center'
      )}
    >
      <form
        css={css`
          background-color: #1e1e21;
          input::placeholder {
            color: #505050;
            opacity: 1;
          }
        `}
        className={'max-w-screen-sm mt-3 mb-24 px-4 sm:px-8 w-full justify-center items-center flex flex-col py-10'}
      >
        <div className={'flex w-full mt-4 flex flex-col'}>
          <div className={'flex justify-between'}>
            <Span className={'text-white text-lg'}>Order ID*</Span>
            <Span
              className={cx(
                state.tried ? 'block' : 'hidden',
                'text-red-700'
              )}
            >
              {Validator.isValidInvoice(state.invoiceNumber) ? '' : 'Invalid Order ID'}
            </Span>
          </div>
          <Input
            defaultValue={state.invoiceNumber}
            name={'invoiceNumber'}
            onChange={handleChange}
            placeholder={'1234567890-US-123'}
          />
        </div>
        <div className={'flex w-full flex flex-col'}>
          <div className={'flex justify-between'}>
            <Span className={'text-white text-lg'}>Email*</Span>
            <Span
              className={cx(
                state.tried ? 'block' : 'hidden',
                'text-red-700'
              )}
            >
              {Validator.isValidEmail(state.email) ? '' : 'Invalid Email'}
            </Span>
          </div>
          <Input
            defaultValue={state.email}
            name={'email'}
            onChange={handleChange}
            placeholder={'bhaptics@example.com'}
          />
        </div>
        <div
          css={css`border: solid 1px #6dd400;`}
          className={'flex w-full hover:opacity-75 mt-5 cursor-pointer items-center justify-center flex'}
          onClick={requestTracking}>
          <span
            css={css`color: #6dd400`}
            className={'font-bold text-center text-lg py-2 leading-tight'}
          >
            Track Order
          </span>
        </div>
        <div className={'flex w-full items-center justify-center'}>
          <span
            onClick={handleOpen('aboutOrderID')}
            css={css`
              text-decoration-color: #898989;
              color: #898989;
            `}
            className={'text-sm cursor-pointer mt-4 hover:opacity-75 text-center underline'}
          >
              I don't know my Order ID
            </span>
        </div>
        <div className={'flex w-full mt-4 h-16 sm:h-12 flex justify-center items-center'}>
          <span className={'text-white text-red-600 text-center whitespace-pre-wrap'}>{state.message}</span>
        </div>
      </form>
      {
        open.aboutOrderID &&
          <Portal>
            <AboutOrderID onClose={handleClose('aboutOrderID')} />
          </Portal>
      }
    </div>
  )
}

export default TrackingRequestForm