import { CartItem } from "../store/reducers/cart";
import Env from "../env/Env";
import ApiUtil from "../utils/ApiUtil";
import {Variant} from "../models/Product";

export interface Order {
  paymentMethod: "Paymentwall" | "Paypal";
  invoiceNumber: string; // ag_external_id for paymentwall
  items: CartItem[];
  shippingAddress: {
    email: string;
    firstName: string;
    lastName: string;
    address1: string;
    address2: string;
    zip: string;
    city: string;
    state: string;
    country: string;
    tel?: string;
  };
  shippingPrice: number;
  details?: any
  discountPrice?: number;
}

interface PaypalParam {
  items: {
    id: string,
    name: string,
    variants?: Variant[]
    price: number,
    qty: number,
    hsCode: string,
    weight: number,
  }[],
  shippingPrice: number,
  shippingAddress: {
    address1: string,
    address2: string,
    zip: string,
    city: string,
    state: string,
    country: string,
    email: string,
    firstName: string,
    lastName: string,
    tel?: string,
  },
  discountPrice: number
}

class CommonPayRequest {
  public capturePaypalOrder:(param: (PaypalParam & {orderId: string})) => Promise<{message: string}>
    = async (param) => {
    try {
      const res = await fetch(`${Env.ApiUrl}/paypal/captureOrder`, {
        method: 'post',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify(param)
      })
      if (res.ok) {
        return {message: ''}
      }
      return await res.json()
    } catch (e) {
      console.error(e)
      return {
        message: [
          `We can't process your payment.`,
          'If errors occur again, please email contact@bhaptics.com about it.'
        ].join('\n')
      }
    }
  }

  public createPaypalOrder:(param: PaypalParam) => Promise<{orderId: string, statusText?: string}>
    = async (param) => {
    try {
      const res = await fetch(`${Env.ApiUrl}/paypal/createOrder`, {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify(param)
      })
      if (!!res) {
        if (!res.ok) {
          return {orderId: '', statusText: res.statusText}
        }
        const data = await res.json()
        return {orderId: data.orderId}
      }
      return {orderId: ''}
    } catch (e) {
      console.error(e)
      return {orderId: ''}
    }
  }
  public addOrder = async (order: Order): Promise<boolean> => {
    if (!order.discountPrice) {
      order.discountPrice = 0
    }
    const orderItems = ApiUtil.getOrderItems(order.items);
    const param = {
      ...order,
      items: orderItems,
    };

    try {
      const res = await fetch(`${Env.ApiUrl}/orders/add`, {
        method: "POST",
        body: JSON.stringify(param),
        headers: {
          "Content-Type": "application/json"
        }
      });
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };
}

export default new CommonPayRequest();
