/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC} from "react";
import {cx} from "emotion";
import SubMenus from "./SubMenus";

type Props = {
  className?:string
}

const menus: {id:string, label: string, href?:string}[] = [
  {
    id: 'tactsuit', label: 'TactSuit',
  },
  {
    id: 'experiences', label: 'Experiences',
  },
  {
    id: 'news', label: 'News', href: '#'
  },
  {
    id: 'support', label: 'Support',
  },
  {
    id: 'shop', label: 'Shop', href: '#'
  },
]

const NavMenus:FC<Props> = ({className=''}) => {
  return (
    <div css={css`color: #ffffff;`} className={cx(className)}>
      <div className={'flex items-center h-full'}>
        {
          menus.map((menu, index) => {
            const linkable = !!menu.href
            return (
              <div
                css={css`
                  &:hover {
                    & > div:first-of-type > span:nth-of-type(2) {display: inline-block}
                    & > a:first-of-type > span:nth-of-type(2) {display: inline-block}
                    & > div:nth-of-type(2) > div:nth-of-type(${index + 1}) {display: flex}
                  }
                `}
                className={'mx-7.5 items-center h-full flex'}
                key={index}
              >
                {
                  linkable ?
                    <a href={menu.href} className={'relative flex items-center h-full'}>
                      <span className={'text-base font-bold'}>{menu.label}</span>
                      <span
                        css={css`background-color: #ffd900;`}
                        className={'absolute hidden bottom-0 left-0 h-0.5 w-full right-0'}
                      />
                    </a>
                    :
                    <div className={'relative flex items-center h-full'}>
                      <span className={'text-base font-bold'}>{menu.label}</span>
                      <span
                        css={css`background-color: #ffd900;`}
                        className={'absolute hidden bottom-0 left-0 h-0.5 w-full right-0'}
                      />
                    </div>
                }
                <SubMenus />
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default NavMenus