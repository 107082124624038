import produce from "immer";
import {Product} from "../../models/Product";

type ProductsAction =
  {type: ProductsActionType.FetchProducts; payload: Product[]};

const initialState: Product[] = [];

export enum ProductsActionType {
  FetchProducts = "products/FetchProducts"
}

export const products = produce(
  (draft: Product[] = initialState, action: ProductsAction) => {
    switch (action.type) {
      case ProductsActionType.FetchProducts: {
        draft = action.payload;
        return draft;
      }
      default:
        return draft;
    }
  }
);
