/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC} from "react";
import {cx} from "emotion";
import styled from "@emotion/styled";
import Validator from "../../utils/Validator";
import CartUtil from "../../utils/CartUtil";

const Span:FC<{className?:string}> = ({className='', children}) =>
  <span className={cx(className, 'flex items-center text-lg font-semibold text-white break-all')}>
    {children}
  </span>

const Th = styled.th`
font-size: 21px;
color: white;
`

type Props = {
  className?:string
  order: any
}

const OrderSummary:FC<Props> = ({className='', order}) => {
  let subtotalQty = 0
  let subtotalPrice = 0
  let shippingPrice = Validator.tryGetValue(order, 'shippingPrice')
  let discountPrice = Validator.tryGetValue(order, 'discountPrice')

  if (!!order && !!order.items) {
    const reduced = order.items.reduce((prev: any, current: any, index:number) => {
      prev.subtotalQty += current.qty;
      prev.subtotalPrice += (current.qty * current.price)
      return prev;
    }, {subtotalQty: 0, subtotalPrice: 0})

    subtotalPrice = reduced.subtotalPrice;
    subtotalQty = reduced.subtotalQty;
  }

  return (
    <div className={cx(className, 'w-full bg-black flex flex-col items-center justify-center')}>
      <table className="table-fixed w-full">
        <thead css={css`background-color: #1e1e21;`}>
        <tr className={'px-6 md:px-10'}>
          <Th className="font-bold w-3/5 md:w-1/3 pl-6 sm:pl-10 py-2 flex justify-start">Item</Th>
          <Th className="font-bold w-1/5 md:w-1/3 px-2 sm:px-4 py-2">
            <span className={'sm:flex hidden justify-center items-center'}>Quantity</span>
            <span className={'flex sm:hidden justify-center items-center'}>Qty</span>
          </Th>
          <Th className="font-bold w-1/5 md:w-1/3 px-2 sm:px-4 py-2">Price</Th>
        </tr>
        </thead>
        <tbody>
        {
          !!order.items && order.items.map((item: any, index: number) => {
            let name = item.name;
            let desc = item.desc;
            let variants = '';
            if (!!item.variants && !!item.variants.length) {
              variants = CartUtil.describeVariants(item.variants)
            }

            return (
              <tr className={'px-6 md:px-10'} key={index}>
                <td className={'pl-6 pt-10 sm:pl-10'}>
                  <Span className={'justify-start'}>{`${name}${!!variants ? `(${variants})` : !!desc ? `${desc}` : ``}`}</Span>
                </td>
                <td className={'pt-10'}>
                  <Span className={'justify-center'}>{item.qty}</Span>
                </td>
                <td className={'pt-10'}>
                  <Span className={'justify-center'}>{`$ ${item.price * item.qty}`}</Span>
                </td>
              </tr>
            )
          })
        }
        </tbody>
      </table>
      <div className={'px-4 flex w-full md:px-6'}>
        <span className={'h-px mt-10 bg-white opacity-50 w-full'} />
      </div>
      <div className={'flex w-full mt-5 text-white'}>
        <div className="font-bold text-lg px-6 md:px-10 w-3/5 md:w-1/3 font-bold py-2 flex items-center justify-start">
          Subtotal
        </div>
        <div className="font-semibold text-lg w-1/5 md:w-1/3 px-2 sm:px-4 py-2 flex items-center justify-center">
          {subtotalQty}
        </div>
        <div className="font-semibold text-lg w-1/5 md:w-1/3 px-2 sm:px-4 py-2 flex items-center justify-center">
          {`$ ${subtotalPrice}`}
        </div>
      </div>
      {
        !!shippingPrice &&
        <div className={'flex lg:hidden w-full mt-5 text-white'}>
          <div className="font-bold text-lg px-6 md:px-10 w-3/5 md:w-1/3 font-bold py-2 flex items-center justify-start">
            Shipping Fee
          </div>
          <div className="w-1/5 md:w-1/3" />
          <div className="font-semibold text-lg w-1/5 md:w-1/3 px-2 sm:px-4 py-2 flex items-center justify-center">
            {`$ ${shippingPrice}`}
          </div>
        </div>
      }
      {
        !!discountPrice &&
        <div className={'flex w-full mt-5 text-red-700'}>
          <div className="font-bold text-lg px-6 md:px-10 w-3/5 md:w-1/3 font-bold py-2 flex items-center justify-start">
            Discount
          </div>
          <div className="w-1/5 md:w-1/3" />
          <div className="font-semibold text-lg w-1/5 md:w-1/3 px-2 sm:px-4 py-2 flex items-center justify-center">
            {`$ ${discountPrice}`}
          </div>
        </div>
      }
      <div className={'flex w-full px-6 mb-6'}>
        <div
          css={css`background-color: #1e1e21;`}
          className={'w-full py-10 px-2 flex flex-wrap justify-around'}
        >
          <div
            className={'flex items-center'}>
            <span className={'font-medium hidden lg:block'} css={css`color: #898989`}>Purchase Total</span>
            <span className={'pl-6 text-lg text-white font-semibold'}>
                {`$ ${subtotalPrice}`}
              </span>
          </div>
          <span
            css={css`color: #898989;`}
            className={'text-lg flex justify-center'}>+</span>
          <div className={'flex items-center justify-around'}>
            <span className={'hidden lg:block'} css={css`color: #898989`}>Shipping Fee</span>
            <span className={'md:pl-6 text-lg text-white font-semibold'}>
              {`$ ${shippingPrice}`}
            </span>
          </div>
          {!!discountPrice && <span className={'text-lg text-red-700'}>-</span>}
          {
            !!discountPrice &&
              <div className={'flex'}>
                <div className={'flex items-center'}>
                  <span className={'text-red-700 hidden lg:block'}>Discount</span>
                  <span className={'md:pl-6 text-lg text-red-700 font-semibold'}>
                    {`$ ${discountPrice}`}
                  </span>
                </div>
              </div>
          }
          <span css={css`color: #898989`} className={'block lg:hidden'}>=</span>
          <div className={'flex items-center'}>
            <span css={css`color: #898989`} className={'hidden lg:block font-semibold text-lg whitespace-pre-wrap mr-8'}>=    Total</span>
            <span className={'text-lg text-white font-semibold'}>
              {`$ ${!!discountPrice ? subtotalPrice + shippingPrice - discountPrice : subtotalPrice + shippingPrice}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderSummary