import React, {FC} from "react";
import {Address} from "../../models/Address";

type Props = {
  address:Address
}

const SummarizeAddress:FC<Props> = ({address, children}) => {
  return (
    <div className={"p-4"}>
      <div className={"text-gray-800"}>{address.address1}</div>
      <div className={"text-gray-800"}>{address.address2}</div>
      <div className={"text-gray-800"}>
        {`${address.city}, ${address.state} ${address.zip} `}
      </div>
      <div className={"text-gray-800"}>{`${address.country.name}`}</div>
      {children}
    </div>
  )
}

export default SummarizeAddress