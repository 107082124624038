/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, Fragment} from "react";
import {cx} from "emotion";
import {useSelector} from "react-redux";
import {localeSelector} from "../../../../store/selectors/configSelector";

const A: FC<{ href: string }> = ({ href, children }) => (
  <a className={"underline hover:opacity-75"} target={"_blank"} href={href}>
    {children}
  </a>
);

const ValveIndex:FC<{}> = () =>
  <A href={"https://vrcover.com/product/valve-index-interface-foam-replacement-basic-set/"}>
    Valve Index
  </A>

const OculusQuest:FC<{}> = () =>
  <A href={"https://vrcover.com/product/oculus-quest-foam-and-interface-basic-set/"}>
    Oculus Quest 1
  </A>

const OculusQuest2:FC<{}> = () =>
  <A href={"https://vrcover.com/item/facial-interface-foam-replacement-set-for-oculus-quest-2/"}>
    Quest 2
  </A>

const RiftCV1:FC<{}> = () =>
  <A href={"https://vrcover.com/product/oculus-rift-facial-interface-foam-replacement-basic-set/"}>
    Rift CV1
  </A>

const VRCover:FC<{}> = () =>
  <A href={"https://vrcover.com"}>VR Cover</A>

type Props = {
  className?:string
}

const TactalStandardSizeFacialInterface:FC<Props> = ({className=''}) => {
  const locale = useSelector(localeSelector)

  return (
    <span
      css={css`color: red;`}
      className={cx(className, "p-4 bg-black rounded-sm block w-full leading-tight")}
    >
      {
        locale.languageCode === 'jp' ?
          <Fragment>
            *<ValveIndex/>、<OculusQuest/>、<OculusQuest2 />, <RiftCV1/>には、「<VRCover/>」のHMD用保護カバーが必要です。
          </Fragment> :
          locale.languageCode === 'cn' ?
            <Fragment>
              *<ValveIndex/>、<OculusQuest/>、<OculusQuest2 />, <RiftCV1/>需要《<VRCover/>》的HMD用护垫。
            </Fragment>
            :
            <Fragment>
              *Facial Interface from <VRCover/>{" "}is required for{" "}<ValveIndex />,{" "}<OculusQuest />,{" "}<OculusQuest2 />,{" "}and{" "}<RiftCV1 />.
            </Fragment>
      }
    </span>
  )
}

export default TactalStandardSizeFacialInterface