/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC, useState} from "react";
import {cx} from "emotion";
import {IoIosSend} from "react-icons/io";
import email from './images/img-footer-email.svg'
import Validator from "../../utils/Validator";
import InputEmail from "./InputEmail";
import SubscribeRequest from "../../firebase/SubscribeRequest";

const ConfirmButton:FC<{className?:string, onClick:(e:any) => void, stage: number, on: boolean}> =
  ({onClick, className='', stage, on}) => {
  return (
    <div
      css={css`
        width: 7rem;
        background-color: ${!on ? '#5a5a5a' : '#ffd900'};
        color: ${!on ? '#ffffff' : '#222222'};
        font-size: 13px;
        @media(max-width: 1023px) {
          font-size: 12px;
        }
      `}
      onClick={onClick}
      className={cx(
        className,
        !on ? 'pointer-events-none' : 'cursor-pointer',
        {'animate-pulse': stage !== 0},
        'relative items-center flex px-1 py-2 h-full overflow-hidden justify-center',
      )}
    >
      <div
        css={css`transform: translateY(${stage === 1 ? '-2.5rem' : stage === 2 ? '-5rem' : '0'});`}
        className={'transition duration-500 delay-500 ease-in-out absolute inset-0 subscribe'}
      >
        <span className={cx('h-full flex items-center justify-center')}>SUBSCRIBE</span>
        <span className={cx('flex items-center justify-center h-full')}><IoIosSend className={'font-xl'}/> SENDING...</span>
        <span className={cx('flex items-center justify-center h-full')}>Thanks !</span>
      </div>
    </div>
  )
}

type Props = {
  className?:string
}

const Newsletter:FC<Props> = ({className=''}) => {
  const [state, setState] = useState<{
    email: string,
    isValidEmail: boolean,
    subscribeStage: number,
  }>({
    email: '',
    isValidEmail: false,
    subscribeStage: 0,
  })

  const updateEmail = (email: any) => {
    setState(state => ({
      ...state,
      email: email.trim(),
      isValidEmail: email && Validator.isValidEmail(email.trim())
    }))
  }

  const handleSubmit = async (e:any) => {
    e.stopPropagation();
    if (state.isValidEmail) {
      try {
        setState(state => ({...state, subscribeStage: 1}))
        const res = await SubscribeRequest.subscribe(state.email);
        if (res.status === 200) {
          setTimeout(() => {
            setState((state: any) => {
              setTimeout(() => {
                setState((state: any) => ({...state, email: '', isValidEmail: false, subscribeStage: 0}));
              }, 1000)
              return {...state, subscribeStage: 2}
            })
          }, 1000)
        }
      } catch (e) {
        setState((state: any) => ({...state, email: '', isValidEmail: false, subscribeStage: 0}));
      }
    }
  }

  return (
    <div
      css={css`@media(max-width: 767px) {align-items: center;}`}
      className={cx(className, 'flex flex-col max-w-screen-xs w-full')}>
      <span
        css={css`@media(max-width: 767px) {margin-top: 2rem}`}
        className={'font-medium md:font-bold text-sm'}>Newsletter</span>
      <span className={'mt-2.5 text-xs md:text-sm opacity-60 md:opacity-40'}>
        Don’t Miss Out on Our Latest Updates!
      </span>
      <div
        css={css`@media(max-width: 767px) {width: 100%}`}
        className={'h-10 mt-4 md:mt-3.5 flex items-center'}>
        <div css={css`background-color: #222222;`} className={'flex-grow h-full flex px-2 py-2'}>
          <img src={email} />
          <InputEmail
            css={css`
              background-color: #222222; 
              &:active, &:focus {opacity: 1}
              font-size: 13px;
              @media(max-width: 1023px) {
                font-size: 12px;
              }
            `}
            className={'text-white opacity-40'}
            value={state.email}
            onChange={updateEmail} />
        </div>
        <ConfirmButton on={state.isValidEmail} onClick={handleSubmit} stage={state.subscribeStage} />
      </div>
    </div>
  )
}

export default Newsletter