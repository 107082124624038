/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC, useState} from "react";
import {cx} from "emotion";
import desktopCi from './images/ci.png'
import mobileCi from './images/mobileCi.png'
import {FiMenu} from "react-icons/fi";
import NavMenus from "./NavMenus";
import TrackingIcon from "./TrackingIcon";
import CartIcon from "./CartIcon";
import MobileNavbar from "./MobileNavbar";
import Portal from "../portal";

type Props = {
  className?:string
}

const MainNavbar:FC<Props> = ({className}) => {
  const [open, setOpen] = useState(false)

  const toggleOpen = (e:any) => {
    e.stopPropagation()
    setOpen(!open)
  }

  return (
    <div className={cx(className, 'w-full mx-auto')}>
      {
        open &&
        <Portal
          fixed={true}
          style={""}
          className={cx('z-20 fixed bg-white inset-0 overflow-y-auto')}
        >
          <MobileNavbar onClick={toggleOpen} />
        </Portal>}
      <div className={'mx-auto h-full relative w-full'}>
        <div
          css={css``}
          className={'mx-auto h-full flex max-w-screen-xl justify-between w-full'}>
          <a className={'flex items-center ml-4'} href={"https://www.bhaptics.com/"}>
            <img className={"hidden lg:block object-contain"} src={desktopCi}/>
            <img className={"block lg:hidden object-contain"} src={mobileCi}/>
          </a>
          <NavMenus className={'hidden lg:flex flex-grow h-full items-center justify-center'} />
          <div css={css`width: 9.625rem`} className={'flex justify-end items-center mr-4'}>
            <CartIcon className={'mr-2'} />
            <TrackingIcon className={'mr-2'} />
            <FiMenu
              onClick={toggleOpen}
              className={'w-7 h-7 lg:hidden cursor-pointer inline-block opacity-50 hover:opacity-100 text-white'} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainNavbar