import Loading from "components/loading";
import React, { useEffect } from "react";

function NewShopTrackingPage() {
  const query = window.location.search;

  useEffect(() => {
    window.location.href = `https://www.bhaptics.com/tracking${query}`;
  }, []);

  return <Loading />;
}

export default NewShopTrackingPage;
