/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC} from "react";
import {useSelector} from "react-redux";
import {localeSelector} from "../../store/selectors/configSelector";
import {translationGeneralSelector} from "../../store/selectors/translationSelector";
import Validator from "../../utils/Validator";

type Props = {
  className?:string
}

const Header:FC<Props> = ({className=''}) => {
  const locale = useSelector(localeSelector)
  const translationGeneral = useSelector(translationGeneralSelector)

  return (
    <div className={"text-2xl p-2 lg:p-4 font-bold text-grey-dark leading-tight"}>
      Bhaptics - {Validator.tryGetValue(translationGeneral, 'experienceVirtualRealityNotVisualReality', locale.languageCode)}
    </div>
  )
}

export default Header