const env = process.env.REACT_APP_CONF;

const defaultConf = {
  Cookie: {
    Domain: "bhaptics.com"
  },
  // test [paypalId: sb-43bpwe3791815@personal.example.com, paypalPw: wy&lR7Tv]
  PayPalClientId: "AVZPazqedysOrR_dEpCcuCdXkXQmURgF7wsDgpEN4ag1awaCeEWBtQ51uaPFGvd1kusWUg7Pwz96CgOj",
  CartPrefix: "_bhaptics_shop_cart__v1__",
  ApiUrl: "https://us-central1-bhaptics-apis-dev.cloudfunctions.net",
  SuccessUrl: "https://dev-shop.bhaptics.com/",
  FirebaseConf: {
    apiKey: "AIzaSyCCUkoVyhhyAAd8S5vu_01rUfKOsUWpKLM",
    authDomain: "bhaptics-shop-dev.firebaseapp.com",
    databaseURL: "https://bhaptics-shop-dev.firebaseio.com",
    projectId: "bhaptics-shop-dev",
    storageBucket: "bhaptics-shop-dev.appspot.com",
    messagingSenderId: "626875987223",
    appId: "1:626875987223:web:b14f797326013b6c3db352",
    measurementId: "G-5QP9QCTD42"
  }
};

const localConf = {
  Cookie: {
    Domain: "bhaptics.com"
  },
  // test [paypalId: sb-43bpwe3791815@personal.example.com, paypalPw: wy&lR7Tv]
  PayPalClientId: "AVZPazqedysOrR_dEpCcuCdXkXQmURgF7wsDgpEN4ag1awaCeEWBtQ51uaPFGvd1kusWUg7Pwz96CgOj",
  CartPrefix: "_bhaptics_shop_cart__v1__",
  ApiUrl: "http://localhost:5000/bhaptics-apis-dev/us-central1",
  SuccessUrl: "https://dev-shop.bhaptics.com",
  FirebaseConf: {
    apiKey: "AIzaSyCCUkoVyhhyAAd8S5vu_01rUfKOsUWpKLM",
    authDomain: "bhaptics-shop-dev.firebaseapp.com",
    databaseURL: "https://bhaptics-shop-dev.firebaseio.com",
    projectId: "bhaptics-shop-dev",
    storageBucket: "bhaptics-shop-dev.appspot.com",
    messagingSenderId: "626875987223",
    appId: "1:626875987223:web:b14f797326013b6c3db352",
    measurementId: "G-5QP9QCTD42"
  }
};

const prodConf = {
  Cookie: {
    Domain: "bhaptics.com"
  },
  // real
  PayPalClientId: "AQKTr8_Nbn90OErzVIVj0uKJ7UaowItC6Lx3nxmu4Lhb609cIMRNREXImdQuZmNG_RqTuGjQZRAisdWf",
  CartPrefix: "_bhaptics_shop_cart__v1__",
  ApiUrl: "https://us-central1-bhaptics-apis.cloudfunctions.net",
  SuccessUrl: "https://shop.bhaptics.com",
  FirebaseConf: {
    apiKey: "AIzaSyDtDM0l8GYjS8YWdxleGYIia1tYcmZMRIg",
    authDomain: "bhaptics-shop-94ba2.firebaseapp.com",
    databaseURL: "https://bhaptics-shop-94ba2.firebaseio.com",
    projectId: "bhaptics-shop-94ba2",
    storageBucket: "bhaptics-shop-94ba2.appspot.com",
    messagingSenderId: "871097648006",
    appId: "1:871097648006:web:dde896f4c7c694216be207",
    measurementId: "G-Q9LYMKN7GG"
  }
};

const conf =
  env === "prod" ? prodConf : env === "local" ? localConf : defaultConf;

if (env !== "prod") {
  console.log(env, conf);
}

export default class Env {
  public static PayPalClientId = conf.PayPalClientId;
  public static Cookie = conf.Cookie;
  public static ApiUrl = conf.ApiUrl;
  public static CartPrefix = conf.CartPrefix;
  public static SuccessUrl = conf.SuccessUrl;
  public static FirebaseConf = conf.FirebaseConf;
}
