/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC, useState} from "react";
import {cx} from "emotion";
import FillAddress from "./FillAddress";
import AcceptTerms from "./AcceptTerms";
import DoPayment from "./DoPayment";
import {CartState} from "../../../store/reducers/cart";
import {useDispatch, useSelector} from "react-redux";
import {subscriptionSelector} from "../../../store/selectors/subscriptionSelector";
import SubscribeRequest from "../../../firebase/SubscribeRequest";
import OnPaymentSuccess from "../../checkout/OnPaymentSuccess";
import {updateShippingPrice} from "../../../store/actions/shippingAction";
import {routerSelector} from "../../../store/selectors/routerSelector";
import {shippingSelector} from "../../../store/selectors/shippingSelector";
import {recipientSelector} from "../../../store/selectors/recipientSelector";
import Parser from "../../../utils/Parser";
import Validator from "../../../utils/Validator";

type Props = {
  className?:string,
  onUpdateCart: (cart:CartState) => void,
  step: number,
  onUpdateStep:(step:number) => void
}

const ProcessCheckout:FC<Props> = ({onUpdateStep, step, className='', onUpdateCart}) => {
  const dispatch = useDispatch()
  const router = useSelector(routerSelector)
  const subscription = useSelector(subscriptionSelector)
  const recipient = useSelector(recipientSelector)
  const onUpdate = (step: number) => (e:any) => {
    if (step === 0) {
      const countryName = recipient.address.country.name
      if (!countryName) {
        console.log('no country name found')
        dispatch(updateShippingPrice(0))
      }
    }
    onUpdateStep(step)
  }

  const onComplete = (callback: any) => {
    onUpdateCart({items: []})
    if (subscription.active) {
      processSubscribe().then((res: any) => {
        !!callback && callback()
        onUpdateStep(4);
      });
    } else {
      !!callback && callback()
      onUpdateStep(4);
    }
  }

  const processSubscribe = async () => {
    try {
      return await SubscribeRequest.subscribe(subscription.email);
    } catch (e) {
      console.log(e);
    }
  };

  const redirect = () => {
    const url = router.location.pathname === '/checkout' ? 'https://shop.bhaptics.com/' : 'https://www.bhaptics.com/'
    window.open(url, '_self')
  }

  return (
    <div className={cx(className)}>
      <div className={'flex flex-col'}>
        <div className={'pb-2'}>
          <FillAddress
            onUpdate={onUpdate}
            active={step === 0}
            editable={step > 0}
            className={'flex bg-white px-1 lg:px-4 py-2 flex-col border rounded'} />
        </div>
        <div className={'pb-2'}>
          <AcceptTerms
            onUpdate={onUpdate}
            active={step === 1}
            editable={step > 1}
            className={'flex bg-white px-1 lg:px-4 py-2 flex-col border rounded'} />
        </div>
        <div className={'pb-2'}>
          <DoPayment
            onComplete={onComplete}
            active={step === 2}
            className={'flex bg-white px-1 lg:px-4 py-2 flex-col border rounded'} />
        </div>
        {
          step === 4 &&
          <OnPaymentSuccess
            redirectMessage={'Will redirect to the main page soon'}
            onClose={redirect} />
        }
      </div>
    </div>
  )
}

export default ProcessCheckout