import {Personal} from "../interfaces/personal";
import {Address} from "../models/Address";

export default class Validator {
  public static isValidEmail = (email: string): boolean => {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  private static _getValues:(object:object) => string[] = (object:object) => {
    const values = Object.values(object)
    if (Array.isArray(values)) {
      const _values:any = values.reduce((prev, current, index) => {
        if (typeof current === 'object') {
          return [...prev, ...Validator._getValues(current)]
        }
        else {
          return [...prev, current]
        }
      }, [])
      return _values
    }
    else {
      console.log('todo')
      return []
    }
  }

  static getAllValues: (obj: { [key: string]: any }) => string[] = (obj) => {
    let values: string[] = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === "object") {
          values = values.concat(Validator.getAllValues(obj[key]));
        } else {
          values.push(obj[key]);
        }
      }
    }
    return values;
  };

  public static flatten:(object:any) => string[] = (object:any) => {
    if (typeof object === 'object') {
      return Validator._getValues(object)
    }
    else {
      console.log('todo')
      return []
    }

  }

  public static isAlphanumeric:(candidate:string) => boolean = (candidate:string) => {
    const regex = /^[a-z0-9 !@#$%^&*()\-_=+:;.`'",\t\|\\/?<>~[\]{}]+$/i
    return regex.test(candidate)
  }

  private static checkPrevInvoice:(invoice:string) => boolean = (invoice) => {
    const pure = invoice.trim().split('-').join('')
    const ymd = pure.slice(0, 8)
    const countryCode = pure.slice(8, 10)
    const last = pure.slice(10)
    const numberOnly = /^\d+$/;
    const alphabetOnly = /^[a-z]+$/i
    return numberOnly.test(ymd) && numberOnly.test(last) && alphabetOnly.test(countryCode) && last.length === 3
  }

  private static checkCurrentInvoice:(invoice:string) => boolean = (invoice) => {
    const pure = invoice.trim().split('-').join('')
    const ymd = pure.slice(0, 8)
    const time = pure.slice(8, 12)
    const countryCode = pure.slice(12, 14)
    const last = pure.slice(14)
    const numberOnly = /^\d+$/;
    const alphabetOnly = /^[a-z]+$/i
    return numberOnly.test(ymd) && numberOnly.test(time) && alphabetOnly.test(countryCode) && last.length === 3
  }

  public static isValidInvoice = (invoice: string):boolean => {
    /**
     * prev: 20201225-US-123
     * current: 20201225-1234-US-12X
     * */
    const length = invoice.length
    let result:any;
    if (length === 20) {
      result = Validator.checkCurrentInvoice(invoice)
    }
    else {
      result = Validator.checkPrevInvoice(invoice)
    }
    return result;
  }

  public static isValidPersonal = (personal: Personal):boolean => {
    const isValidEmail = Validator.isValidEmail(personal.email)
    const hasFirstName = Validator.hasFilled(personal.firstName)
    const hasLastName = Validator.hasFilled(personal.lastName)
    const hasPhone = 'tel' in personal && Validator.hasFilled(personal.tel!)

    return isValidEmail && hasFirstName && hasLastName && hasPhone
  }

  public static isValidAddress = (address: Address):boolean => {
    const hasAddress1 = Validator.hasFilled(address.address1)
    const hasZip = Validator.hasFilled(address.zip)
    const hasCity = Validator.hasFilled(address.city)
    const hasCountry = Validator.hasFilled(address.country.name)

    return hasAddress1 && hasZip && hasCity && hasCountry
  }

  public static hasFilled = (stringData: string):boolean => !!stringData.length

  public static tryGetValue = (source:any={}, ...keys: any[]):any => {
    const [first, ...remaining] = keys;
    if (!source[first] as any) {
      return null
    } else {
      if (!!remaining.length) {
        return Validator.tryGetValue(source[first], ...remaining)
      }
      else {
        return source[first]
      }
    }
  }
}
