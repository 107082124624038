/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {cartItemsSelector} from "../store/selectors/cartSelector";
import { updateCartItems } from "../store/actions/cartAction";
import GaUtil from "../utils/GaUtil";
import {CartState} from "../store/reducers/cart";
import useScript from "../utils/useScript";
import Env from "../env/Env";
import Header from "../components/checkout/Header";
import ProcessCheckout from "../components/checkout/processCheckout";
import SummarizeCart from "../components/checkout/summarizeCart";
import {updateShippingPrice} from "../store/actions/shippingAction";
import {recipientSelector} from "../store/selectors/recipientSelector";
import Shipping from "../firebase/Shipping";

type Props = {};

const CheckoutPage: FC<Props> = () => {
  const scriptUrl = `https://www.paypal.com/sdk/js?client-id=${Env.PayPalClientId}`
  const [step, setStep] = useState(0)
  useScript(scriptUrl).then()
  const dispatch = useDispatch();
  const cartItems = useSelector(cartItemsSelector);
  const recipient = useSelector(recipientSelector)
  const recipientRef = useRef(recipient)
  const cartItemsRef = useRef(cartItems)

  useEffect(() => {
    recipientRef.current = recipient
    cartItemsRef.current = cartItems
  }, [recipient, cartItems])

  useEffect(() => {
    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)
    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
  }, [])

  const handleOffline = (e:any) => {
    console.log('offline')
    dispatch(updateShippingPrice(-1))
  }

  const handleOnline = (e:any) => {
    console.log('online')
    const country_iso_2 = recipientRef.current.address.country.iso_2;
    const cartItems = cartItemsRef.current.filter((cartItem) => cartItem.checked)
    if (cartItems.length) {
      Shipping.getPrice({country_iso_2, cartItems})
        .then((shippingPrice) => {
          dispatch(updateShippingPrice(shippingPrice))
        })
    }
  }

  useEffect(() => {
    GaUtil.log('begin_checkout', {});
  }, [])

  const updateCart = (key: string) => (cart: CartState) => {
    if (key === 'remove') {
      const unCheckedItems = cartItems.filter(cartItem => !cartItem.checked);
      dispatch(updateCartItems(unCheckedItems));
    }
    else if (key === 'update') {
      const unCheckedItems = cartItems.filter(cartItem => !cartItem.checked)
      dispatch(updateCartItems([...cart.items, ...unCheckedItems]))
    }
  }

  return (
    <div
      css={css`background-color: #f3f3f3;`}
      className={"w-full flex-grow bg-gray-200"}
    >
      <div className={'max-w-screen-xl mx-auto w-full flex flex-col'}>
        <Header />
        <div className={'flex mb-2 px-1 lg:px-2 flex-wrap'}>
          <ProcessCheckout
            step={step}
            onUpdateStep={(step) => setStep(step)}
            onUpdateCart={updateCart('remove')} className={'w-full lg:w-1/2 px-1 lg:px-2'} />
          <SummarizeCart
            step={step}
            onUpdateCart={updateCart('update')} className={'w-full lg:w-1/2 px-1 lg:px-2'} />
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
