import styled from "@emotion/styled";
import React from "react";
import { cx } from "emotion";

export const ContinueButton = styled.div`
  cursor: pointer;
  background-color: #222;
  color: #fff;
  border-radius: 3px;
  text-align: center;
`;

export const ErrorBox: React.FC<{ className?: string }> = ({
  children,
  className = ""
}) => (
  <div className={cx(className, "bg-red-600 text-white text-sm px-4 py-1")}>
    {children}
  </div>
);
