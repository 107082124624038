/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";
import {useDispatch, useSelector} from "react-redux";
import {notificationSelector} from "../../store/selectors/notificationSelector";
import {updateNotification} from "../../store/actions/notificationAction";
import Notify from "../shop/productCard/Notify";

type Props = {};

const Notification: FC<Props> = () => {
  const dispatch = useDispatch()
  const notification = useSelector(notificationSelector)

  const handleNotify = (actionType: string) => (e:any) => {
    dispatch(updateNotification({type: notification.type, on:false}))
    if (actionType === 'cancel') {}
    else if (actionType === 'contact') {
      window.open("mailto:contact@bhaptics.com", "_top");
    }
  }

  return (
    notification.on ?
      <Notify type={notification.type} onNotify={handleNotify} />
      :
      null
  )
};

export default Notification;
