/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC, Fragment } from "react";
import MainNavbar from "./MainNavbar";

type Props = {};

const Navbar: FC<Props> = ({}) => {
  return (
    <Fragment>
      <MainNavbar
        css={css`background-color: #0f1014;`}
        className={'sticky top-0 w-full z-20 h-15 lg:h-20'}
      />
    </Fragment>
  );
};

export default Navbar