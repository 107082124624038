/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC} from "react";
import {cx} from "emotion";

type Props = {
  width: string,
  className?: string,
}

const Line:FC<Props> = ({width, className=''}) =>
  <div
    className={cx(className)}
    css={css`
      width: 100%;
      max-width: calc(${width});
      height: 3px;
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0), #ffffff 52%, rgba(0, 0, 0, 0));
    `}
  />;

export default Line