import {Variant} from "../models/Product";

class Parser {
  static getUrlParams = (window: any) => new URLSearchParams(window.location.search);

  static getOrderId = (window: any) => {
    const urlParams = Parser.getUrlParams(window)
    return urlParams.get('orderId')
  }

  static getVariantsTypes = (variants: Variant[]) => {
    const uniques = new Set(Array.from(variants, (variant: Variant) => variant.type))
    return [...uniques.keys()]
  }

  static groupVariantsByTypes = (variants: Variant[]):{[type: string]: any[]} => {
    const parsed:{[type: string]: any[]} = variants.reduce((prev: {[type: string]: any[]}, current, index) => {
      if (current.type in prev) {
        prev[current.type] = [...prev[current.type], current.kind]
      }
      else {
        prev[current.type] = [current.kind]
      }
      return prev;
    }, {})

    return parsed
  }

}

export default Parser