/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC} from "react";
import {useSelector} from "react-redux";
import {translationBatterySelector, translationGeneralSelector} from "../../../store/selectors/translationSelector";
import Validator from "../../../utils/Validator";
import {ProductId} from "../../../models/Product";
import desktopBg from "./images/dekstop/img-battery-info.jpg";
import mobileBg from "./images/mobile/default.jpg";
import desktopTactalBg from "./images/dekstop/img-battery-info-tactal.jpg";
import mobileTactalBg from "./images/mobile/tactalBg.jpg";
import {localeSelector} from "../../../store/selectors/configSelector";
import {cx} from "emotion";

type Props = {
  id: ProductId;
};

const bgs:{[key: string]: string[]} = {
  'tactsuit-x40': [desktopBg, mobileBg],
  'tactsuit-x16': [desktopBg, mobileBg],
  'tactot-for-backpack-pc': [desktopBg, mobileBg],
  'tactal': [desktopTactalBg, mobileTactalBg],
  'tactosy-for-arms': [desktopBg, mobileBg],
  'tactosy-for-hands': [desktopBg, mobileBg],
  'tactosy-for-feet': [desktopBg, mobileBg],
}

const Battery: FC<Props> = ({id}) => {
  const translationBattery = useSelector(translationBatterySelector)
  const data:{[key: string]:any}[] = Validator.tryGetValue(translationBattery, id)
  const locale = useSelector(localeSelector)
  const translationGeneral = useSelector(translationGeneralSelector)
  let head = Validator.tryGetValue(translationGeneral, 'batteryInformation', locale.languageCode)
  if (!head) {
    head = 'Battery Information'
  }

  let footnotes:any = []
  if (!!data) {
    footnotes = data.reduce((prev: any, current, index) => {
      const regex = /^footnote+/i
      const _footnotes =
        Object
          .keys(current)
          .filter((key) => regex.test(key) && !!current[key])
          .map((key) => {return {[key]: current[key]}})
          // .filter((value) => !!value.length)
      if (_footnotes.length > 0) {
        prev = [...prev, _footnotes]
      }
      return prev;
    }, [])
  }

  return !!data ?
    <div className={'bg-black text-white w-full mx-auto'}>
      <div
        css={css`background-color: #18191d;`}
        className={'max-w-screen-xl mx-auto w-full flex items-center justify-center md:flex-row flex-col-reverse'}
      >
        <div className={'flex-1 w-full'}>
          <div className={'flex py-8 lg:py-0 px-5 lg:px-0 items-center lg:justify-center'}>
            <div className={'flex flex-col justify-center'}>
              <span className={'text-3xl lg:text-4xl font-extrabold whitespace-pre-wrap lg:whitespace-no-wrap'}>{head}</span>
              <div className={'flex flex-col py-5 lg:py-8'}>
                {
                  data.map((value, index) => {
                    const last = data.length - 1 === index
                    return (
                      <div
                        className={cx(last ? 'mb-0' : 'mb-5','flex items-center')} key={index}>
                        <span
                          css={css`min-width: 5rem`}
                          className={'text-base md:text-lg w-20 leading-none whitespace-no-wrap font-bold'}>
                          {value[`label_${locale.languageCode}`]}
                        </span>
                        <span
                          css={css`line-height: 1`}
                          className={'ml-4 md:ml-12 text-sm lg:text-base'}>
                          {value[`description_${locale.languageCode}`]}
                        </span>
                      </div>
                    )
                  })
                }
              </div>
              {
                !!footnotes.length &&
                <div className={'flex flex-col lg:max-w-screen-xs'}>
                  {
                    footnotes[0].map((value: {[key: string]: string}, footnoteIndex: number) => {
                      return (
                        <span
                          css={css`color: #979797; line-height: 1`}
                          key={footnoteIndex}
                          className={'text-sm lg:text-base mb-2'}
                        >
                        {value[`footnote_${locale.languageCode}`]}
                      </span>
                      )
                    })
                  }
                </div>
              }
            </div>
          </div>
        </div>
        <div
          css={css`
            height: 34.5rem;
            @media(max-width: 767px) {height: 24rem}
          `}
          className={'flex-1 w-full'}
        >
          <img src={bgs[id][0]} className={'hidden sm:block object-cover w-full h-full'} />
          <img src={bgs[id][1]} className={'block sm:hidden object-cover w-full h-full'} />
        </div>
      </div>
    </div>
    :
    null
};

export default Battery;
