import React, { FC } from "react";
import { useSelector } from "react-redux";
import { routerSelector } from "../store/selectors/routerSelector";
import RouterUtil from "../utils/RouterUtil";
import { productSelector } from "../store/selectors/productsSelector";
import Hardware from "../components/product/hardware";
import Accessory from "../components/product/accessory";

type Props = {};

const ProductPage: FC<Props> = () => {
  const router = useSelector(routerSelector);
  const id = RouterUtil.getProductId(router)
  const product = useSelector(productSelector(id));

  if (!product) {
    return null;
  }

  const category = product.category;

  return (
    category === 'hardware' ?
      <Hardware id={id} product={product} />
      :
      category === 'accessory' ?
        <Accessory id={id} product={product} />
        :
        null
  )
};

export default ProductPage;
