/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC} from "react";
import Validator from "../../../utils/Validator";
import {useSelector} from "react-redux";
import {translationProductDetailsSelector} from "../../../store/selectors/translationSelector";
import {localeSelector} from "../../../store/selectors/configSelector";
import {cx} from "emotion";

type Props = {
  id: string,
  className?:string
}

const ProductName:FC<Props> = ({id, className=''}) => {
  const translationProductDetails = useSelector(translationProductDetailsSelector)
  const locale = useSelector(localeSelector);

  return (
    <span css={css`color: #979797`} className={cx(className, "text-lg block leading-tight font-bold")}>
      {Validator.tryGetValue(translationProductDetails, id, `generalName_${locale.languageCode}`)}
    </span>
  )
}

export default ProductName