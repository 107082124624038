import {Product} from "../models/Product";

export const productsData: Product[] = [
  {
    id: 'tactsuit-x40',
    price: 499,
    name: 'TactSuit X40',
    category: "hardware",
    hsCode: '8543.70-9090',
    weight: 5.5,
    // variants: undefined
  },
  {
    id: 'tactsuit-x16',
    price: 299,
    name: 'TactSuit X16',
    category: "hardware",
    hsCode: '8543.70-9090',
    weight: 4.5,
    // variants: undefined
  },
  {
    id: "tactot-for-backpack-pc",
    name: "tactot for backpack PC",
    category: "hardware",
    hsCode: '8543.70-9090',
    weight: 5.5,
    price: 549,
    // variants: undefined
  },
  {
    id: "tactal",
    name: "Tactal",
    category: "hardware",
    hsCode: '8543.70-9090',
    price: 149,
    weight: 1,
    variants: [
      {type: 'Size', kind: 'Wide'},
      {type: 'Size', kind: 'Standard'}
    ]
  },
  {
    id: "tactosy-for-arms",
    name: "Tactosy for Arms (1 pair)",
    category: "hardware",
    hsCode: '8543.70-9090',
    weight: 1.5,
    price: 249,
    // variants: undefined
  },
  {
    id: "tactosy-for-hands",
    name: "Tactosy for Hands (1 pair)",
    category: "hardware",
    hsCode: '8543.70-9090',
    weight: 2,
    price: 249,
    // variants: undefined
  },
  {
    id: "tactosy-for-feet",
    name: "Tactosy for Feet (1 pair)",
    category: "hardware",
    hsCode: '8543.70-9090',
    weight: 2,
    price: 249,
    // variants: undefined
  },
  {
    id: "tactsuit-x-audio-accessories",
    name: "TactSuit X Audio Accessories",
    category: "accessory",
    hsCode: "6310.90-0000",
    weight: 0,
    price: 10,
    // variants: undefined
  },
  {
    id: "mesh-lining-for-tactsuit-x",
    name: "Mesh Lining for TactSuit X",
    category: "accessory",
    variants: [
      {type: 'TactSuit', kind: 'X40'},
      {type: 'TactSuit', kind: 'X16'}
    ],
    hsCode: "6310.90-0000",
    weight: 0,
    price: 13,
  },
  {
    id: "inner-lining-for-tactosy-for-arms",
    name: "Inner Lining for Tactosy For Arms",
    category: "accessory",
    hsCode: "6310.90-0000",
    weight: 0,
    price: 10,
    // variants: undefined
  }
]
