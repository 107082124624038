/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useEffect, useRef, useState} from "react";
import {FiChevronLeft, FiChevronRight} from 'react-icons/fi'
import Slider from "react-slick";
import {cx} from "emotion";
import {useSelector} from "react-redux";
import {productSelector} from "../../../store/selectors/productsSelector";
import {ProductId} from "../../../models/Product";
import PriceTag from "./PriceTag";
import desktopCes from './images/desktop/ces.png'
import {Link} from "react-router-dom";

const Chevron = (props: any) =>
  props.rtl ?
    <FiChevronRight
      onClick={props.onClick}
      className={cx(props.className, 'w-12 z-10 h-12 text-white opacity-50 hover:opacity-100 cursor-pointer')}
    />
    :
    <FiChevronLeft
      onClick={props.onClick}
      className={cx(props.className, 'w-12 z-10 h-12 text-white opacity-50 hover:opacity-100 cursor-pointer')}
    />

const PaginationDot:FC<{className?: string, selected: boolean, onClick:(e:any) => void}> = ({onClick, selected, className=''}) =>
  <div
    onClick={onClick}
    css={css`
      opacity: ${selected ? '1' : '.45'};
      &:hover {opacity: .7;}
    `}
    className={cx(className)}
  />

type Props = {
  className?:string,
  slides: {srcs: string[], id: string}
}

const ProductSlider:FC<Props> = ({className='', slides}) => {
  const ref = useRef<any>(null)
  const product = useSelector(productSelector(slides.id as ProductId))
  const cesable = ['tactsuit-x40'].includes(slides.id)
  const from = 'December 15, 2020 15:00:00 UTC';
  let single = slides.srcs.length === 1

  const [state, setState] = useState({
    index: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    slides: 2
  });

  const pages = Math.ceil(state.slides / state.slidesToScroll);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0
        }
      }
    ],
    beforeChange: (currentSlide: number, nextSlide: number) => {
      if (state.index !== nextSlide) {
        setState(state => ({ ...state, index: nextSlide }));
      }
    },
    onInit: () => {
      if (!!ref && !!ref.current) {
        console.log(ref.current.props);
      }
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResizeEvent);
    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, []);

  useEffect(() => {
    initialize();
  }, [ref.current]);

  const initialize = () => {
    if (ref.current) {
      const {
        state: { breakpoint },
        props
      } = ref.current;
      const { children } = props;
      if (breakpoint === null) {
        const { slidesToShow, slidesToScroll } = props;
        setState(state => ({
          ...state,
          slidesToShow,
          slidesToScroll,
          slides: children.length
        }));
      } else {
        const responsive = props.responsive.filter(
          (item: any) => item.breakpoint === breakpoint
        )[0];
        const { slidesToShow, slidesToScroll } = responsive.settings;
        setState(state => ({
          ...state,
          slidesToShow,
          slidesToScroll,
          slides: children.length
        }));
      }
      ref.current.slickGoTo(0);
    }
  };

  const handleResizeEvent = () => initialize();

  const handleLeft = (e: any) => ref.current && ref.current.slickPrev();

  const handleRight = (e: any) => ref.current && ref.current.slickNext();

  const handlePageTo = (index: number) => (e: any) => {
    ref.current.slickGoTo(index);
  }

  return (
    <div
      css={css`background-color: #0f1014;`}
      className={'flex w-full relative items-center'}
    >
      {cesable && (Date.now() > Date.parse(from)) && <img src={desktopCes} className={'w-12 absolute top-0 left-0 z-10 mt-2 ml-2'} />}
      <PriceTag
        css={css`background-color: #4e4e51;`}
        price={product.price}
        className={'absolute top-0 z-10 right-0 mt-2 mr-2'} />
      <Chevron
        onClick={handleLeft}
        className={cx('left-0 absolute', {'hidden': single})}
      />
      <Slider
        className={'w-full'}
        css={css`
              & .slick-arrow {visibility: hidden; display: none !important;}
              & .slick-track {
                height: 280px;
              }
              & .slick-slide > div {height: 100%}
            `}
        ref={ref} {...settings}
      >
        {
          slides.srcs.map((src, index) => {
            return (
              <div
                css={css`display: flex!important;`}
                key={index} className={'h-full flex justify-center items-center relative'}>
                <Link to={`/shop-products/${slides.id}`} className={'h-full flex items-center justify-center'}>
                  <img className={'h-full py-6 object-cover'} src={src} />
                </Link>
              </div>
            )
          })
        }
      </Slider>
      <Chevron
        rtl={true}
        onClick={handleRight}
        className={cx('right-0 absolute', {'hidden': single})}
      />
      <div
        className={cx(
          {'hidden': single},
          'absolute bottom-0 left-0 mb-3.5 right-0 w-full flex justify-center'
        )}
      >
        {
          [...new Array(pages)].map((page, index) => (
            <PaginationDot
              selected={index === state.index / state.slidesToShow}
              className={'w-2 h-2 mx-2.5 bg-white cursor-pointer rounded-full'}
              onClick={handlePageTo(index * state.slidesToShow)} key={index} />
          ))
        }
      </div>
    </div>
  )
}

export default ProductSlider