import { RootState } from "../reducers";
import { createSelector } from "reselect";
import {ProductId} from "../../models/Product";

export const productsSelector = (state: RootState) => state.products;

export const productSelector = (productId: string) =>
  createSelector(
    [productsSelector],
    products => products.filter((product: any) => product.id === productId)[0]
  );

export const categorySelector = (productId: ProductId) =>
  createSelector(
    [productSelector(productId)],
    product => product.category
  );
