/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import {translationComesWithSelector, translationGeneralSelector} from "../../../store/selectors/translationSelector";
import Validator from "../../../utils/Validator";
import tactsuitXAudioAccessoriesImage from "./images/desktop/tactsuit-x-audio-accessories.png";
import tactalImage from "./images/desktop/tactal.png";
import tactsuitX16Image from "./images/desktop/tactsuitX16.png";
import tactsuitX40Image from "./images/desktop/tactsuitX40.png";
import tactosyForFeetImage from "./images/desktop/tactosyForFeet.png";
import tactosyForArmsImage from "./images/desktop/tactosyForArms.png";
import tactosyForHandsImage from "./images/desktop/tactosyForHands.png";
import tactotForBackpackPcImage from "./images/desktop/tactotForBackpackPc.png";
import bleDongleIcon from './images/icons/bleDongle.svg';
import manualIcon from './images/icons/manual.svg';
import tactalIcon from './images/icons/tactal.svg';
import tactosyForArmsIcon from './images/icons/tactosyForArms.svg';
import tactosyForFeetIcon from './images/icons/tactosyForFeet.svg';
import tactosyForHandsIcon from './images/icons/tactosyForHands.svg';
import tactotForBackpackPcIcon from './images/icons/tactotForBackpackPc.svg';
import trackerConnectingModuleIcon from './images/icons/trackerConnectingModule.svg';
import usbTypeCCableIcon from './images/icons/usbTypeCCable.svg';
import velcroIcon from './images/icons/velcro.svg';
import tactsuitX16Icon from './images/icons/tactsuitX16.svg';
import tactsuitX40Icon from './images/icons/tactsuitX40.svg';
import springCable from './images/icons/springCable.svg'
import yJack from './images/icons/yJack.svg'

import {Product, ProductId} from "../../../models/Product";
import {localeSelector} from "../../../store/selectors/configSelector";
import {cx} from "emotion";

const images:{[key: string]: any} = {
  [ProductId.TactotForBackPackPc]: tactotForBackpackPcImage,
  [ProductId.TactosyForHands]: tactosyForHandsImage,
  [ProductId.TactosyForArms]: tactosyForArmsImage,
  [ProductId.TactosyForFeet]: tactosyForFeetImage,
  [ProductId.Tactal]: tactalImage,
  [ProductId.TactsuitX40]: tactsuitX40Image,
  [ProductId.TactsuitX16]: tactsuitX16Image,
  [ProductId.TactsuitXAudioAccessories]: tactsuitXAudioAccessoriesImage
}

const icons:{[key: string]: string[]} = {
  'tactot-for-backpack-pc': [tactotForBackpackPcIcon, usbTypeCCableIcon, bleDongleIcon, manualIcon],
  'tactsuit-x40': [tactsuitX40Icon, usbTypeCCableIcon, bleDongleIcon, manualIcon],
  'tactsuit-x16': [tactsuitX16Icon, usbTypeCCableIcon, bleDongleIcon, manualIcon],
  'tactal': [tactalIcon, usbTypeCCableIcon, velcroIcon, bleDongleIcon, manualIcon],
  'tactosy-for-arms': [tactosyForArmsIcon, usbTypeCCableIcon, bleDongleIcon, manualIcon],
  'tactosy-for-hands': [tactosyForHandsIcon, usbTypeCCableIcon, bleDongleIcon, manualIcon, trackerConnectingModuleIcon],
  'tactosy-for-feet': [tactosyForFeetIcon, usbTypeCCableIcon, bleDongleIcon, manualIcon, trackerConnectingModuleIcon],
  'tactsuit-x-audio-accessories': [springCable, yJack, '', ''],
}

type Props = {
  id: ProductId;
};

const ComesWith: FC<Props> = ({id}) => {
  const translationComesWith = useSelector(translationComesWithSelector)
  const locale = useSelector(localeSelector)
  const translationGeneral = useSelector(translationGeneralSelector)
  let head = Validator.tryGetValue(translationGeneral, 'comesWith', locale.languageCode)
  if (!head) {
    head = 'Comes With'
  }
  let data = Validator.tryGetValue(translationComesWith, id)
  const valid = (id in icons) && data
  let filled = data
  if (valid) {
    filled = filled.map((item: any, index:number) => ({...item, src: icons[id][index]}))
    if (filled.length < 3) {
      filled = [...filled, undefined]
    }
    else if (filled.length === 4) {
      filled = [filled[0], filled[1], undefined, filled[2], filled[3]]
    }
  }

  let requiredCells = 0
  if (id in icons) {
    requiredCells = icons[id].length
  }

  return valid ?
    <div className={"w-full bg-black mx-auto"}>
      <div
        css={css`background-image: linear-gradient(to left, #dadada, #ffffff 0%);`}
        className={'max-w-screen-xl overflow-x-hidden py-8 lg:py-20 mx-auto w-full flex items-center justify-center'}
      >
        <div className={'flex flex-col px-5 max-w-screen-xs sm:max-w-screen-md mx-auto w-full'}>
          <span className={'text-3xl lg:text-4xl font-extrabold whitespace-no-wrap'}>{head}</span>
          <div className={'mt-5 relative lg:mt-10 grid grid-cols-3 sm:grid-cols-5 grid gap-x-5 gap-y-8'}>
            <div
              className={'col-span-3 grid grid-cols-3 gap-x-5 gap-y-8'}>
              {
                [...new Array(filled.length)].map((value: any, index: number) => {
                  return (
                    <div
                      key={index}
                      css={css`
                        @media(max-width: 639px) {
                        transform: translateX(${
                          requiredCells <= 4 ? 
                            'calc(50% + 0.625rem)' 
                            : 
                            requiredCells <= 5 ?
                              index > 2 ? 'calc(50% + 0.625rem)' : '' : ''
                        });
                        }
                      `}
                      className={cx('row-span-1 flex items-baseline justify-center',)}
                    >
                      {
                        !!filled[index] &&
                        <div className={'flex flex-col items-center text-center'}>
                          {!!filled[index] && <img css={css`min-width: 4.5rem; min-height: 4.5rem; max-width: 6.5rem; max-height: 6.5rem`} className={'flex-grow w-full'} src={filled[index].src} />}
                          {!!filled[index] && <span className={'text-sm sm:text-base mt-3.5 leading-tight whitespace-pre-wrap'}>{filled[index][`label_${locale.languageCode}`]}</span>}
                        </div>
                      }
                    </div>
                  )
                })
              }
            </div>
            <div className={'col-span-2 hidden sm:flex items-center justify-center'}>
              <img src={images[id]} className={"object-contain"} />
            </div>
          </div>
        </div>
      </div>
    </div>
   : null;
};

export default ComesWith;
