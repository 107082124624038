/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC, useEffect, useState } from "react";
import { importFiles } from "../../utils/CommonUtil";
import {cx} from "emotion";
import {useSelector} from "react-redux";
import {offerSelector} from "../../store/selectors/offerSelector";

type Props = {
  className?:string
};

const SummarizeOffer: FC<Props> = ({className }) => {
  const [subtotal, setSubtotal] = useState(0);
  const offer = useSelector(offerSelector);
  const images = importFiles(
    require.context("./images/desktop", false, /\.(png|jpe?g|svg)$/)
  );

  useEffect(() => {
    if (!!offer) {
      setSubtotal(
        offer.items.reduce((prev, current, index) => {
          prev += current.qty * current.price;
          return prev;
        }, 0)
      );
    }
  }, [offer]);

  return (
    <div className={cx(className)}>
      <div className={'flex bg-white flex-col px-1 lg:px-4 py-2 border rounded'}>
        <span className={'text-xl font-semibold'}>Offer Summary</span>
        <div className={'py-2'}>
          {
            offer.items.map((offerItem, index) => {
              return (
                <div className={'flex flex-col px-1 lg:px-4 mb-2 border rounded'} key={index}>
                  <div className={'flex justify-between items-center'}>
                    <div className={'h-32 flex items-center'}>
                      <img className={'object-contain h-full'} src={images[`${offerItem.id}.png`]}/>
                    </div>
                    <div className={'flex-grow flex flex-col justify-center items-end'}>
                      <span className={'capitalize text-lg font-semibold'}>{offerItem.name}</span>
                      {
                        (!!offerItem.variants && !!offerItem.variants.length) ?
                          <div className={'flex items-center'}>
                            {
                              offerItem.variants.map((variant, index) => {
                                const sentence = index === 0 ?
                                  `${variant.type}: ${variant.kind}` :
                                  `, ${variant.type}: ${variant.kind}`
                                return (
                                  <span key={index} className={'capitalize'}>{sentence}</span>
                                )
                              })
                            }
                          </div>
                          :
                          <div className={'flex items-center'}>
                            <span className={'capitalize'}>{offerItem.desc}</span>
                          </div>
                      }
                      <div className={'capitalize text-lg font-semibold flex items-center flex-no-wrap'}>
                        <span className={'mr-1'}>{`Qty: ${offerItem.qty}`}</span>
                      </div>
                      <span className={'capitalize text-lg font-semibold'}>{`$${offerItem.price.toFixed(2)}`}</span>
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
        <div className={'flex flex-col'}>
          <div className={'flex justify-between'}>
            <span>Subtotal</span>
            <span>{`$ ${subtotal}`}</span>
          </div>
          <div className={'flex flex-wrap md:flex-no-wrap justify-between'}>
            <span>Tax</span>
            <span className={'flex justify-end pl-2 text-right'}>Taxes and duties are not included in the price!</span>
          </div>
          <div className={'flex justify-between'}>
            <span>Shipping</span>
            <span>{`$ ${offer.shippingPrice}`}</span>
          </div>
          <div className={'flex justify-between'}>
            <span>Discount</span>
            <span>{`$ ${offer.discountPrice}`}</span>
          </div>
          <div className={'flex justify-between'}>
            <span>Total</span>
            <span>{`$ ${+(+subtotal + offer.shippingPrice - offer.discountPrice).toFixed(2)}`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummarizeOffer;
