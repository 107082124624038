import {Personal} from "../../interfaces/personal";
import {RecipientActionType, RecipientState} from "../reducers/recipient";
import {Address} from "../../models/Address";

export const updateRecipient = (recipient: RecipientState) => (
  dispatch: any,
  getState: any
) => {
  dispatch({ type: RecipientActionType.UpdateRecipient, payload: recipient });
};

export const updateRecipientAddress = (address: Address) => (
  dispatch: any,
  getState: any
) => {
  dispatch({ type: RecipientActionType.UpdateRecipientAddress, payload: address });
};

export const updateRecipientPersonal = (personal: Personal) => (
  dispatch: any,
  getState: any
) => {
  dispatch({ type: RecipientActionType.UpdateRecipientPersonal, payload: personal });
};