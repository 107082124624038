import Loading from "components/loading";
import React, { useEffect } from "react";

function NewShopPage() {
  useEffect(() => {
    window.location.href = `https://www.bhaptics.com/shop`;
  }, []);

  return <Loading />;
}

export default NewShopPage;
