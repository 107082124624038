/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC, Fragment} from "react";
import {OrderState} from "../../pages/TrackingPage";
import {cx} from "emotion";
import {Tracking, TrackingState} from "../../types/Tracking";
import DateUtils from "../../utils/DateUtils";

type Props = {
  className?:string,
  orderStates: OrderState[],
  tracking: Tracking
}

const HorizontalOrderStatesFlowchart:FC<Props> = ({tracking, className='', orderStates}) => {
  const orderStateIndex = tracking.state === TrackingState.orderPlaced ? 0 :
    tracking.state === TrackingState.packing ? 1 :
      tracking.state === TrackingState.shipped ? 2 :
        -1;
  const currentState:any = orderStates.filter((state:any) => state.id === tracking.state)[0];
  let shippingDate:any = undefined
  if (!!tracking.shippingMillis) {
    const date = DateUtils.toLocaleDate(!!tracking.shippingMillis ? tracking.shippingMillis : 0, 9)
    const dateString = date.toDateString()
    const splitted = dateString.split(" ")
    shippingDate = `${splitted[1]} ${splitted[2]}`
  }

  if (!!tracking && 'shippingDate' in tracking) {
    //@ts-ignore
    const _splitted = tracking.shippingDate.split(" ")
    shippingDate = `${_splitted[1]} ${_splitted[2]}`
  }

  return (
    <Fragment>
      <div className={cx(className, 'flex w-full')}>
        {
          orderStates.map((state, index) => {
            const dotColor = index <= orderStateIndex ? '#6dd400' : '#d8d8d8'
            const leftLineColor = index < orderStateIndex + 1 ? '#6dd400' : '#d8d8d8'
            const rightLineColor = index < orderStateIndex ? '#6dd400' : '#d8d8d8'

            return (
              <div
                className={'flex w-1/3 flex-col items-center justify-center'}
                key={index}
              >
                <img src={state.icons[+(index <= orderStateIndex)]} />
                <div className={'flex mt-3 w-full items-center justify-center'}>
                  <div
                    css={css`height: 3px; background-color: ${leftLineColor}`}
                    className={cx(
                      {'invisible': index === 0},
                      'flex flex-grow'
                    )} />
                  <span
                    css={css`background-color: ${dotColor}`}
                    className={'w-3 h-3 rounded-full'} />
                  <div
                    css={css`height: 3px; background-color: ${rightLineColor}`}
                    className={cx(
                      {'invisible': index === 2},
                      'flex flex-grow'
                    )} />
                </div>
                <span
                  className={'mt-3 text-2xl font-medium'}
                  css={css`color: ${index <= orderStateIndex ? '#ffffff' : '#898989'};`}
                >
                  {state.label}
                </span>
              </div>
            )
          })
        }
      </div>
      <div className={'hidden sm:block px-10 w-full py-4'}>
        <div
          css={css`background: #1e1e21;`}
          className={'flex w-full mt-5 items-center justify-center'}
        >
          <div className={'max-w-screen-md w-full my-4 px-2 flex flex-col items-center justify-center'}>
            <span className={'font-bold text-lg text-white'}>{`<${currentState.label}>`}</span>
            <div className={'leading-none lg:text-center'}>
              <span className={'text-center lg:whitespace-pre-wrap text-sm mt-2 text-white leading-tight'}>{`${currentState.description}`}</span>
              {currentState.id === TrackingState.packing && <span className={'text-white font-bold'}>{shippingDate}.</span>}
              {currentState.id === TrackingState.shipped &&
              <a href={tracking.trackingUrl} target={'_blank'} className={'hover:opacity-75 text-white'}>
                <span>{tracking.trackingNumber}</span>
              </a>
              }
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default HorizontalOrderStatesFlowchart