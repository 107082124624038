/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC} from "react";
import {cx} from "emotion";
import compareIcon from './images/icon-compare-big.png'
import tactsuitIcon from './images/icon-tact-suit-big.png'
import tactalIcon from './images/icon-tactal-big.png'
import armsIcon from './images/icon-tactosy-for-arms-big.png'
import feetIcon from './images/icon-tactosy-for-feet-big.png'
import handsIcon from './images/icon-tactosy-for-hands-big.png'

type Props = {
  className?:string
}

const icons:{[id: string]: any} = {
  x40: tactsuitIcon,
  x16: tactsuitIcon,
  compare: compareIcon,
  tactal: tactalIcon,
  arms: armsIcon,
  hands: handsIcon,
  feet: feetIcon,
}

const menus:{id?:string, label: string, href: string}[][] = [
  [
    {id:'x40', label: 'TactSuitX40', href: 'https://www.bhaptics.com/tactsuit/tactsuit-x40'},
    {id:'x16', label: 'TactSuit X16', href: 'https://www.bhaptics.com/tactsuit/tactsuit-x16'},
    {id:'compare', label: 'Compare', href: 'https://www.bhaptics.com/tactsuit/compare'},
    {id:'tactal', label: 'Tactal', href: 'https://www.bhaptics.com/tactsuit/tactal'},
    {id:'arms', label: 'Tactosy for\nArms', href: 'https://www.bhaptics.com/tactsuit/tactosy-for-arms'},
    {id:'hands', label: 'Tactosy for\nHands', href: 'https://www.bhaptics.com/tactsuit/tactosy-for-hands'},
    {id:'feet', label: 'Tactosy for\nFeet', href: 'https://www.bhaptics.com/tactsuit/tactosy-for-feet'}
  ],
  [
    {label: 'VR', href: 'https://www.bhaptics.com/experiences/vr'},
    {label: 'PC', href: 'https://www.bhaptics.com/experiences/pc'},
    {label: 'Console', href: 'https://www.bhaptics.com/experiences/console'},
    {label: 'Movie/Music', href: 'https://www.bhaptics.com/experiences/movie-music'},
  ],
  [],
  [
    {id: '', label: 'Setup', href: 'https://www.bhaptics.com/support/setup'},
    {id: '', label: 'FAQ', href: 'https://www.bhaptics.com/support/faq'},
    {id: '', label: 'Downloads', href: 'https://www.bhaptics.com/support/downloads'},
    {id: '', label: 'Developer', href: 'https://www.bhaptics.com/support/developers'},
    {id: '', label: 'Contact Us', href: 'https://www.bhaptics.com/support/contact-us'}
  ],
  []
]

const SubMenus:FC<Props> = ({className=''}) => {
  return (
    <div
      css={css`
        opacity: 0.96;
        top: 100%;
        box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.03);
      `}
      className={cx(className, 'w-full mx-auto left-0 right-0 bg-white absolute items-center justify-center')}>
      {
        menus.map((menu, index) => {
          return (
            <div className={'max-w-screen-xl mx-auto hidden items-center justify-center'} key={index}>
              {
                !!menu.length && menu.map((item, itemIndex) => {
                  const onTactsuit = index === 0
                  return (
                    <a
                      href={item.href}
                      css={css`
                        width: ${onTactsuit ? '6.625rem' : ''}; 
                        padding: ${onTactsuit ? '1rem 0rem 0.815rem 0rem' : '1.25rem 1.565rem'};
                      `}
                      className={'opacity-50 hover:opacity-100 flex h-full flex-col justify-around items-center'} key={itemIndex}>
                      {'id' in item && !!item.id && <img className={'w-10 h-10'} src={icons[item.id]}/>}
                      <span
                        css={css`color: #222222; line-height: 1.33`}
                        className={cx(
                          onTactsuit ? 'text-xs h-8 mt-2' : 'text-sm h-5',
                          'whitespace-pre-wrap text-center font-medium'
                        )}
                      >
                        {item.label}
                      </span>
                    </a>
                  )
                })
              }
            </div>
          )
        })
      }
    </div>
  )
}

export default SubMenus