export const isArray = (source: any) =>
  typeof source === "object" && source instanceof Array;

export const isInteger = (source: any) => Number.isInteger(source);

export const getDimension = (node: any) => node.getBoundingClientRect();

export const isObject = (obj: any) => typeof obj === "object" && obj !== null;

export const isFunction = (x: any) => !!x && typeof x === "function";

export const isNumber = (value: any) => !isNaN(value) && typeof (value) === 'number'

export const clamp = (min: number, max: number, candidate: number) =>
  Math.min(max, Math.max(min, candidate));

export const importFiles = (target: any) => {
  let files: any = {};
  target
    .keys()
    .forEach((key: any) => (files[key.replace("./", "")] = target(key)));
  return files;
};

export const readCSV = (keyAndPath:{key:string, path: string}) => new Promise((resolve, reject) => {
  const request = new XMLHttpRequest();
  request.onloadend = () => resolve({request, key:keyAndPath.key})
  request.open('GET', keyAndPath.path, true)
  request.send()
})

export const readCsvs = (...keyAndPaths: {key: string, path:string}[]) => Promise.all(
  keyAndPaths.map((keyAndPath, index) => readCSV(keyAndPath))
)

// todo - divide function for simplicity or rename clearly
export const arrayToObject = (array: any[], classifier: string):any => {
  let _object: any = {}
  array.map((item) => {
    const has = !!_object[item[classifier]]
    if (!has) {
      _object[item[classifier]] = item
    }
    else {
      if (!Array.isArray(_object[item[classifier]])) {
        _object[item[classifier]] = [_object[item[classifier]]]
      }
      _object[item[classifier]] = [..._object[item[classifier]], item]
    }
  })
  return _object;
}

export const objectToArray = (object: {[key: string]: any}) => {
  return Object.keys(object).map((key:string) => object[key])
}