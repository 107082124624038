export interface Notification {
  type: string,
  on: boolean
}

const initialState = {
  type: '',
  on: false
}

export enum NotificationActionType {
  UpdateNotification = 'notification/UpdateNotification'
}

type NotificationAction =
  {type: NotificationActionType.UpdateNotification, payload: Notification};

export const notification = (state: Notification = initialState, action: NotificationAction) => {
  switch (action.type) {
    case NotificationActionType.UpdateNotification: {
      return action.payload
    }
    default:
      return state
  }
}