import {Country} from "../../firebase/Shipping";
import produce from "immer";

const initialState: Country[] = []

export enum CountriesActionType {
  UpdateCountries = "countries/updateCountries",
}

type CountriesAction =
  | {type: CountriesActionType.UpdateCountries; payload: Country[]}

export const countries = produce(
  (draft: Country[]=initialState, action: CountriesAction) => {
    switch (action.type) {
      case CountriesActionType.UpdateCountries: {
        draft = action.payload;
        return draft
      }
      default:
        return draft
    }
  }
)