/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";
import ProductCard from "../productCard";
import ComesWith from "../comesWith";
import Overview from "../overview";
import Features from "../features";
import Battery from "../battery";
import Support from "../support";
import Notice from "../notice";
import YouMayLike from "../youMayLike";
import {Product, ProductId} from "../../../models/Product";

type Props = {
  id: ProductId;
  product: Product
};

const Hardware: FC<Props> = ({product, id }) => {
  return (
    <div>
      <ProductCard product={product} />
      <ComesWith id={id} />
      <Overview id={id} />
      <Features id={id} />
      <Battery id={id} />
      <Support />
      <Notice id={id} />
      <YouMayLike id={id} />
    </div>
  );
};

export default Hardware;
