import {Personal} from "../../interfaces/personal";
import {PayerActionType, PayerState} from "../reducers/payer";
import {Address} from "../../models/Address";

export const updatePayer = (payer: PayerState) => (
  dispatch: any,
  getState: any
) => {
  dispatch({ type: PayerActionType.UpdatePayer, payload: payer });
};

export const updatePayerPersonal = (personal: Personal) => (
  dispatch: any,
  getState: any
) => {
  dispatch({ type: PayerActionType.UpdatePayerPersonal, payload: personal });
};

export const updatePayerAddress = (address: Address) => (
  dispatch: any,
  getState: any
) => {
  dispatch({ type: PayerActionType.UpdatePayerAddress, payload: address });
};