/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC} from "react";
import Portal from "../../portal";
import icon from "../../notification/images/desktop/logo.png";
import Validator from "../../../utils/Validator";
import {cx} from "emotion";
import {useSelector} from "react-redux";
import {localeSelector} from "../../../store/selectors/configSelector";
import {translationGeneralSelector} from "../../../store/selectors/translationSelector";
import LocaleUtil from "../../../utils/LocaleUtil";

type Props = {
  className?:string,
  type: string
  onNotify:(actionType: string) => (e:any) => void
}

const Button: FC<{ onClick: (e: any) => void; className?: string }> = ({className = "", children, onClick}) => (
  <button
    className={cx(
      className,
      "px-2 py-1 rounded-full border hover:opacity-75"
    )}
    onClick={onClick}
  >
    {children}
  </button>
);

const Notify:FC<Props> = ({className='', type, onNotify}) => {
  const locale = useSelector(localeSelector)
  const translationGeneral = useSelector(translationGeneralSelector)

  const head = Validator.tryGetValue(
    translationGeneral,
    type === 'notAccessoriesOnly' ? 'toPurchaseAccessoriesOnly' : 'forBulkOrders',
    locale.languageCode
  )
  const contactUs = Validator.tryGetValue(translationGeneral, 'contactUsAt', locale.languageCode)
  const footnoteForBulkOffer = Validator.tryGetValue(translationGeneral, 'toGetADiscountAndArrangeShipping', locale.languageCode)
  const cancel = Validator.tryGetValue(translationGeneral, 'cancel', locale.languageCode)
  const contact = Validator.tryGetValue(translationGeneral, 'contact', locale.languageCode)

  return (
    <Portal css={css`font-family: ${LocaleUtil.getFontFamily(locale.languageCode)};`}>
      <div
        css={css`backdrop-filter: blur(1px);`}
        className={"z-50 fixed bg-black bg-opacity-25 inset-0 w-full h-full flex flex-col items-center justify-center"}
      >
        <div
          css={css`background-color: #0f1014;`}
          className={"flex flex-col rounded text-white items-center justify-center pb-8 pt-10 px-20"}
        >
          <img className={"mb-4"} src={icon} />
          <div className={"text-center whitespace-pre-wrap leading-snug mb-4"}>
            <span className={"block"}>{head}</span>
            <div className={"flex flex-wrap justify-center"}>
              <span className={cx({'order-last': locale.languageCode === 'jp'})}>{contactUs}{" "}</span>
              <a
                className={"underline"}
                target={"_top"}
                href={"mailto:contact@bhaptics.com"}
              >
                contact@bhaptics.com
              </a>
            </div>
            {type === 'bulkOffer' && <span className={"block"}>{footnoteForBulkOffer}</span>}
          </div>
          <div className={"flex flex-row items-center"}>
            <Button
              css={css`color: rgba(255, 0, 0, 1);border-color: rgba(255, 0, 0, 1);`}
              onClick={onNotify('cancel')}
            >
              <span className={"text-sm font-medium whitespace-no-wrap"}>{cancel}</span>
            </Button>
            <Button
              className={"ml-4"}
              css={css`color: #6dd400;border-color: #6dd400;`}
              onClick={onNotify('contact')}
            >
              <span className={"text-sm font-medium whitespace-no-wrap"}>{contact}</span>
            </Button>
          </div>
        </div>
      </div>
    </Portal>
  )
}

export default Notify