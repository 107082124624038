/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC} from "react";
import {cx} from "emotion";
import marker from "./images/marker.svg";

type Props = {
  className?:string,
  ranking: number
}

const Ranking:FC<Props> = ({ranking, className=''}) => {
  return (
    <div className={cx(className, "absolute top-0 left-0 ml-2")}>
      <img src={marker} />
      <span className={"absolute inset-0 flex items-center text-black justify-center mb-2"}>{ranking}</span>
    </div>
  )
}

export default Ranking