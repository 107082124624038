/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC} from "react";
import {useSelector} from "react-redux";
import {translationGeneralSelector} from "../../../store/selectors/translationSelector";
import {cx} from "emotion";
import {localeSelector} from "../../../store/selectors/configSelector";
import Validator from "../../../utils/Validator";
import {Variant} from "../../../models/Product";

type Props = {
  className?:string,
  id: string,
  variants?:Variant[] | undefined
}

const Backorder:FC<Props> = ({id, className='', variants}) => {
  let idWithVariants = id
  const varianted = Array.isArray(variants) && variants.length > 0;
  if (!!varianted) {
    idWithVariants = `${idWithVariants}-${JSON.stringify(variants)}`
  }
  const translationGeneral = useSelector(translationGeneralSelector)
  const backorders: {[key:string]: {name:string, date_en:string, date_cn:string, date_jp:string, date_kr:string}} = {
    'tactsuit-x40': {
      'name': '',
      'date_en': 'Jun 04th',
      'date_cn': '06月04日',
      'date_jp': '06月04日',
      'date_kr': 'Jun 04th',
    },
    'tactosy-for-arms': {
      'name': '',
      'date_en': 'May 18th',
      'date_cn': '05月18日',
      'date_jp': '05月18日',
      'date_kr': 'May 18th',
    },
    'tactosy-for-hands': {
      'name': '',
      'date_en': 'May 18th',
      'date_cn': '05月18日',
      'date_jp': '05月18日',
      'date_kr': 'May 18th',
    },
    'tactosy-for-feet': {
      'name': '',
      'date_en': 'May 18th',
      'date_cn': '05月18日',
      'date_jp': '05月18日',
      'date_kr': 'May 18th',
    },
    'tactsuit-x16': {
      'name': '',
      'date_en': 'May 18th',
      'date_cn': '05月18日',
      'date_jp': '05月18日',
      'date_kr': 'May 18th',
    },
    'tactal-[{"type":"Size","kind":"Wide"}]': {
      'name': 'Wide size -',
      'date_en': 'May 18th',
      'date_cn': '05月18日',
      'date_jp': '05月18日',
      'date_kr': 'May 18th',
    },
    'tactal-[{"type":"Size","kind":"Standard"}]': {
      'name': 'Standard size -',
      'date_en': 'May 18th',
      'date_cn': '05月18日',
      'date_jp': '05月18日',
      'date_kr': 'May 18th',
    },
  }

  const locale = useSelector(localeSelector)
  let text = Validator.tryGetValue(translationGeneral, 'estimatedShipDate', locale.languageCode)
  if (!text) {
    text = 'Estimated Ship Date'
  }

  const backorder = backorders[idWithVariants]

  return (
    !!backorder ?
      <div
        css={css`color:#60a5fa`}
        className={cx(className, 'flex flex-col')}>
        <span className={'block text-sm font-bold'}>{`* ${text}`}</span>
        <div className={'flex ml-2.5 leading-4 text-xs flex-no-wrap'}>
          {!!backorder.name && <span>{backorder.name}</span>}
          <span>
              {
                //@ts-ignore
                backorder[`date_${locale.languageCode}`]
              }
            </span>
        </div>
      </div>
      :
      null
  )
}

export default Backorder
