/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC} from "react";
import {MdClose} from 'react-icons/md'
import finderIcon from './images/finderIcon.png'

type Props = {
  className?:string,
  onClose: (e: any) => void
}

const AboutOrderID:FC<Props> = ({className='', onClose}) => {
  return (
    <div
      css={css`
        background: rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(1px);
      `}
      className={"z-50 fixed inset-0 w-full h-full flex items-center justify-center"}
    >
      <div
        css={css`background-color: #1e1e21;`}
        className={
          "max-w-screen-sm whitespace-pre-wrap w-full flex flex-col rounded text-white items-center justify-center pb-12 pt-5 px-8"
        }
      >
        <div className={'w-full flex justify-end items-center'}>
          <MdClose onClick={onClose} className={'hover:opacity-75 cursor-pointer text-white text-3xl'} />
        </div>
        <div className={'w-full flex items-center justify-center'}>
          <img src={finderIcon}/>
        </div>
        <div
          css={css`& * {color: #6d7278}`}
          className={'flex px-4 mt-2 flex-wrap items-center justify-center text-center'}>
          <span>You can find your Order ID on the top right corner of your Order Confirmation email. If you can’t find your Order ID, contact us at </span>
          <a
            target={"_top"}
            href={"mailto:support@bhaptics.com"}
          >
            <span
              css={css`text-decoration-color: #6d7278`}
              className={'underline hover:opacity-75'}>support@bhaptics.com</span>
          </a>
          <span> with your name and ship-to address.</span>
        </div>
      </div>
    </div>
  )
}

export default AboutOrderID