/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC, useEffect} from "react";
import {CartState} from "../../../store/reducers/cart";
import {cx} from "emotion";
import Validator from "../../../utils/Validator";
import {useDispatch, useSelector} from "react-redux";
import {localeSelector} from "../../../store/selectors/configSelector";
import {translationGeneralSelector} from "../../../store/selectors/translationSelector";
import tactsuitX16 from './images/tactsuit-x16.png'
import tactsuitX40 from './images/tactsuit-x40.png'
import tactal from './images/tactal.png'
import tactotForBackpackPc from './images/tactot-for-backpack-pc.png'
import tactosyForArms from './images/tactosy-for-arms.png'
import tactosyForHands from './images/tactosy-for-hands.png'
import innerLiningForTactosyForArms from './images/inner-lining-for-tactosy-for-arms.png'
import meshLiningForTactsuitX from './images/mesh-lining-for-tactsuit-x.png'
import tactsuitXAudioAccessories from './images/tactsuit-x-audio-accessories.png'
import tactosyForFeet from './images/tactosy-for-feet.png'

import {BiPlus, BiMinus} from 'react-icons/bi'
import {shippingPriceSelector} from "../../../store/selectors/shippingSelector";
import Backorder from "../../shop/productCard/Backorder";
import {checkedCartItemsSelector} from "../../../store/selectors/cartSelector";
import {recipientSelector} from "../../../store/selectors/recipientSelector";
import Shipping from "../../../firebase/Shipping";
import {updateShippingPrice} from "../../../store/actions/shippingAction";

type Props = {
  className?:string,
  onUpdateCart: (cart:CartState) => void,
  step:number
}

const images: any = {
  'tactsuit-x40': tactsuitX40,
  'tactsuit-x16': tactsuitX16,
  'tactal': tactal,
  'tactot-for-backpack-pc': tactotForBackpackPc,
  'tactosy-for-arms': tactosyForArms,
  'tactosy-for-hands': tactosyForHands,
  'inner-lining-for-tactosy-for-arms': innerLiningForTactosyForArms,
  'mesh-lining-for-tactsuit-x': meshLiningForTactsuitX,
  'tactsuit-x-audio-accessories': tactsuitXAudioAccessories,
  'tactosy-for-feet': tactosyForFeet,
}

const SummarizeCart:FC<Props> = ({step, className='', onUpdateCart}) => {
  const checkedCartItems = useSelector(checkedCartItemsSelector)
  const dispatch = useDispatch()
  const recipient = useSelector(recipientSelector)
  const locale = useSelector(localeSelector)
  const translationGeneral = useSelector(translationGeneralSelector)
  const shippingPrice = useSelector(shippingPriceSelector)
  const price = checkedCartItems.reduce((prev: number, current, index) => {
    prev += current.price * current.qty
    return prev
  }, 0)

  useEffect(() => {
    const country_iso_2 = recipient.address.country.iso_2
    if (checkedCartItems.length && !!country_iso_2) {
      Shipping.getPrice({
        country_iso_2,
        cartItems: checkedCartItems
      }).then((shippingPrice) => {
        dispatch(updateShippingPrice(shippingPrice))
      })
    }
  }, [checkedCartItems])

  const handleClick = (key:string) => (cartItemIndex: number) => (e:any) => {
    const value = key === 'plus' ? 1 : -1
    const nextCartItems = checkedCartItems.map((cartItem, index) =>
      index === cartItemIndex ? {...cartItem, qty: cartItem.qty + value} : cartItem
    )
    onUpdateCart({items: nextCartItems})
  }

  return (
    <div className={cx(className)}>
      <div className={'flex bg-white flex-col px-1 lg:px-4 py-2 border rounded'}>
        <span className={'text-xl font-semibold'}>{Validator.tryGetValue(translationGeneral, 'orderSummary', locale.languageCode)}</span>
        <div className={'py-2'}>
          {
            checkedCartItems.map((cartItem, cartItemIndex) => {
              const max = 9
              return (
                <div key={cartItemIndex} className={'flex flex-col px-1 lg:px-4 mb-2 border rounded'}>
                  <div className={'flex justify-between items-center'}>
                    <div className={'h-32 flex items-center'}>
                      <img className={'object-contain h-full'} src={images[cartItem.id as string]} />
                    </div>
                    <div className={'flex-grow flex flex-col justify-center items-end'}>
                      <span className={'capitalize text-lg font-semibold'}>{cartItem.name}</span>
                      {
                        !!cartItem.variants &&
                          <div className={'flex items-center'}>
                            {
                              cartItem.variants.map((variant, index) => {
                                const sentence = index === 0 ?
                                  `${variant.type}: ${variant.kind}` :
                                  `, ${variant.type}: ${variant.kind}`
                                return (
                                  <span key={index} className={'capitalize'}>{sentence}</span>
                                )
                              })
                            }
                          </div>
                      }
                      <div className={'capitalize text-lg font-semibold flex items-center flex-no-wrap'}>
                        <span className={'mr-1'}>{`${Validator.tryGetValue(translationGeneral, 'qty', locale.languageCode)}: ${cartItem.qty}`}</span>
                        <BiPlus
                          onClick={handleClick('plus')(cartItemIndex)}
                          className={cx(
                            cartItem.qty < max && step < 2 ? 'hover:opacity-75' : 'pointer-events-none opacity-25',
                            'border mr-1 rounded text-2xl cursor-pointer'
                          )} />
                        <BiMinus
                          onClick={handleClick('minus')(cartItemIndex)}
                          className={cx(
                            cartItem.qty > 1 && step < 2 ? 'hover:opacity-75' : 'pointer-events-none opacity-25',
                            'border ml-1 rounded text-2xl cursor-pointer'
                          )} />
                      </div>
                      <span className={'capitalize text-lg font-semibold'}>{`$${cartItem.qty * cartItem.price}`}</span>
                    </div>
                  </div>
                  <Backorder className={'mb-2'} id={cartItem.id} variants={cartItem.variants} />
                </div>
              )
            })
          }
        </div>
        <div className={'flex flex-col'}>
          <div className={'flex justify-between'}>
            <span>{Validator.tryGetValue(translationGeneral, 'subtotal', locale.languageCode)}</span>
            <span>{`$ ${price}`}</span>
          </div>
          <div className={'flex flex-wrap md:flex-no-wrap justify-between'}>
            <span>{Validator.tryGetValue(translationGeneral, 'tax', locale.languageCode)}</span>
            <span className={'flex justify-end pl-2 text-right'}>{Validator.tryGetValue(translationGeneral, 'taxesAndDutiesAreNotIncluded', locale.languageCode)}</span>
          </div>
          <div className={'flex justify-between'}>
            <span>{Validator.tryGetValue(translationGeneral, 'shipping', locale.languageCode)}</span>
            <span>{!shippingPrice ? Validator.tryGetValue(translationGeneral, 'pleaseSelectTheShippingCountry', locale.languageCode) : `$ ${shippingPrice}`}</span>
          </div>
          <div className={'flex justify-between'}>
            <span>{Validator.tryGetValue(translationGeneral, 'total', locale.languageCode)}</span>
            <span>{`$ ${price + shippingPrice}`}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SummarizeCart
