import { RootState } from "../reducers";
import { CartState } from "../reducers/cart";
import { createSelector } from "reselect";
import {shippingPriceSelector} from "./shippingSelector";

export const cartSelector = (state: RootState): CartState => state.cart;

export const cartItemsSelector = createSelector(
  [cartSelector],
  cart => cart.items
);

export const cartItemSelector = (id: string) => createSelector(
  [cartItemsSelector],
  cartItems => {
    if (!cartItems || !cartItems.length) {
      return undefined
    }
    const filtered = cartItems.filter((cartItem) => cartItem.id === id)
    if (!filtered.length) {
      return undefined
    }
    return filtered[0]
  }
)

export const checkedCartItemsSelector = createSelector(
  [cartSelector],
  cart => cart.items.filter(cartItem => cartItem.checked)
);

export const cartWeightSumSelector = createSelector(
  [checkedCartItemsSelector],
  cart =>
    cart.map(c => c.weight * c.qty).reduce((prev, current) => prev + current, 0)
);

export const subTotalSelector = createSelector(
  [cartItemsSelector],
  cart =>
    cart.map(c => c.price * c.qty).reduce((prev, current) => prev + current, 0)
);

export const checkedSubTotalSelector = createSelector(
  [checkedCartItemsSelector],
  cart =>
    cart.map(c => c.price * c.qty).reduce((prev, current) => prev + current, 0)
);

export const canBuySelector = createSelector(
  [checkedCartItemsSelector],
  cartItems => cartItems.length > 0
);

export const totalSelector = createSelector(
  [subTotalSelector, shippingPriceSelector],
  (total: number, shippingPrice: number): number => total + shippingPrice
);

export const checkedTotalSelector = createSelector(
  [checkedSubTotalSelector, shippingPriceSelector],
  (total: number, shippingPrice: number): number => total + shippingPrice
);

export const productSummaryStrSelector = createSelector(
  [checkedCartItemsSelector],
  (items): string => {
    if (items.length === 1) {
      return items[0].name;
    }
    if (items.length === 2) {
      return items[0].name + " and 1 more item";
    }
    if (items.length > 2) {
      return items[0].name + " and " + (items.length - 1) + " more items";
    }
    return "No Item";
  }
);
