/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC, useState} from "react";
import {cx} from "emotion";
import FillAddress from "./FillAddress";
import AcceptTerms from "./AcceptTerms";
import DoPayment from "./DoPayment";
import {useDispatch, useSelector} from "react-redux";
import {subscriptionSelector} from "../../../store/selectors/subscriptionSelector";
import SubscribeRequest from "../../../firebase/SubscribeRequest";
import OnPaymentSuccess from "../../checkout/OnPaymentSuccess";
import {routerSelector} from "../../../store/selectors/routerSelector";

type Props = {
  className?:string,
}

const ProcessCheckout:FC<Props> = ({className=''}) => {
  const [step, setStep] = useState(0)
  const router = useSelector(routerSelector)
  const subscription = useSelector(subscriptionSelector)

  const onUpdate = (_step: number) => (e:any) => {
    setStep(_step)
  }

  const onComplete = (callback: any) => {
    if (subscription.active) {
      processSubscribe().then((res: any) => {
        !!callback && callback()
        setStep(4);
      });
    } else {
      !!callback && callback()
      setStep(4);
    }
  }

  const processSubscribe = async () => {
    try {
      return await SubscribeRequest.subscribe(subscription.email);
    } catch (e) {
      console.log(e);
    }
  };

  const redirect = () => {
    const url = 'https://shop.bhaptics.com/'
    window.open(url, '_self')
  }

  return (
    <div className={cx(className)}>
      <div className={'flex flex-col'}>
        <div className={'pb-2'}>
          <FillAddress
            onUpdate={onUpdate}
            active={step === 0}
            editable={step > 0}
            className={'flex bg-white px-1 lg:px-4 py-2 flex-col border rounded'} />
        </div>
        <div className={'pb-2'}>
          <AcceptTerms
            onUpdate={onUpdate}
            active={step === 1}
            editable={step > 1}
            className={'flex bg-white px-1 lg:px-4 py-2 flex-col border rounded'} />
        </div>
        <div className={'pb-2'}>
          <DoPayment
            onComplete={onComplete}
            active={step === 2}
            className={'flex bg-white px-1 lg:px-4 py-2 flex-col border rounded'} />
        </div>
        {
          step === 4 &&
          <OnPaymentSuccess
            redirectMessage={'Will redirect to the main page soon'}
            onClose={redirect} />
        }
      </div>
    </div>
  )
}

export default ProcessCheckout