import { createSelector } from "reselect";
import { RootState } from "../reducers";
import { Offer } from "../reducers/offer";

export const offerSelector = (state: RootState): Offer => state.offer;

export const offerShippingAddressSelector = createSelector(
  [offerSelector],
  offer => offer.shippingAddress
);

export const offerShippingCountrySelector = createSelector(
  [offerSelector],
  offer => (offer.shippingAddress ? offer.shippingAddress.country : undefined)
);

export const totalOfferItemsWeightSelector = createSelector(
  [offerSelector],
  offer =>
    offer.items.reduce((prev, current, index) => {
      prev += current.qty * current.weight;
      return prev;
    }, 0)
);
