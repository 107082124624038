/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC} from "react";
import {cx} from "emotion";
import icon from './images/noticeIcon.png'

type Props = {
  className?: string,
}

const sentences = [
  'Due to the ongoing worldwide supply chain delays, we are experiencing longer lead time than usual.\nPlease check the estimated shipping date for each product before placing your order.\nYour order will be shipped out in one shipment when all items in your order are back in stock.',
  'Typically delivered in 3-5 business days from the ship date',
  'The prices and shipping fees do not include import duty/tariff and GST/VAT applicable in your country.'
]

const Notifier:FC<Props> = ({className=''}) => {
  return (
    <div
      css={css`background-color: #0f1014;`}
      className={cx(className, 'w-full mx-auto')}
    >
      <div className={'max-w-screen-xl sm:px-12 xl:px-36 mx-auto'}>
        <div css={css`background-color: #1e1e21;`} className={'py-4 px-4 md:px-12 lg:py-10 flex items-center justify-center flex-wrap lg:flex-no-wrap'}>
          <img src={icon} />
          <div className={'ml-2 flex flex-col justify-center'}>
            <span className={'text-2xl md:text-3xl font-black mb-4 text-white'}>Shipping Delay Notice</span>
            {
              sentences.map((sentence, index) => {
                return (
                  <div className={'flex items-baseline mb-2'}>
                    <span
                      css={css`
                        min-width: .5rem;
                        min-height: .5rem;
                        background-image: linear-gradient(319deg, #750376 101%, #d31d2c -4%);
                      `}
                      className={'inline-block h-2 w-2 rounded-full'}
                    />
                    <span className={'text-sm leading-tight md:leading-none whitespace-normal lg:whitespace-pre-wrap md:text-base font-semibold text-white ml-2'}>{sentence}</span>
                  </div>
                )
              })
            }
          </div>
        </div>
        {/*<div
          css={css`background-color: #1e1e21;`}
          className={'flex items-center justify-center py-4 lg:py-10'}>
          <div className={'flex flex-wrap items-center justify-center'}>
            <img className={'mx-6'} src={icon} />
            <div className={'flex mx-5 flex-col justify-center'}>
              {
                sentences.map((sentence, index) => {
                  return (
                    <div key={index} className={'flex items-center'}>
                      <span
                        css={css`
                          min-width: .5rem;
                          min-height: .5rem;
                          background-image: linear-gradient(319deg, #750376 101%, #d31d2c -4%);
                        `}
                        className={'inline-block h-2 w-2 rounded-full'}
                      />
                      <span css={css`line-height: 1.25`} className={'text-sm font-semibold text-white ml-2'}>{sentence}</span>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>*/}
      </div>
    </div>
  )
}

export default Notifier