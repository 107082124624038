import React, {FC, useEffect, useState} from "react";
import {cx} from "emotion";
import Validator from "../../../utils/Validator";
import {css, jsx} from "@emotion/core";
import {Personal} from "../../../interfaces/personal";
import {useDispatch, useSelector} from "react-redux";
import {localeSelector} from "../../../store/selectors/configSelector";
import {translationGeneralSelector} from "../../../store/selectors/translationSelector";
import {updateRecipientPersonal} from "../../../store/actions/recipientAction";
import {updatePayerPersonal} from "../../../store/actions/payerAction";
import {subscriptionSelector} from "../../../store/selectors/subscriptionSelector";
import {updateSubscription} from "../../../store/actions/subscriptionAction";
import {recipientSelector} from "../../../store/selectors/recipientSelector";
import equal from "fast-deep-equal/es6";

type Props = {
  className?:string,
  useFor:'recipient'|'payer',
  personal:Personal,
}

const Input = (props: any) => {
  const {className, ...rest} = props
  return  (
    <input
      autoComplete={rest.name === 'confirmEmail' ? 'off' : 'on'}
      className={cx(className, 'appearance-none border rounded w-full py-2 px-3 focus:outline-none')}
      {...rest} />
  )
}

const alphanumeric = /^[a-z0-9 !@#$%^&*()\-_=+:;.`'",\t\|\\/?<>~[\]{}]+$|^$/i

const InputPersonal:FC<Props> = ({className="", personal, useFor}) => {
  const dispatch = useDispatch();
  const locale = useSelector(localeSelector)
  const recipient = useSelector(recipientSelector);
  const translationGeneral = useSelector(translationGeneralSelector)
  const subscription = useSelector(subscriptionSelector)

  const [state, setState] = useState({
    email: '',
    confirmEmail: '',
    firstName: '',
    lastName: '',
    tel: '',
  })

  useEffect(() => {
    setState((state:any) => ({...state, ...personal}))
  }, [])

  useEffect(() => {
    if (!equal(state, personal)) {
      dispatch(updateRecipientPersonal({...personal, ...state}))
      dispatch(updatePayerPersonal({...personal, ...state}))
      if (state.email === state.confirmEmail) {
        dispatch(updateSubscription({...subscription, email: state.email}))
      }
    }
  }, [state])

  const toggle = (e:any) => {
    const name = e.target.name
    if (name === 'subscription') {
      const active = e.target.checked;
      dispatch(updateSubscription({...subscription, active}))
    }
  }

  const handleChange = (e:any) => {
    const name = e.target.name
    const value = e.target.value
    if (name === "email") {
      setState((state:any) => ({...state, email: value}))
    }
    else if (name === 'confirmEmail') {
      setState((state:any) => ({...state, confirmEmail: value}))
    }
    else if (name === 'fname') {
      if (alphanumeric.test(value)) {
        setState((state:any) => ({...state, firstName: value}))
      }
    }
    else if (name === 'lname') {
      if (alphanumeric.test(value)) {
        setState((state:any) => ({...state, lastName: value}))
      }
    }
    else if (name === 'tel') {
      setState((state:any) => ({...state, tel: value}))
    }
  }

  const showBothEmailsNotMatched =
    !!recipient.personal.email &&
    !!recipient.personal.confirmEmail &&
    Validator.isValidEmail(recipient.personal.email) &&
    recipient.personal.email !== recipient.personal.confirmEmail

  return (
    <div className={cx(className, '')}>
      <div className={"flex flex-row px-4"}>
        <Input
          name={"email"}
          type={"email"}
          value={state.email}
          defaultValue={!personal.email ? undefined : personal.email}
          onChange={handleChange}
          placeholder={Validator.tryGetValue(translationGeneral, 'email', locale.languageCode)}
        />
      </div>
      <div className={"flex flex-col px-4 mt-3"}>
        <Input
          name={"confirmEmail"}
          type={"email"}
          value={state.confirmEmail}
          defaultValue={!personal.confirmEmail ? undefined : personal.confirmEmail}
          onChange={handleChange}
          placeholder={Validator.tryGetValue(translationGeneral, 'confirmEmail', locale.languageCode)}
        />
        <div
          className={cx(
            showBothEmailsNotMatched ? 'flex' : 'hidden',
            'mt-2 text-center w-full'
          )}
        >
          <span className={'text-red-700 italic'}>The email addresses you entered do not match.</span>
        </div>
      </div>
      <div
        className={cx(
          {'hidden': useFor === 'payer'},
          "flex items-center px-4 mt-3 py-1"
        )}
      >
        <input
          onClick={toggle}
          type="checkbox"
          name="subscription"
          defaultChecked={subscription.active}
          value={"subscription"}
        />
        <label className={"leading-tight ml-2"} htmlFor="subscription">
          {Validator.tryGetValue(translationGeneral, 'keepMeUpdatedAboutTheLatestContentList', locale.languageCode)}
        </label>
      </div>
      <div
        css={css`& > input:last-of-type {margin-right: 0;}`}
        className={"flex flex-row mt-3 px-4"}
      >
        <div className={"flex-1 mr-1"}>
          <Input
            name={"fname"}
            value={state.firstName}
            defaultValue={!personal.firstName ? undefined : personal.firstName}
            onChange={handleChange}
            placeholder={`${Validator.tryGetValue(translationGeneral, 'firstName', locale.languageCode)} ${locale.languageCode === 'en' ? '(In Roman letters)' : ''}`}
          />
        </div>
        <div className={"flex-1 ml-1"}>
          <Input
            name={"lname"}
            value={state.lastName}
            defaultValue={!personal.lastName ? undefined : personal.lastName}
            onChange={handleChange}
            placeholder={`${Validator.tryGetValue(translationGeneral, 'lastName', locale.languageCode)} ${locale.languageCode === 'en' ? '(In Roman letters)' : ''}`}
          />
        </div>
      </div>
      <div className={"mt-3 px-4"}>
        <Input
          name={"tel"}
          value={state.tel}
          defaultValue={!personal.tel ? undefined : personal.tel}
          onChange={handleChange}
          placeholder={`${Validator.tryGetValue(translationGeneral, 'phoneNumber', locale.languageCode)}`}
        />
      </div>
    </div>
  )
}

export default InputPersonal