/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC, forwardRef, useRef, useState } from "react";
import { ProductId, Variant } from "../../../models/Product";
import { useSelector } from "react-redux";
import { localeSelector } from "../../../store/selectors/configSelector";
import Validator from "../../../utils/Validator";
import Portal from "../../portal";
import styled from "@emotion/styled";
import { cx } from "emotion";
import TactalStandardSizeFacialInterface from "./etc/TactalStandardSizeFacialInteface";

type Props = {
  className?: string;
  children: any;
  onToggle: (e: any) => void;
  variant: Variant;
  id: string;
};

const details: {
  [id: string]: {
    [locale: string]: {
      [type: string]: {
        [kind: string]: string;
      };
    };
  };
} = {
  tactal: {
    en: {
      Size: {
        Standard:
          "Compatible with Valve Index*, HTC Vive, Oculus Quest 1*, Quest 2*, Rift CV1*, and Samsung Odyssey",
        Wide:
          "Compatible with HTC Vive Pro, Focus, Focus Plus, Pimax VR Series",
      },
    },
    kr: {
      Size: {
        Standard:
          "Compatible with Valve Index*, HTC Vive, Oculus Quest 1*, Quest 2*, Rift CV1*, and Samsung Odyssey",
        Wide:
          "Compatible with HTC Vive Pro, Focus, Focus Plus, Pimax VR Series",
      },
    },
    cn: {
      Size: {
        Standard:
          "Compatible with Valve Index*, HTC Vive, Oculus Quest 1*, Quest 2*, Rift CV1*, and Samsung Odyssey",
        Wide:
          "Compatible with HTC Vive Pro, Focus, Focus Plus, Pimax VR Series",
      },
    },
    jp: {
      Size: {
        Standard:
          "Compatible with Valve Index*, HTC Vive, Oculus Quest 1*, Quest 2*, Rift CV1*, and Samsung Odyssey",
        Wide:
          "Compatible with HTC Vive Pro, Focus, Focus Plus, Pimax VR Series",
      },
    },
  },
};

const DetailVariant: FC<any> = forwardRef(
  ({ id, variant, className = "", children, onToggle }: Props, ref: any) => {
    const locale = useSelector(localeSelector);
    const notice = Validator.tryGetValue(
      details,
      id,
      locale.languageCode,
      variant.type,
      variant.kind
    );

    if (!notice) {
      return null;
    }

    const isWide = variant.kind === "Wide";
    
    const TriangleDown = isWide
      ? styled.div`
          width: 0;
          height: 0;
          margin-right: 155px;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid red;
        `
      : styled.div`
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid red;
        `;

    return (
      <Portal
        style={""}
        css={
          isWide
            ? css`
                position: absolute !important;
                bottom: 100%;
                left: 200%;
                right: 0;
              `
            : css`
                position: absolute !important;
                bottom: 100%;
                left: 0;
                right: 0;
              `
        }
        className={"flex z-20 mb-1.5 flex-col items-center"}
        root={ref.current}
      >
        <div
          css={css`
            left: 0.75rem;
          `}
          className={"relative"}
        >
          <div
            css={css`
              max-width: 22.5rem;
              min-width: 14rem;
            `}
            className={
              "absolute bottom-0 right-0 flex flex-col bg-black border border-red-600 rounded-sm"
            }
          >
            <div className={"flex w-full justify-end items-center bg-red-700"}>
              <button
                onClick={onToggle}
                className={"cursor-pointer hover:opacity-75 mr-2"}
              >
                X
              </button>
            </div>
            <span className={cx("block w-full px-4 py-2 text-white")}>
              {notice}
            </span>
            {id === "tactal" &&
              variant.type === "Size" &&
              variant.kind === "Standard" && (
                <TactalStandardSizeFacialInterface />
              )}
          </div>
        </div>
        <TriangleDown />
      </Portal>
    );
  }
);

export default DetailVariant;
