/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC} from "react";
import {Product} from "../../models/Product";
import {cx} from "emotion";
import ProductCard from "./productCard";

type Props = {
  className?:string,
  products:Product[]
}

const DisplayProducts:FC<Props> = ({className='', products}) => {
  return (
    <div className={cx(className, 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-x-7.5 gap-y-7.5')}>
      {
        products.map((product, index) => {
          return (
            <div
              css={css`border-color: #4e4e51;`}
              key={index} className={'border'}
            >
              <ProductCard product={product} />
            </div>
          )
        })
      }
    </div>
  )
}

export default DisplayProducts