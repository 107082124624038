/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC} from "react";
import {cx} from "emotion";

type Props = {
  className?: string,
  name?:string
}

const Copyright:FC<Props> = ({className='', name='bHaptics Inc.'}) => {
  return (
    <span className={cx(className, "text-center select-none text-white")}>
      &copy; {new Date().getFullYear()} {name}
    </span>
  )
}

export default Copyright