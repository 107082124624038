import React, {FC} from "react";
import {cx} from "emotion";

type Props = {
  className?:string,
  label?:string,
  href?: string
}

const ContactButton:FC<Props> = ({className='', label='bHaptics Support Team', href='mailto:contact@bhaptics.com'}) => {
  return (
    <a className={cx(className)} href={href}>
      <span>{label}</span>
    </a>
  )
}

export default ContactButton