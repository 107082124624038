/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";
import {useSelector} from "react-redux";
import {localeSelector} from "../../../store/selectors/configSelector";
import {cx} from "emotion";
import {translationProductSpecsSelector} from "../../../store/selectors/translationSelector";
import Validator from "../../../utils/Validator";

type Props = {
  className?:string,
  id: string
};

const ProductSpecSheet: FC<Props> = ({className='', id}) => {
  const translationProductSpecs = useSelector(translationProductSpecsSelector)
  const locale = useSelector(localeSelector)
  const specs:{[key: string]: any}[] = Validator.tryGetValue(translationProductSpecs, id)

  return (
    <div
      css={css`
        & * {user-select: none;}
        background-color: #1e1e21;
      `}
      className={cx(className, {'hidden': !specs}, "py-2 px-5")}
    >
      <table className="shadow-xl rounded w-full">
        <tbody className={""}>
          {!!specs &&
            specs.map((spec, index: number) => (
              <tr
                css={css`
                  display: flex;
                  align-items: flex-start;
                  line-height: 1.25;
                  & > td:first-of-type {width: 164px}
                  @media(max-width: 767px) {
                    line-height: 1.25;
                    & > td:first-of-type {width: 33%}
                    & > td:nth-of-type(2) {width: 66%;}
                  }
                  color: #979797;
                `}
                className={'py-1'}
                key={index}
              >
                <td className="pr-4 font-bold">{spec[`label_${locale.languageCode}`]}</td>
                <td className="">
                  <span className={"whitespace-pre-line font-normal"}>
                    {" "}
                    {spec[`description_${locale.languageCode}`]}
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductSpecSheet;