/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useEffect, Fragment} from "react";
import {cx} from "emotion";
import Validator from "../../../utils/Validator";
import {useDispatch, useSelector} from "react-redux";
import {localeSelector} from "../../../store/selectors/configSelector";
import {translationGeneralSelector} from "../../../store/selectors/translationSelector";
import {recipientSelector} from "../../../store/selectors/recipientSelector";
import {ContinueButton} from "../../checkout/Common";
import Shipping from "../../../firebase/Shipping";
import {updateCountries} from "../../../store/actions/countriesAction";
import SummarizePersonal from "../../checkout/SummarizePersonal";
import SummarizeAddress from "../../checkout/SummarizeAddress";
import {shippingPriceSelector, shippingSelector} from "../../../store/selectors/shippingSelector";
import InputPersonal from "./InputPersonal";
import InputAddress from "./InputAddress";

type Props = {
  className?:string,
  editable: boolean,
  active: boolean,
  onUpdate: (stepIndex: number) => (e:any) => void
}

const FillAddress:FC<Props> = ({className='', editable, active, onUpdate}) => {
  const dispatch = useDispatch()
  const locale = useSelector(localeSelector)
  const translationGeneral = useSelector(translationGeneralSelector)
  const recipient = useSelector(recipientSelector);
  const shipping = useSelector(shippingSelector)
  const {address2, ...rest} = {...recipient.address, ...recipient.personal}
  const flattened = Validator.flatten(rest)
  const fulfilled = flattened.filter((value:string) => !value).length === 0
  const validEmail = Validator.isValidEmail(rest.email) && (rest.email === rest.confirmEmail)

  useEffect(() => {
    Shipping.getCountries().then((countries) => {
      dispatch(updateCountries(countries))
    });
  }, []);

  const handleContinue = async (e:any) => {
    onUpdate(1)(e)
  }

  return (
    <div className={cx(className)}>
      <div className={'flex items-center justify-between'}>
        <span className={'text-xl font-semibold'}>{Validator.tryGetValue(translationGeneral, 'address', locale.languageCode)}</span>
        <button
          onClick={onUpdate(0)}
          className={cx(
            editable ? 'opacity-100 hover:opacity-75' : 'pointer-events-none opacity-0',
            'border rounded px-2 py-1')}
        >
          <span>Edit</span>
        </button>
      </div>
      <span className={'font-semibold my-2'}>
        {Validator.tryGetValue(translationGeneral, 'shipTo', locale.languageCode)}
      </span>
      {
        active &&
          <Fragment>
            <form className={'mt-2'}>
              <InputPersonal useFor={'recipient'} personal={recipient.personal} />
              <InputAddress useFor={'recipient'} address={recipient.address} />
            </form>
            <div className={'px-4'}>
              <ContinueButton
                className={cx(!fulfilled || !validEmail ? 'pointer-events-none opacity-25' : 'hover:opacity-75', "cursor-pointer mt-3 py-2")}
                onClick={handleContinue}
              >
                {Validator.tryGetValue(translationGeneral, 'continue', locale.languageCode)}
              </ContinueButton>
            </div>
          </Fragment>
      }
      {
        editable &&
          <Fragment>
            <div className={'mt-2'}>
              <SummarizePersonal personal={recipient.personal} />
              <SummarizeAddress address={recipient.address} />
              <div className={"my-3 px-4 flex items-center"}>
                <span className={"my-2 font-medium text-gray-800"}>
                  {`Shipping Price: $${(+shipping.price).toFixed(2)}`}
                </span>
              </div>
            </div>
          </Fragment>
      }
    </div>
  )
}

export default FillAddress