/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC} from "react";
import {useSelector} from "react-redux";
import {translationGeneralSelector} from "../../../store/selectors/translationSelector";
import {cx} from "emotion";
import {localeSelector} from "../../../store/selectors/configSelector";
import Validator from "../../../utils/Validator";

type Props = {
  className?:string,
  id: string,
}

const Backorders:FC<Props> = ({id, className=''}) => {
  const translationGeneral = useSelector(translationGeneralSelector)

  const backorders: {[key:string]: {name:string, date_en:string, date_cn:string, date_jp:string, date_kr:string}[]} = {
    'tactsuit-x40': [{
      'name': '',
      'date_en': 'Jun 04th',
      'date_cn': '06月04日',
      'date_jp': '06月04日',
      'date_kr': 'Jun 04th',
    }],
    'tactosy-for-arms': [{
      'name': '',
      'date_en': 'May 18th',
      'date_cn': '05月18日',
      'date_jp': '05月18日',
      'date_kr': 'May 18th',
    }],
    'tactosy-for-feet': [{
      'name': '',
      'date_en': 'May 18th',
      'date_cn': '05月18日',
      'date_jp': '05月18日',
      'date_kr': 'May 18th',
    }],
    'tactosy-for-hands': [{
      'name': '',
      'date_en': 'May 18th',
      'date_cn': '05月18日',
      'date_jp': '05月18日',
      'date_kr': 'May 18th',
    }],
    'tactsuit-x16': [{
      'name': '',
      'date_en': 'May 18th',
      'date_cn': '05月18日',
      'date_jp': '05月18日',
      'date_kr': 'May 18th',
    }],
    'tactal': [
      {
        'name': 'Standard size -',
        'date_en': 'May 18th',
        'date_cn': '05月18日',
        'date_jp': '05月18日',
        'date_kr': 'May 18th',
      },
      {
        'name': 'Wide size -',
        'date_en': 'May 18th',
        'date_cn': '05月18日',
        'date_jp': '05月18日',
        'date_kr': 'May 18th',
      }
    ],
  }

  const locale = useSelector(localeSelector)
  let text = Validator.tryGetValue(translationGeneral, 'estimatedShipDate', locale.languageCode)
  if (!text) {
    text = 'Estimated Ship Date'
  }

  return (
    !!backorders[id] ?
      <div
        css={css`color:#60a5fa`}
        className={cx(className, 'flex flex-col')}>
        <span className={'block text-sm font-bold'}>{`* ${text}`}</span>
        {
          backorders[id].map((backorder, index: number) => {
            const name = backorder.name
            //@ts-ignore
            const date = backorder[`date_${locale.languageCode}`]
            return (
              <div key={index} className={'flex ml-2.5 leading-4 text-xs flex-no-wrap'}>
                {!!name && <span>{name}</span>}
                <span>{date}</span>
              </div>
            )
          })
        }
      </div>
      :
      null
  )
}

export default Backorders
