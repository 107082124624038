import produce from "immer";
import CartLocalStorageService from "storage/CartLocalStorageService";
import {ProductId, Variant} from "../../models/Product";

export interface Item {
  id: string | ProductId;
  name: string;
  price: number;
  weight: number;
  variants?: Variant[]
}

export interface CartItem extends Item {
  checked: boolean;
  category: string;
  qty: number;
  hsCode: string;
}

export type CartState = {
  items: CartItem[];
};

const initialState = {
  items: CartLocalStorageService.getCart()
};

export enum CartActionType {
  UpdateCartItems = "cart/UpdateCartItems",
  FetchCartItems = "cart/FetchCartItems"
}

type CartAction =
  {type: CartActionType.FetchCartItems, payload: CartItem[]} |
  {type: CartActionType.UpdateCartItems, payload: CartItem[]}

export const cart = produce(
  (draft: CartState = initialState, action: CartAction) => {
    switch (action.type) {
      case CartActionType.UpdateCartItems: {
        draft.items = action.payload;
        return draft;
      }
      case CartActionType.FetchCartItems: {
        draft.items = action.payload;
        return draft;
      }
      default:
        return draft;
    }
  }
);
