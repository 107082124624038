import tactsuitX40 from "../images/tactsuit-x40.png";
import tactsuitX16 from "../images/tactsuit-x16.png";
import tactotForBackpackPc from "../images/tactot-for-backpack-pc.png";
import tactal from "../images/tactal.png";
import innerLiningForTactosyForArms from "../images/inner-lining-for-tactosy-for-arms.png";
import tactosyForArms from "../images/tactosy-for-arms.png";
import tactosyForFeet from "../images/tactosy-for-feet.png";
import tactosyForHands from "../images/tactosy-for-hands.png";
import meshLiningForTactsuitX from "../images/mesh-lining-for-tactsuit-x.png";
import tactsuitXAudioAccessories from '../images/tactsuit-x-audio-accessories.png'
import {ProductId} from "../../../../models/Product";

interface YouMayLikeItem {
  productId: ProductId;
  src: string;
  to: string;
  ranking: number;
}

export const youMayLikeData: YouMayLikeItem[] = [
  {
    productId: ProductId.TactsuitX40,
    src: tactsuitX40,
    to: `tactsuit-x40`,
    ranking: 1
  },
  {
    productId: ProductId.TactsuitX16,
    src: tactsuitX16,
    to: `tactsuit-x16`,
    ranking: 2
  },
  {
    productId: ProductId.TactotForBackPackPc,
    src: tactotForBackpackPc,
    to: `tactot-for-backpack-pc`,
    ranking: 3
  },
  {
    productId: ProductId.Tactal,
    src: tactal,
    to: `tactal`,
    ranking: 4
  },
  {
    productId: ProductId.TactosyForArms,
    src: tactosyForArms,
    to: `tactosy-for-arms`,
    ranking: 5
  },
  {
    productId: ProductId.TactosyForHands,
    src: tactosyForHands,
    to: `tactosy-for-hands`,
    ranking: 6
  },
  {
    productId: ProductId.TactosyForFeet,
    src: tactosyForFeet,
    to: `tactosy-for-feet`,
    ranking: 7
  },
  {
    productId: ProductId.TactsuitXAudioAccessories,
    src: tactsuitXAudioAccessories,
    to: `tactsuit-x-audio-accessories`,
    ranking: 8
  },
  {
    productId: ProductId.MeshLiningForTactsuitX,
    src: meshLiningForTactsuitX,
    to: `mesh-lining-for-tactsuit-x`,
    ranking: 9
  },
  {
    productId: ProductId.InnerLiningForTactosyForArms,
    src: innerLiningForTactosyForArms,
    to: `inner-lining-for-tactosy-for-arms`,
    ranking: 10
  }
];