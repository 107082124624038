import { RootState } from "../reducers";
import { RouterState } from "connected-react-router";
import { createSelector } from "reselect";

export const routerSelector = (state: RootState): RouterState => state.router;

export const locationSelector = createSelector(
  [routerSelector],
  router => router.location
);
