/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useState} from "react";
import {MdClose} from "react-icons/md";
import sendEmailIcon from "./images/sendEmailIcon.png";
import {FaCheck} from 'react-icons/fa'
import {cx} from "emotion";
import Validator from "../../utils/Validator";
import OrdersRequest from "../../firebase/OrdersRequest";
import {useDispatch} from "react-redux";
import {startLoading, stopLoading} from "../../store/actions/loadingAction";

type Props = {
  className?:string
  orderId:string,
  trackingId:string,
  onClose:(e:any) => void
}

const Input = (props:any) => {
  const {className, ...rest} = props
  return (
    <input
      {...rest}
      className={cx(
        className,
        'text-whit appearance-none rounded-none w-full py-2 px-3 focus:outline-none',
      )}
      css={css`
        background-color: #0f1014;
        border: solid 1px #505050;
        &:active, &:focus {
          background: #0f1014;
        }
      `}
    />
  )
}

const SendAnEmail:FC<Props> = ({onClose, className='', orderId, trackingId}) => {
  const dispatch = useDispatch()

  const [state, setState] = useState<{
    email: string
  }>({
    email: ''
  })

  const handleChange = (e: any) => {
    const name = e.target.name
    const value = e.target.value
    setState((state: any) => ({
      ...state,
      [name]: value
    }))
  }

  const sendEmail = async (e:any) => {
    e.preventDefault()
    dispatch(startLoading)
    OrdersRequest.sendEmail(state.email, orderId, 'orderReceived').then((res: boolean) => {
      if (res) {
        dispatch(stopLoading)
        onClose(e)
      }
      else {
        // todo - improve alert
        window.alert('Send order summary are failed. Please try again or contact support@bhatpics.com if needed...')
      }
    })
  }

  return (
    <div
      css={css`
        background: rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(1px);
      `}
      className={"z-50 fixed inset-0 w-full h-full flex items-center justify-center"}
    >
      <div
        css={css`background-color: #1e1e21;`}
        className={
          "max-w-screen-sm whitespace-pre-wrap w-full flex flex-col rounded text-white items-center justify-center pb-12 pt-5 px-8"
        }
      >
        <div className={'w-full flex justify-end items-center'}>
          <MdClose onClick={onClose} className={'hover:opacity-75 cursor-pointer text-white text-3xl'} />
        </div>
        <div className={'w-full flex items-center justify-center'}>
          <img src={sendEmailIcon}/>
        </div>
        <div
          css={css`& * {color: #6d7278}`}
          className={'flex w-full px-4 mt-2 flex-wrap items-center justify-center text-center'}>
          <span>Send the order summary to your e-mail address.</span>
          <form
            css={css`
              input::placeholder {
                color: #505050;
                opacity: 1;
              }
            `}
            className={'mt-6 w-full flex px-4'}>
            <Input
              defaultValue={state.email}
              name={'email'}
              onChange={handleChange}
              placeholder={'bhaptics@example.com'}
            />
            <button
              onClick={sendEmail}
              className={cx(
                Validator.isValidEmail(state.email) ? '' : 'pointer-events-none',
                'hover:opacity-75 ml-2 px-2 py-1'
              )}
            >
              <FaCheck
                css={css`path {color: ${Validator.isValidEmail(state.email) ? '#f7b500' : ''}}`}
                className={'text-2xl'} />
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SendAnEmail