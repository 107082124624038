import produce from "immer";

export interface ShippingState {
  courier: 'ups' | 'dhl' | 'fedex' | 'postOffice'
  price: number,
  zone: number
}

const initialState:ShippingState = {
  courier: 'ups',
  price: 0,
  zone: 0
}

export enum ShippingActionType {
  UpdateShipping = "shipping/updateShipping",
  UpdateShippingPrice = "shipping/updateShippingPrice",
  UpdateShippingCourier = "shipping/updateShippingCourier",
}

type ShippingAction =
  | {type: ShippingActionType.UpdateShipping, payload: ShippingState}
  | {type: ShippingActionType.UpdateShippingCourier, payload: {courier: 'ups' | 'dhl' | 'fedex' | 'postOffice'}}
  | {type: ShippingActionType.UpdateShippingPrice, payload: {price: number}}

export const shipping = produce(
  (draft: ShippingState=initialState, action: ShippingAction) => {
    switch (action.type) {
      case ShippingActionType.UpdateShipping: {
        draft = action.payload
        return draft
      }
      case ShippingActionType.UpdateShippingCourier: {
        draft.courier = action.payload.courier
        return draft
      }
      case ShippingActionType.UpdateShippingPrice: {
        draft.price = action.payload.price
        return draft
      }
      default:
        return draft;
    }
  }
)