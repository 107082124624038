/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { FC } from "react";
import {cx} from "emotion";
import Validator from "../../../utils/Validator";
import {useSelector} from "react-redux";
import {translationProductDetailsSelector} from "../../../store/selectors/translationSelector";
import {localeSelector} from "../../../store/selectors/configSelector";

type Props = {
  className?:string,
  id: string
};

const ProductDescSheet: FC<Props> = ({className, id}) => {
  const locale = useSelector(localeSelector);
  const translationProductDetails = useSelector(translationProductDetailsSelector)
  const desc = Validator.tryGetValue(translationProductDetails, id, `description_${locale.languageCode}`)

  return (
    <div
      css={css`
        & * {user-select: none;}
        background-color: #1e1e21;
      `}
      className={cx(className, {'hidden': !desc}, "py-2 px-5")}
    >
      <div
        css={css`color: #979797;`}
        className="shadow-xl rounded w-full"
      >
        {!!desc && <span className={`pr-4 py-1 whitespace-pre-wrap leading-none font-normal`}>{desc}</span>}
      </div>
    </div>
  );
};

export default ProductDescSheet;