import Env from "../env/Env";
import {Variant} from "../models/Product";

interface PaymentwallParam {
  items: {
    id: string,
    name: string,
    variants?: Variant[]
    price: number,
    qty: number,
    hsCode: string,
    weight: number,
  }[],
  shippingPrice: number,
  shippingAddress: {
    address1: string,
    address2: string,
    zip: string,
    city: string,
    state: string,
    country: string,
    email: string,
    firstName: string,
    lastName: string,
    tel?: string,
  },
  discountPrice: number
}

const successCallback = (onSuccess: (data: any) => void) => (event: any) => {
  if (event.origin !== "https://api.paymentwall.com") return;
  const eventData = JSON.parse(event.data);
  if (eventData.event === "paymentSuccess") {
    onSuccess(event);
    return;
  }
};

class PaymentwallRequest {
  public getWidget = async (
    request: any,
    onSuccess: (data: any) => void
  ): Promise<{ url: string; callbackRef: any }> => {
    const callbackRef = successCallback(onSuccess);
    const res = await fetch(`${Env.ApiUrl}/paymentwall/widget`, {
      method: "POST",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json"
      }
    });

    const json = await res.json();
    window.addEventListener("message", callbackRef, false);
    return { url: json.data, callbackRef };
  };

  public getWidgetV2 = async (
    param: PaymentwallParam,
    onSuccess: (data: any) => void
  ): Promise<{url:string; callbackRef?:any, statusText?:string}> => {
    const callbackRef = successCallback(onSuccess);
    const res = await fetch(`${Env.ApiUrl}/paymentwallV2/widget`, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (!res.ok) {
      return {url:'', statusText: res.statusText}
    }
    const json = await res.json()
    window.addEventListener("message", callbackRef, false);
    return { url: json.url, callbackRef };
  };
}

export default new PaymentwallRequest();
