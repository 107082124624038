/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useEffect, useRef, useState} from "react";
import {FiChevronLeft, FiChevronRight} from 'react-icons/fi'
import Slider from "react-slick";
import {cx} from "emotion";
import desktopCes from './images/desktop/ces.png'

const Chevron = (props: any) =>
  props.rtl ?
    <FiChevronRight
      onClick={props.onClick}
      className={cx(props.className, 'w-12 z-10 h-12 text-white opacity-50 hover:opacity-100 cursor-pointer')}
    />
    :
    <FiChevronLeft
      onClick={props.onClick}
      className={cx(props.className, 'w-12 z-10 h-12 text-white opacity-50 hover:opacity-100 cursor-pointer')}
    />

type Props = {
  className?:string,
  slides: {srcs: string[], id: string}
}

const ProductSlider:FC<Props> = ({className='', slides}) => {
  const ref = useRef<any>(null)
  const cesable = ['tactsuit-x40'].includes(slides.id)
  const from = 'December 15, 2020 15:00:00 UTC';
  let single = slides.srcs.length === 1

  const [state, setState] = useState({
    index: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    slides: 2
  });

  const pages = Math.ceil(state.slides / state.slidesToScroll);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0
        }
      }
    ],
    beforeChange: (currentSlide: number, nextSlide: number) => {
      if (state.index !== nextSlide) {
        setState(state => ({ ...state, index: nextSlide }));
      }
    },
    onInit: () => {
      if (!!ref && !!ref.current) {
        console.log(ref.current.props);
      }
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResizeEvent);
    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, []);

  useEffect(() => {
    initialize();
  }, [ref.current]);

  const initialize = () => {
    if (ref.current) {
      const {
        state: { breakpoint },
        props
      } = ref.current;
      const { children } = props;
      if (breakpoint === null) {
        const { slidesToShow, slidesToScroll } = props;
        setState(state => ({
          ...state,
          slidesToShow,
          slidesToScroll,
          slides: children.length
        }));
      } else {
        const responsive = props.responsive.filter(
          (item: any) => item.breakpoint === breakpoint
        )[0];
        const { slidesToShow, slidesToScroll } = responsive.settings;
        setState(state => ({
          ...state,
          slidesToShow,
          slidesToScroll,
          slides: children.length
        }));
      }
      ref.current.slickGoTo(0);
    }
  };

  const handleResizeEvent = () => initialize();

  const handleLeft = (e: any) => ref.current && ref.current.slickPrev();

  const handleRight = (e: any) => ref.current && ref.current.slickNext();

  return (
    <div
      css={css`border-color: #7d7d7d;background-color: #1e1e21;`}
      className={cx(className, 'flex w-full border relative items-center')}
    >
      {
        cesable &&
        (Date.now() > Date.parse(from)) &&
        <img src={desktopCes} className={'w-12 z-10 absolute top-0 left-0 mt-2 ml-2'} />}
      <Chevron
        onClick={handleLeft}
        className={cx('left-0 absolute', {'hidden': single})}
      />
      <Slider
        className={'w-full'}
        css={css`
          & .slick-arrow {visibility: hidden; display: none !important;}
          & .slick-track {height: 400px;}
          & .slick-slide > div {height: 100%}
        `}
        ref={ref} {...settings}
      >
        {
          slides.srcs.map((src, index) => {
            return (
              <div
                css={css`display: flex!important;`}
                key={index} className={'h-full flex justify-center items-center relative'}>
                <img className={'h-full py-6 object-cover'} src={src} />
              </div>
            )
          })
        }
      </Slider>
      <Chevron
        rtl={true}
        onClick={handleRight}
        className={cx('right-0 absolute', {'hidden': single})}
      />
    </div>
  )
}

export default ProductSlider