/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC} from "react";
import {useDispatch, useSelector} from "react-redux";
import {locationSelector} from "../../store/selectors/routerSelector";
import {cartItemsSelector} from "../../store/selectors/cartSelector";
import {CartItem} from "../../store/reducers/cart";
import {Routes} from "../../models/Enums";
import {updateNotification} from "../../store/actions/notificationAction";
import {push} from "connected-react-router";
import {cx} from "emotion";
import cartIcon from "./images/cartIcon.png";

type Props = {
  className?:string
}

const CartIcon:FC<Props> = ({className=''}) => {
  const dispatch = useDispatch();
  const location = useSelector(locationSelector)
  const cartItems = useSelector(cartItemsSelector);

  const cartItemsQty = cartItems.reduce(
    (prev: number, current: CartItem, index: number) => prev + current.qty,
    0
  );

  const toCartPage = (e: any) => {
    dispatch(push(Routes.CartPage));
  }

  const handleClick = (e: any) => {
    if (!!cartItems.length && location.pathname === Routes.IndexPage) {
      const nonAccessories = cartItems.filter((item, index) => item.category !== 'accessory')
      if (!nonAccessories.length) {
        dispatch(updateNotification({type: 'notAccessoriesOnly', on:true}))
        return;
      }
      else {
        toCartPage(e)
      }
    }
    else {
      toCartPage(e)
    }
  };

  return (
    <div
      className={cx(className, "flex justify-center cursor-pointer items-center opacity-50 hover:opacity-100")}
      onClick={handleClick}
    >
      <div className={'relative'}>
        <img src={cartIcon} />
        <div
          css={css`background-color: rgb(109, 212, 0); left: 50%; bottom: 50%`}
          className={cx(
            {'hidden': !cartItemsQty},
            'absolute flex rounded-full items-center justify-center w-5 h-5'
          )}
        >
          <span className={'text-xs'}>{cartItemsQty}</span>
        </div>
      </div>
    </div>
  )
}

export default CartIcon