import firebase from "firebase/app";
import "firebase/analytics";
import Env from "../env/Env";

class GaUtils {
  public analytics?: firebase.analytics.Analytics;

  public initialize() {
    firebase.initializeApp(Env.FirebaseConf);
    this.analytics = firebase.analytics();
  }

  public logEvent = (eventName: string) => {
    if (!!this.analytics) {
      this.analytics.logEvent(eventName)
    }
  };

  public updateCart = (items: any) => {
    console.log('addToCart', this.analytics)
    if (!!this.analytics) {

      this.analytics.logEvent('add_to_cart', {items: items, currency: '$'})
    }
  };

  public removeCart = (items?: any) => {
    if (!!this.analytics) {

      this.analytics.logEvent('remove_from_cart', {currency: '$', items})
    }
  };

  public log = (eventName: string, param: any) => {
    if (!!this.analytics) {
      this.analytics.logEvent(eventName, param)
    }
  };

  public logException = (e: any) => {
    if (!!this.analytics) {
      this.analytics.logEvent('exception', {description: e.message})
    }
  };
}

export default new GaUtils();
