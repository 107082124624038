import Loading from "components/loading";
import React, { useEffect } from "react";

function NewShopOfferPage() {
  const query = window.location.pathname.split("/");

  useEffect(() => {
    window.location.href = `https://bhaptics.com/offer?id=${query[2]}`;
  }, []);

  return <Loading />;
}

export default NewShopOfferPage;
