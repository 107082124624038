export enum TrackingState {
  orderPlaced = 'orderPlaced',
  packing = 'packing',
  shipped = 'shipped',
  // delivering = 'delivering',
  // delivered = 'delivered',
}

export interface _Tracking {
  orderId: string,
  state: TrackingState,
  id:string,
  shippingMillis?: number
  shippingDate?: string,
  trackingNumber?: string,
  trackingUrl?: string,
  createdMillis: number,
  updatedMillis: number,
}

const initialState: _Tracking = {
  orderId: '',
  state: TrackingState.orderPlaced,
  id: '',
  shippingMillis: 0,
  shippingDate: '',
  trackingNumber: '',
  trackingUrl: '',
  createdMillis: 0,
  updatedMillis: 0,
}

export class Tracking implements _Tracking{
  orderId = ""
  state = TrackingState.orderPlaced
  id = ''
  shippingMillis:number|undefined = 0
  trackingNumber:string|undefined = ''
  trackingUrl:string|undefined = ''
  shippingDate:string|undefined = ''
  createdMillis = 0
  updatedMillis = 0

  constructor(tracking?: _Tracking) {
    if (!tracking) {
      this.initialize(initialState)
    }
    else {
      this.initialize(tracking)
    }
    delete this.initialize
  }

  public initialize:(tracking: _Tracking) => void = (tracking) => {
    const {orderId} = tracking
    this.orderId = orderId
    this.state = tracking.state;
    this.id = tracking.id;
    this.shippingMillis = 'shippingMillis' in tracking ? tracking.shippingMillis : 0;
    this.trackingNumber = 'trackingNumber' in tracking ? tracking.trackingNumber : '';
    this.trackingUrl = 'trackingUrl' in tracking ? tracking.trackingUrl : '';
    this.shippingDate = 'shippingDate' in tracking ? tracking.shippingDate : '';
    this.createdMillis = tracking.createdMillis
    this.updatedMillis = tracking.updatedMillis
  }
}