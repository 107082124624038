/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import * as React from "react";
import img from "./paymentwall.png";
import paymentwall from "./images/paymentwall.png";
import {cx} from "emotion";

type Props = {
  onClick: (e: any) => void;
  className?: string,
};

const PaymentwallButton: React.FC<Props> = ({className='', onClick }) => {
  return (
    <div className={cx(className, "flex flex-col flex-nowrap justify-center items-center mt-2")}>
      <div
        css={css`height: 40px;`}
        onClick={onClick}
        className={"bg-gray-200 h-12 hover:opacity-75 flex flex-row justify-center flex-nowrap rounded cursor-pointer w-full"}
      >
        <img
          className={"object-contain h-full object-center w-full rounded "}
          alt={""}
          src={paymentwall}
        />
      </div>
    </div>
  );
};

export default PaymentwallButton;
