import { Offer, OfferActionType } from "../reducers/offer";

export const fetchOffer = (offer: Offer) => (dispatch: any, getState: any) => {
  dispatch({ type: OfferActionType.FetchOffer, payload: offer });
};

export const updateOfferShippingAddress = (address: any) => (
  dispatch: any,
  getState: any
) => {
  dispatch({ type: OfferActionType.UpdateShippingAddress, payload: address });
};

export const updateOffer = (offer: Offer) => (dispatch: any, getState: any) => {
  dispatch({ type: OfferActionType.UpdateOffer, payload: offer });
};
