/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import * as React from "react";
import {FC, useEffect, useState} from "react";
import desktopBg from "./images/desktopBg.jpg";
import mobileBg from "./images/mobileBg.jpg";

type Props = {};

const Intro: FC<Props> = () => {
  const slogan = 'Time to\nFeel the Future'
  return (
    <div className={"w-full bg-white mx-auto"}>
      <div
        css={css`
          font-family: Gilroy;
          background-image: url(${desktopBg});
          @media(max-width: 639px) {
            background-image: url(${mobileBg});
            height: 25rem;
          }
          height: 21.25rem;
        `}
        className={"max-w-screen-xl sm:px-12 xl:px-36 bg-cover relative bg-no-repeat bg-center w-full mx-auto"}
      >
        <div className={'flex h-full flex-col sm:flex-row items-center'}>
          <div className={'text-center sm:text-left'}>
            <div className={'relative z-10 pt-10 sm:pt-0 '}>
              <span
                css={css`
                font-size: 2.5rem;
                line-height: 1.08;
                @media(min-width: 1024px) {
                  font-size: 3.5rem;
                  line-height: 1.02;
                }
              `}
                className={'z-10 font-extrabold whitespace-pre-wrap'}
              >
              {slogan}
            </span>
            </div>
            <div className={'relative'}>
              <span
                css={css`
                  height: 1.125rem;
                  @media(max-width: 639px) {height: 0.875rem}
                  background-color: #ffcc00;
                `}
                className={'absolute bottom-0 left-0 flex w-full inline-block'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
