/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, { FC, useEffect, useState } from "react";
import {
  addCartItem,
  updateCartItemV2
} from "../../../store/actions/cartAction";
import { useDispatch, useSelector } from "react-redux";
import { importFiles } from "../../../utils/CommonUtil";
import {cartItemsSelector} from "../../../store/selectors/cartSelector";
import CartUtil from "../../../utils/CartUtil";
import {push} from "connected-react-router";
import {Product, Variant} from "../../../models/Product";
import {Routes} from "../../../models/Enums";
import Parser from "../../../utils/Parser";
import ProductSlider from "./ProductSlider";
import equal from "fast-deep-equal/react";
import Added from "../../shop/productCard/Added";
import Notify from "../../shop/productCard/Notify";
import Name from "./Name";
import PriceTag from "./PriceTag";
import ProductName from "./ProductName";
import ProductMainFeatures from "./ProductMainFeatures";
import {cx} from "emotion";
import ProductSpecSheet from "./ProductSpecSheet";
import ProductDescSheet from "./ProductDescSheet";
import Count from "./Count";
import Button from "./Button";
import SelectVariants from "./SelectVariants";
import Backorders from "../../shop/productCard/Backorders";
import Validator from "../../../utils/Validator";

const Line = (props:any) =>
  <span css={css`background-color: #303030;`} className={cx(props.className, 'inline-bock h-1 flex w-full')} />

const ProductCard: FC<{product: Product}> = ({product}) => {
  let timer: any;
  const dispatch = useDispatch();
  const [added, setAdded] = useState(false)
  const [notification, setNotification] = useState({type: '', on: false})
  const soldOut = ['tactot', 'tactot-for-backpack-pc'].includes(product.id)
  const cartItems = useSelector(cartItemsSelector)
  const [countable, setCountable] = useState(true)
  const [range, setRange] = useState({
    max: 9,
    offset: 0
  })

  const [candidate, setCandidate] = useState<{
    count: number,
    variants: undefined | Variant[]
  }>({
    count: 1,
    variants: undefined
  })

  useEffect(() => {
    return () => {
      if (!!timer) {
        clearTimeout(timer)
      }
    }
  }, [])

  useEffect(() => {
    if (!!product && soldOut) {
      setCandidate((state: any) => ({
        ...state,
        count: 0
      }))
    }
    if (!!product.variants && !!product.variants.length) {
      const types = Parser.getVariantsTypes(product.variants)
      setCandidate((state: any) => ({
        ...state,
        variants: types.map((type) => ({type, kind: ''}))
      }))
    }
  }, [product])

  useEffect(() => {
    trySetRange()
    tryActivateCount()
  }, [cartItems, candidate.variants])

  const tryActivateCount = () => {
    const canVariantsSelectable = Array.isArray(product.variants) && product.variants.length >= 2;
    if (canVariantsSelectable && candidate.variants) {
      const allSettled = candidate.variants
        .map((variant) => Validator.getAllValues(variant))
        .reduce((prev, current, index) => prev.concat(current), [])
        .filter(value => value === '').length === 0
      setCountable(allSettled)
    }
    else {
      setCountable(true)
    }
  }

  const trySetRange = () => {
    let filtered = cartItems.filter((cartItem) => cartItem.id === product.id)
    if (filtered.length) {
      if (!!candidate.variants) {
        filtered = filtered.filter((cartItem) => equal(cartItem.variants, candidate.variants))
        if (!!filtered[0]) {
          setRange((state:any) => ({...state, offset: filtered[0].qty}))
        }
        else {
          setRange((state:any) => ({...state, offset: 0}))
        }
      }
      else {
        setRange((state:any) => ({...state, offset: filtered[0].qty}))
      }
    }
  }

  const images = importFiles(require.context("./images/desktop", false, /\.(png|jpe?g|svg)$/));
  const imagesKeys = Object.keys(images).filter(key =>
    new RegExp(`^${product.id}-[0-9].png`, "g").test(key)
  );
  const srcs = imagesKeys.map((key) => images[key])
  const variants = 'variants' in product ? product.variants : undefined
  let clickable = !!candidate.count && (range.max > range.offset);

  if (candidate.variants !== undefined) {
    clickable = clickable && !candidate.variants.filter((variant) => !variant.kind).length
  }

  const updateCount = (count: number) => {
    setCandidate((state: any) => ({...state, count}))
  }

  const addToCart = (e:any) => {
    let filtered = cartItems.filter((cartItem) => cartItem.id === product.id)
    if (!filtered.length) {
      dispatch(addCartItem({...product, checked: true, variants: candidate.variants, qty: candidate.count}))
      setCandidate((state: any) => ({...state, count: range.max > candidate.count ? 1 : 0}))
    }
    else {
      filtered = filtered.filter((cartItem) => equal(cartItem.variants, candidate.variants))
      if (!!filtered[0]) {
        dispatch(updateCartItemV2({...filtered[0], qty: filtered[0].qty + candidate.count}))
        setCandidate((state: any) => ({...state, count: range.max > (filtered[0].qty + candidate.count) ? 1: 0}))
      }
      else {
        dispatch(addCartItem({...product, checked: true, variants: candidate.variants, qty:candidate.count}))
        setCandidate((state: any) => ({...state, count: range.max > candidate.count ? 1 : 0}))
      }
    }
    setAdded((state:any) => {
      timer = setTimeout(() => {
        setAdded(false)
      }, 1000)
      return true
    })
  }

  const buyNow = (e:any) => {
    const accessoriesOnly =
      ((!cartItems || !cartItems.length) && product.category === 'accessory') ||
      (CartUtil.accessoriesOnly(cartItems) && product.category === 'accessory')

    if (accessoriesOnly) {
      setNotification({type: 'notAccessoriesOnly', on: true})
    }
    else {
      addToCart(e)
      dispatch(push(Routes.CheckoutPage));
    }
  }

  const updateVariants = (variants: Variant[]) => {
    setCandidate((state:any) => ({...state, variants}))
  }

  const handleNotify = (actionType: string) => (e:any) => {
    setNotification((state: any) => ({...state, on: false}))
    if (actionType === 'cancel') {}
    else if (actionType === 'contact') {
      window.open("mailto:contact@bhaptics.com", "_top");
    }
  }

  const hidden = ['mesh-lining-for-tactsuit-x'].includes(product.id)

  return (
    <div className={"w-full bg-black mx-auto"}>
      {added && !notification.on && <Added />}
      {notification.on && <Notify type={notification.type} onNotify={handleNotify} />}
      <div
        css={css`
          background: #0f1014;
            @media(max-width: 1279px) {
              padding-left: 0rem;
              padding-right: 0rem;
            }
        `}
        className={"max-w-screen-xl lg:py-20 xl:py-32 lg:px-20 xl:px-36 mx-auto w-full text-white items-center md:items-start justify-center flex-col md:flex-row flex"}
      >
        <ProductSlider className={'max-w-full md:max-w-screen-xs'} slides={{srcs: srcs, id: product.id}} />
        <div className={'flex-grow w-full md:mx-3.5 py-2 relative flex flex-col'}>
          <div className={'relative px-5 md:px-0 flex justify-between'}>
            <Name id={product.id} />
            <PriceTag className={'invisible'} price={soldOut ? 0 : product.price} />
            <PriceTag className={'absolute pr-5 md:pr-0 right-0'} price={soldOut ? 0 : product.price} />
          </div>
          <div className={'px-5 md:px-0 mt-6 flex items-baseline flex-wrap justify-between'}>
            <div className={'flex flex-col'}>
              <ProductName id={product.id} />
              <ProductMainFeatures className={'mt-1'} id={product.id} />
              <Backorders className={'mt-1.5'} id={product.id} />
            </div>
          </div>
          <Line className={cx('mt-4', {'hidden': hidden})} />
          <ProductSpecSheet className={cx('mt-2', {'hidden': hidden})} id={product.id} />
          <ProductDescSheet className={cx('mt-2', {'hidden': hidden})} id={product.id} />
          <Line className={'mt-2'} />
          <SelectVariants
            css={css`pointer-events: auto;`}
            id={product.id} onVariants={updateVariants} variants={variants} />
          {!!variants && <Line className={'mt-2'} />}
          <div
            css={css`& * {pointer-events: ${soldOut ? 'none' : ''}`}
            className={'px-5 md:px-0 flex py-2 items-center justify-between'}>
            <Count
              css={css`pointer-events: ${countable ? 'auto' : 'none'}`}
              count={candidate.count} onCount={updateCount}
                   className={cx({'opacity-25': soldOut}, 'flex-grow h-16 xs:h-full')} max={range.max - range.offset} />
            <div
              className={'grid grid-cols-2 h-full gap-x-2'}>
              <Button
                css={css`
                  & > span {
                    color: ${soldOut ? '#606060' : product.category === 'accessory' ? '#ffb400;' : ''};
                  }
                `}
                id={'addToCart'}
                className={'lg:w-40 px-2'}
                onClick={addToCart}
                clickable={clickable}
                style={{
                  borderColor: `${soldOut ? '#606060' : product.category === 'hardware' ? '#0091ff' : '#ffb400'}`,
                  backgroundColor: '#1e1e21'}}
              >
              </Button>
              <Button
                id={'buyNow'}
                onClick={buyNow}
                className={'lg:w-40 px-2'}
                style={{
                  borderColor: `${soldOut ? '#606060' : product.category === 'hardware' ? '#0091ff' : 'transparent'}`,
                  backgroundColor: `${soldOut ? '#606060' : product.category === 'hardware' ? '#0091ff' : '#ffb400'}`}}
                clickable={clickable}
              >
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
