/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useState} from "react";
import discord from './images/img-sns-discord.svg';
import facebook from './images/img-sns-facebook.svg';
import instagram from './images/img-sns-instagram.svg';
import twitter from './images/img-sns-twitter.svg';
import wechat from './images/img-sns-wechat.svg';
import weibo from './images/img-sns-weibo.svg';
import youtube from './images/img-sns-youtube.svg';
import wechatQrCode from './images/wechatQrCode.jpg'
import {cx} from "emotion";
import {AiOutlineCloseCircle} from 'react-icons/ai'
import Portal from "../portal";

type Props = {
  className?: string
}

const snsList = [
  {src: discord, href: 'https://discord.com/invite/EYY2vAQP2u', target: '_blank', key: 'discord'},
  {src: facebook, href: 'https://www.facebook.com/bHaptics-280788279093649/', target: '_blank', key: 'facebook'},
  {src: twitter, href: 'https://twitter.com/bHaptics', target: '_blank', key: 'twitter'},
  {src: instagram, href: 'https://www.instagram.com/bhaptics/', target: '_blank', key: 'instagram'},
  {src: youtube, href: 'https://www.youtube.com/channel/UCGyV7l7iZz9tUtEg4wvsOpA', target: '_blank', key: 'youtube'},
  {src: wechat, href: '#', target: '_self', key: 'wechat'},
  {src: weibo, href: 'http://weibo.com/7426973110', target: '_blank', key: 'weibo'},
]

const SnsList:FC<Props> = ({className=''}) => {
  const [open, setOpen] = useState(false)

  const toggleOpen = (e: any) => {
    setOpen(!open)
  }

  const onDesktop = `min-width: 1.875rem; width: 1.875rem; height: 1.875rem`
  const onMobile = `min-width: 1.625rem; width: 1.625rem; height: 1.625rem`

  return (
    <div className={cx(className, 'flex items-center')}>
      {
        snsList.map((snsItem: any, index) => {
          return (
            snsItem.key === 'wechat' ?
              <img
                css={css`${onDesktop};@media(max-width: 1023px) {${onMobile}}`}
                className={'opacity-40 hover:opacity-100 ml-2.5 cursor-pointer'} key={index} src={snsItem.src}
                onClick={toggleOpen} />
              :
              <a key={index} href={snsItem.href} className={'opacity-40 ml-2.5 hover:opacity-100'} target={snsItem.target}>
                <img
                  css={css`${onDesktop};@media(max-width: 1023px) {${onMobile}}`}
                  src={snsItem.src} />
              </a>

          )
        })
      }
      {
        open &&
          <Portal
            css={css` 
              position: fixed;
              color: white;
              min-height: 100vh;
              height: 100%;
              top: 0; bottom: 0; left: 0; right: 0;
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <div className={'max-w-screen-xs z-10 p-2 w-full mx-auto flex flex-col'}>
              <div className={'flex items-center justify-between'}>
                <span className={'font-bold leading-tight'}>Please scan QR code</span>
                <AiOutlineCloseCircle
                  className={'opacity-50 hover:opacity-100 cursor-pointer text-3xl my-2 -mr-1'} onClick={toggleOpen} />
              </div>
              <img src={wechatQrCode} />
            </div>
            <div className={'absolute inset-0 bg-black opacity-75'} />
          </Portal>
      }
    </div>
  )
}

export default SnsList