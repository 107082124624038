import { createSelector } from "reselect";
import { RootState } from "../reducers";
import {ShippingState} from "../reducers/shipping";

export const shippingSelector = (state: RootState): ShippingState => state.shipping;

export const shippingCourierSelector = createSelector(
  [shippingSelector],
  (shipping: ShippingState) => shipping.courier
);

export const shippingPriceSelector = createSelector(
  [shippingSelector],
  (shipping: ShippingState) => shipping.price
);