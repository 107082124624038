/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {localeSelector} from "../../store/selectors/configSelector";
import {Locale} from "../../store/reducers/config";
import Locales from "./Locales";
import {cx} from "emotion";
import {translationGeneralSelector} from "../../store/selectors/translationSelector";
import Validator from "../../utils/Validator";
import styled from "@emotion/styled";

const Triangle = styled.div`
width: 0;
height: 0;
border-left: 9px solid transparent;
border-right: 9px solid transparent;
border-top: 9px solid #898989;
`

type Props = {
  className?:string
}

const SelectLanguage:FC<Props> = ({className=''}) => {
  const ref = useRef<HTMLDivElement>(null)
  const locale:Locale = useSelector(localeSelector);
  const translationGeneral = useSelector(translationGeneralSelector)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (ref.current) {
      document.addEventListener('click', handleClick)
    }
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [ref.current])

  const handleClick = (e: any) => {
    e.stopPropagation()
    if (!!ref.current && ref.current.contains(e.target)) {

    }
    else {
      if (open) {
        setOpen(!open)
      }
      else {
        setOpen(false)
      }
    }
  }

  const handleOpen = (e: any) => {
    e.stopPropagation()
    if (!open) {
      setOpen(true)
    }
  }

  const handleClose = (e: any) => {
    e.stopPropagation()
    if (open) {
      setOpen(false)
    }
  }

  return (
    <div className={'flex items-center justify-start'}>
      <span
        css={css`min-width: 8rem`}
        className={'text-white whitespace-no-wrap w-32 mr-4 text-sm md:text-base leading-tight'}>{Validator.tryGetValue(translationGeneral, 'selectLanguage', locale.languageCode)}</span>
      <div
        ref={ref}
        css={css`border-color: #4d4d4d`}
        // onMouseOver={handleOpen}
        onClick={handleOpen}
        className={cx(
          open ? 'hover:opacity-100' : 'hover:opacity-75',
          'flex relative cursor-pointer p-1 w-full mt-1 items-center border rounded justify-between'
        )}>
        <span className={'text-white ml-2'}>{locale.languageName}</span>
        <Triangle className={'ml-4 sm:ml-8 ml:ml-16 lg:ml-24 xl:ml-32 mr-2'} />
        {
          open &&
          <Locales
            css={css`
              & span {
                color: white;
              }
              background-color: #1e1e21;
              border-color: #4d4d4d;
            `}
            onClose={handleClose}
            className={'absolute bottom-0 right-0 w-full  mr-2 mb-2 z-10'}/>
        }
      </div>
    </div>
  )
}

export default SelectLanguage