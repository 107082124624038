/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useEffect, useState} from "react";
import {cx} from "emotion";
import {useSelector} from "react-redux";
import {translationGeneralSelector} from "../../../store/selectors/translationSelector";
import {localeSelector} from "../../../store/selectors/configSelector";
import Validator from "../../../utils/Validator";

const Button = (props:any) => {
  const {onClick, clickable, children, id, soldOut, ...rest} = props
  const translationGeneral = useSelector(translationGeneralSelector)
  const locale = useSelector(localeSelector);
  let label = Validator.tryGetValue(translationGeneral, id, locale.languageCode)
  if (!label) {
    label = id === 'buyNow' ? "Buy Now" : 'Add to Cart'
  }

  return (
    <button
      {...rest}
      onClick={onClick}
      className={cx(
        clickable ? 'opacity-75 hover:opacity-100' : 'opacity-30 pointer-events-none',
        'border-2 font-bold py-1.5'
      )}
    >
      <span css={css`color: ${soldOut && id !== 'buyNow' ? '#606060' : id === 'addToCart' ? '#0091ff;' : ''}`} className={'text-sm'}>{label}</span>
    </button>
  )
}

export default Button