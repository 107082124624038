/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useEffect, useRef, useState} from "react";
import {cx} from "emotion";
import {useSelector} from "react-redux";
import {localeSelector} from "../../../store/selectors/configSelector";
import {translationGeneralSelector} from "../../../store/selectors/translationSelector";
import Validator from "../../../utils/Validator";

type Props = {
  className?:string,
  price: number
}

const PriceTag:FC<Props> = ({className='', price}) => {
  const locale = useSelector(localeSelector);
  const translationGeneral = useSelector(translationGeneralSelector)
  const soldOut = Validator.tryGetValue(translationGeneral, 'soldOut', locale.languageCode)

  return (
    <div className={cx(className, 'flex self-center rounded-full')}>
      <span className={'font-bold text-xl xl:text-3xl text-white leading-none'}>
        {price === 0 ? soldOut : `$ ${price}`}
      </span>
    </div>
  )
}

export default PriceTag

