import produce from "immer";
import {Personal} from "../../interfaces/personal";
import {Address} from "../../models/Address";

export interface PayerState {
  personal: Personal,
  address: Address
}

const initialState: PayerState = {
  personal: {
    email: '',
    firstName: '',
    lastName: '',
    tel: '',
  },
  address: {
    address1: '',
    address2: '',
    zip: '',
    city: '',
    state: '',
    country: {
      iso_2: '',
      iso_3: '',
      name: '',
    }
  }
}

export enum PayerActionType {
  UpdatePayer = "payer/updatePayer",
  UpdatePayerPersonal = "payer/updatePayerPersonal",
  UpdatePayerAddress= "payer/updatePayerAddress",
}

type PayerAction =
  | {type: PayerActionType.UpdatePayer; payload: PayerState}
  | {type: PayerActionType.UpdatePayerPersonal; payload: Personal}
  | {type: PayerActionType.UpdatePayerAddress; payload: Address}

export const payer = produce(
  (draft: PayerState=initialState, action: PayerAction) => {
    switch (action.type) {
      case PayerActionType.UpdatePayer: {
        draft =  action.payload;
        return draft
      }
      case PayerActionType.UpdatePayerPersonal: {
        draft.personal =  action.payload;
        return draft
      }
      case PayerActionType.UpdatePayerAddress: {
        draft.address =  action.payload;
        return draft
      }
      default:
        return draft
    }
  }
)
