import { CartItem } from "../store/reducers/cart";
import Env from "../env/Env";

class CartLocalStorageService {
  private cartPrefix = `${Env.CartPrefix}`;
  private localStorage: any;
  constructor() {
    this.localStorage = localStorage;
  }

  public setCart = (cart: CartItem[]) => {
    const data = {
      cart,
      time: new Date().getTime()
    };
    this.localStorage.setItem(this.cartPrefix, JSON.stringify(data));
  };

  public getCart = (): CartItem[] => {
    const dataStr = this.localStorage.getItem(this.cartPrefix);
    const data = JSON.parse(dataStr);
    if (!!data) {
      const currentTime = new Date().getTime();
      if (!!data.time && currentTime - data.time > 1000 * 3600 * 24) {
        return [];
      }
      try {
        return data.cart;
      } catch (e) {
        console.log(e);
      }
    }
    return [];
  };
}

export default new CartLocalStorageService();
