/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, {FC, useEffect, useRef, useState} from "react";
import {Variant} from "../../../models/Product";
import {cx} from "emotion";
import Parser from "../../../utils/Parser";
import Validator from "../../../utils/Validator";
import {useSelector} from "react-redux";
import {localeSelector} from "../../../store/selectors/configSelector";
import {translationGeneralSelector} from "../../../store/selectors/translationSelector";
import DetailVariant from "./DetailAboutVariant";

type Props = {
  className?:string
  variants?: Variant[],
  onVariants: (variants: Variant[]) => void,
  id: string,
}

const SelectVariants:FC<Props> = ({id, className='', variants=undefined, onVariants}) => {
  const locale = useSelector(localeSelector)
  const translationGeneral = useSelector(translationGeneralSelector)
  const ref:any = useRef()
  const [state, setState] = useState<any>({
    variants: []
  })
  const [detail, setDetail] = useState({
    on: false,
    type: '',
    kind: ''
  })

  const handleClickOutside = ({ target }:any) => {
    if (ref.current && !ref.current.contains(target))  {
      setDetail((state: any) => ({...state, on: false}));
    }
    document.removeEventListener("click",handleClickOutside);
  };

  useEffect(() => {
    if (!!variants && !!variants.length) {
      const types = Parser.getVariantsTypes(variants)
      setState((state:any) => ({...state, variants: types.map((type, index) => ({type, kind: ''}))}))
    }
  }, [variants])

  let merged:any = null
  if (!!variants && !!variants.length) {
    merged = Parser.groupVariantsByTypes(variants)
  }

  const updateVariant = (type: string, kind: any) => (e:any) => {
    document.addEventListener("click",handleClickOutside);
    const nextVariants = state.variants.map((variant: any, index: number) => variant.type === type ? {type, kind} : variant)
    setState((state:any) => ({
      ...state,
      variants:nextVariants
    }))
    setDetail((state:any) => ({...state, kind, type, on: true}))
    ref.current = e.target
    onVariants(nextVariants)
  }

  const toggleDetail = (e:any) => {
    e.stopPropagation()
    setDetail((state: any) => ({...state, on: !state.on}))
    document.removeEventListener("click",handleClickOutside);
  }

  return (
    <div className={cx(className)}>
      {
        !!merged &&
        Object
          .keys(merged)
          .map((key, index) => {
            const kinds:string[] = merged[key]
            const friendly = (key: string) => {
              const res = Validator.tryGetValue(translationGeneral, `select${key}`, locale.languageCode)
              switch (key) {
                case 'Size': {
                  return !!res ? res : 'Select Size'
                }
                case 'TactSuit': {
                  return !!res ? res : 'Select TactSuit X'
                }
                default: {
                  return !!res ? res : `Select ${key}`
                }
              }
            }

            let type = Validator.tryGetValue(translationGeneral, key, locale.languageCode)
            if (!type) {
              type = key
            }
            const filtered = state.variants.filter((variant: any, index: number) => variant.type === key)
            const varianted = !!filtered && !!filtered.length && !!filtered[0].kind

            return (
              <div className={'flex py-1.5 flex-col'} key={index}>
                <span className={cx('font-bold text-base', {'hidden': varianted})}>
                  {friendly(key)}
                </span>
                <div className={'flex items-center mt-2.5 flex-wrap'}>
                  <span className={'capitalize text-sm flex-grow font-bold'}>{type}</span>
                  <div className={'flex items-center'}>
                    {
                      kinds.map((kind, kindIndex) => {
                        const single = kinds.length === 1
                        const on = !!filtered.length && filtered[0].kind === kind
                        let _kind = Validator.tryGetValue(translationGeneral, kind, locale.languageCode)
                        if (!_kind) {
                          _kind = kind;
                        }
                        const target = detail.on && detail.type === key && detail.kind === kind
                        return (
                          <div
                            onClick={updateVariant(key, kind)}
                            css={css`
                              border-color: ${on ? '#0f0f11' : '#8e8e90;'}; 
                              background-color: ${on ? '#0091ff;' : '#0f0f11'};
                            `}
                            className={
                              cx(
                                on ? '' : 'opacity-50 hover:opacity-100 cursor-pointer',
                                kindIndex === 0 && single ? '' : 'ml-4',
                                'py-2 px-2 relative flex items-center rounded-full border'
                              )
                            }
                            key={kindIndex}
                          >
                            {target && <DetailVariant id={id} ref={ref} onToggle={toggleDetail} variant={{type: detail.type, kind: detail.kind}} />}
                            <span className={'capitalize leading-none pointer-events-none text-sm font-bold'}>{_kind}</span>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            )
          })
      }
    </div>
  )
}

export default SelectVariants