import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { cartItemsSelector } from "../../store/selectors/cartSelector";
import { CartItem } from "../../store/reducers/cart";
import { useCookies } from "react-cookie";
import Env from "../../env/Env";

type Props = {};

const Cookies: FC<Props> = () => {
  const cartItems = useSelector(cartItemsSelector);
  const [cookies, setCookie, removeCookie] = useCookies([`${Env.CartPrefix}`]);
  const cartItemsQty = cartItems.reduce(
    (prev: number, current: CartItem, index: number) => prev + current.qty,
    0
  );

  useEffect(() => {
    setCookie(`${Env.CartPrefix}`, cartItemsQty, {
      path: "/",
      domain: `${Env.Cookie.Domain}`
    });
  }, [cartItemsQty]);

  return null;
};

export default Cookies;
