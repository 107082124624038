import {Address} from "../../models/Address";
import {Item} from "./cart";

export interface Offer {
  id: string;
  purchased: boolean;
  discountPrice: number;
  shippingAddress?: Address;
  createTime?: Time;
  memo?: string;
  shippingPrice: number;
  editTime?: Time;
  items: OfferItem[];
}

export interface Time {
  _seconds: number;
  _nanoseconds: number;
}

export interface OfferItem extends Item {
  qty: number;
  desc?: string;
  hsCode: string;
}

export const initialOffer: Offer = {
  items: [],
  id: "",
  purchased: false,
  shippingPrice: 0,
  discountPrice: 0,
  editTime: undefined,
  createTime: undefined,
  memo: "",
  shippingAddress: undefined
};

export enum OfferActionType {
  FetchOffer = "offer/fetchOffer",
  UpdateOffer = "offer/updateOffer",
  UpdateShippingAddress = "offer/updateShippingAddress"
}

type OfferAction =
  | { type: OfferActionType.UpdateOffer; payload: Offer }
  | { type: OfferActionType.UpdateShippingAddress; payload: Address }
  | { type: OfferActionType.FetchOffer; payload: Offer };

export const offer = (state: Offer = initialOffer, action: OfferAction) => {
  switch (action.type) {
    case OfferActionType.UpdateOffer: {
      return action.payload;
    }
    case OfferActionType.FetchOffer: {
      return action.payload;
    }
    case OfferActionType.UpdateShippingAddress: {
      return { ...state, shippingAddress: action.payload };
    }
    default:
      return state;
  }
};
