/** @jsx jsx */
import {css, jsx} from "@emotion/core";
import React, {FC} from "react";
import {cx} from "emotion";
import logo from './images/img-ci.png'
import mobileLogo from './images/mobile-img-ci.png'
import Copyright from "./Copyright";
import SnsList from "./SnsList";
import Newsletter from "./Newsletter";
import SelectLanguage from "../selectLanguage";
import BusinessRegistration from "./BusinessRegistration";

type Props = {
  className?:string
}

const subMenus = [
  {
    head: 'Tactsuit',
    list: [
      {label: 'TactSuit X40', href: 'https://www.bhaptics.com/tactsuit/tactsuit-x40'},
      {label: 'TactSuit X16', href: 'https://www.bhaptics.com/tactsuit/tactsuit-x16'},
      {label: 'Compare', href: 'https://www.bhaptics.com/tactsuit/compare'},
      {label: 'Tactal', href: 'https://www.bhaptics.com/tactsuit/tactal'},
      {label: 'Tactosy for Arms', href: 'https://www.bhaptics.com/tactsuit/tactosy-for-arms'},
      {label: 'Tactosy for Hands', href: 'https://www.bhaptics.com/tactsuit/tactosy-for-hands'},
      {label: 'Tactosy for Feet', href: 'https://www.bhaptics.com/tactsuit/tactosy-for-feet'}
    ]
  },
  {
    head: 'Experiences',
    list: [
      {label: 'VR', href: 'https://www.bhaptics.com/experiences/vr'},
      {label: 'PC', href: 'https://www.bhaptics.com/experiences/pc'},
      {label: 'Console', href: 'https://www.bhaptics.com/experiences/console'},
      {label: 'Movie/Music', href: 'https://www.bhaptics.com/experiences/movie-music'}
    ]
  }
]

const legalMenus = [
  {label: 'Terms and Conditions', href: 'https://www.bhaptics.com/legals/terms-and-conditions/'},
  {label: 'Privacy Policy', href: 'https://www.bhaptics.com/legals/privacy-policy/'},
  {label: 'Return and Refund', href: 'https://www.bhaptics.com/legals/cancellation-return-refund/'},
  {label: 'Legal Term', href: 'https://www.bhaptics.com/legals/'},
]

const Footer:FC<Props> = ({className=''}) => {
  const breakpoint = '767px'
  return (
    <div className={cx(className, 'w-full bg-black text-white')}>
      <div
        css={css`@media(max-width: ${breakpoint}) {padding-top: 2.75rem}`}
        className={'max-w-screen-xl pt-14 px-5 md:px-10 mx-auto flex flex-col justify-center items-center'}>
        <div
          css={css`@media(max-width: ${breakpoint}) {flex-direction: column; align-items: center}`}
          className={'flex flex-row w-full'}>
          <div className={'flex-grow'}>
            <img className={'hidden md:block'} src={logo} />
            <img className={'block md:hidden'} src={mobileLogo} />
          </div>
          <div
            css={css`
              @media(max-width: ${breakpoint}) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
                padding-left: 0;
                width: 100%;
              }
`           }
            className={'pl-4 grid grid-cols-4 gap-x-4 md:gap-x-10 lg:gap-x-24'}>
            {
              subMenus.map((subMenu, index) => (
                <div
                  css={css`
                    @media(max-width: ${breakpoint}) {display: none}
                  `}
                  className={'flex flex-col'} key={index}>
                  <span className={'font-bold text-sm'}>{subMenu.head}</span>
                  <div className={'flex mt-2.5 flex-col'}>
                    {
                      subMenu.list.map((listItem, subIndex) => (
                        <a href={listItem.href} key={subIndex}>
                            <span className={'whitespace-no-wrap cursor-pointer text-xs md:text-sm opacity-40 hover:opacity-60'}>{listItem.label}</span>
                        </a>
                      ))
                    }
                  </div>
                </div>
              ))
            }
            <div
              css={css`@media(max-width: 767px) {display: flex; flex-direction: column; align-items: center}`}
              className={'col-span-2'}><Newsletter /></div>
          </div>
        </div>
        <span
          css={css`@media(max-width: ${breakpoint}) {display: none}`}
          className={'opacity-20 h-px w-full mt-14 bg-white'}
        />
        <div className={'mt-5 w-full'}>
          <div className={'flex justify-center md:justify-start'}>
            <SelectLanguage />
          </div>
        </div>
        {/*<BusinessRegistration className={'hidden md:flex mt-5 w-full'} />*/}
        <div
          css={css`@media(max-width: ${breakpoint}) {flex-direction: column; padding-top: 2.5rem; padding-bottom: 1.875rem}`}
          className={'flex w-full pt-5 pb-10 items-center'}>
          <Copyright
            css={css`@media(max-width: ${breakpoint}) {order: 4; margin-top: 1.875rem}`}
            className={'whitespace-no-wrap opacity-30 text-xs md:text-sm'} name={'bHaptics Inc.'}
          />
          <div
            css={css`
              @media(max-width: ${breakpoint}) {
                order: 1; 
                padding-left: 0; 
                flex-direction: column; 
                & * {font-size: 13px;}
                & > div {
                  margin-bottom: .75rem;
                }
              }`}
            className={'flex w-full pl-14 items-center'}>
            {
              legalMenus.map((legalMenu, index) => {
                const isFirst = index === 0
                return (
                  <div key={index} className={'flex items-center'}>
                    <span
                      css={css`background-color: #cccccc;`}
                      className={cx(
                        {'hidden': isFirst},
                        'w-px mx-3 h-3 opacity-30 pointer-events-none hidden md:inline-block'
                      )}
                    />
                    <a href={legalMenu.href}>
                      <span className={'cursor-pointer text-sm leading-tight opacity-60 active:opacity-100 md:opacity-30 hover:opacity-60'}>
                        {legalMenu.label}
                      </span>
                    </a>
                  </div>
                )
              })
            }
          </div>
          <SnsList
            css={css`@media(max-width: ${breakpoint}) {order: 2; margin-top: 2rem}`}
            className={'flex-grow'}
          />
          {/*<BusinessRegistration
            css={css`order: 3;`}
            className={'flex md:hidden mt-5 w-full'} />*/}
        </div>
      </div>
    </div>
  )
}

export default Footer