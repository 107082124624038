import { createSelector } from "reselect";
import { RootState } from "../reducers";
import {TranslationState} from "../reducers/translation";
import {arrayToObject} from "../../utils/CommonUtil";

export const translationSelector = (state: RootState): TranslationState => state.translation;

export const translationGeneralSelector = createSelector(
  [translationSelector],
  (translation: TranslationState) => {
    if (Array.isArray(translation.general)) {
      return arrayToObject(translation.general, 'key')
    }
    return translation.general
  }
);

export const translationSupportSelector = createSelector(
  [translationSelector],
  (translation: TranslationState) => translation.support
);

export const translationNoticeSelector = createSelector(
  [translationSelector],
  (translation: TranslationState) => {
    if (Array.isArray(translation.notice)) {
      return arrayToObject(translation.notice, 'category')
    }
    return translation.notice
  }
);

export const translationComesWithSelector = createSelector(
  [translationSelector],
  (translation: TranslationState) => {
    if (Array.isArray(translation.comesWith)) {
      return arrayToObject(translation.comesWith, 'device')
    }
    return translation.comesWith
  }
);

export const translationBatterySelector = createSelector(
  [translationSelector],
  (translation: TranslationState) => {
    if (Array.isArray(translation.battery)) {
      return arrayToObject(translation.battery, 'device')
    }
    return translation.battery
  }
)

export const translationFeatureSelector = createSelector(
  [translationSelector],
  (translation: TranslationState) => {
    if (Array.isArray(translation.feature)) {
      return arrayToObject(translation.feature, 'device')
    }
    return translation.feature
  }
)

export const translationProductSpecsSelector = createSelector(
  [translationSelector],
  (translation: TranslationState) => {
    if (Array.isArray(translation.productSpecs)) {
      return arrayToObject(translation.productSpecs, 'device')
    }
    return translation.productSpecs
  }
)

export const translationProductDetailsSelector = createSelector(
  [translationSelector],
  (translation: TranslationState) => {
    if (Array.isArray(translation.productDetails)) {
      return arrayToObject(translation.productDetails, 'device')
    }
    return translation.productDetails
  }
)

export const translationMessagesSelector = createSelector(
  [translationSelector],
  (translation: TranslationState) => {
    if (Array.isArray(translation.messages)) {
      return arrayToObject(translation.messages, 'device')
    }
    return translation.messages
  }
)

export const translationOverviewSelector = createSelector(
  [translationSelector],
  (translation: TranslationState) => {
    if (Array.isArray(translation.overview)) {
      return arrayToObject(translation.overview, 'device')
    }
    return translation.overview
  }
)