import React, {FC, useEffect, useState} from "react";
import {cx} from "emotion";
import Validator from "../../utils/Validator";
import {css, jsx} from "@emotion/core";
import {useDispatch, useSelector} from "react-redux";
import {updateRecipientAddress} from "../../store/actions/recipientAction";
import {localeSelector} from "../../store/selectors/configSelector";
import {translationGeneralSelector} from "../../store/selectors/translationSelector";
import {countriesSelector} from "../../store/selectors/countriesSelector";
import Shipping, {CountryState} from "../../firebase/Shipping";
import {checkedCartItemsSelector} from "../../store/selectors/cartSelector";
import {updateShippingPrice} from "../../store/actions/shippingAction";
import {updatePayerAddress} from "../../store/actions/payerAction";
import {locationSelector} from "../../store/selectors/routerSelector";
import {Address} from "../../models/Address";
import {CartState} from "../../store/reducers/cart";
import {shipping} from "../../store/reducers/shipping";

type Props = {
  className?:string
  useFor: 'recipient' | 'payer',
  address: Address,
}

const Input = (props: any) => {
  const {className, ...rest} = props
  return  (
    <input
      autoComplete={'on'}
      className={cx(className, 'appearance-none border rounded w-full py-2 px-3 focus:outline-none')}
      {...rest} />
  )
}

const alphanumeric = /^[a-z0-9 !@#$%^&*()\-_=+:;.`'",\t\|\\/?<>~[\]{}]+$|^$/i

const InputAddress:FC<Props> = ({className="", useFor, address}) => {
  const checkedCartItems = useSelector(checkedCartItemsSelector)
  const dispatch = useDispatch()
  const locale = useSelector(localeSelector)
  const translationGeneral = useSelector(translationGeneralSelector)
  const countries = useSelector(countriesSelector)
  const [countryStates, setCountryStates] = useState<CountryState[]>([]);
  const [state, setState] = useState({
    address1: '',
    address2: '',
    zip: '',
    city: '',
    state: '',
    country: {
      id: "",
      iso_2: "",
      iso_3: "",
      name: ""
    }
  })

  useEffect(() => {
    setState((state:any) => ({...state, ...address}))
  }, [])

  useEffect(() => {
    if (state.country.iso_2 && useFor === 'recipient') {
      Shipping.getPrice({
        country_iso_2: state.country.iso_2,
        cartItems: checkedCartItems
      }).then((shippingPrice) => {
        dispatch(updateShippingPrice(shippingPrice))
      })
    }
    else {
      dispatch(updateShippingPrice(0))
    }
  }, [state.country.iso_2, checkedCartItems])

  useEffect(() => {
    Shipping.getCountryStates(state.country.iso_2).then((states) => {
      setState((state:any) => ({...state, state: ""}))
      setCountryStates(states);
    });
  }, [state.country.iso_2 ==="US",state.country.iso_2 ==="CA"])

  useEffect(() => {
    dispatch(updateRecipientAddress({...address, ...state}))
    dispatch(updatePayerAddress({...address, ...state}))
  }, [state])

  const location = useSelector(locationSelector)

  const canChangeSelect = !location.pathname.startsWith("/offer/") || useFor === 'payer'

  const handleSelect = async (e:any) => {
    const name = e.target.name;
    const value = e.target.value;
    const country = countries[value];
    if (name === 'country') {
      setState((state:any) => ({...state, country}))
    }
  }

  const handleChange = (e:any) => {
    const name = e.target.name
    const value = e.target.value
    if (name === "street-address address-line1") {
      if (alphanumeric.test(value)) {
        setState((state:any) => ({...state, address1: value}))
      }
    }
    else if (name === 'street-address address-line2') {
      if (alphanumeric.test(value)) {
        setState((state:any) => ({...state, address2: value}))
      }
    }
    else if (name === 'postal-code zip-code zip') {
      setState((state:any) => ({...state, zip: value}))
    }
    else if (name === 'address-level2 city') {
      setState((state:any) => ({...state, city: value}))
    }
    else if (name === 'address-level1 region') {
      setState((state:any) => ({...state, state: value}))
    }
  }

  return (
    <div className={cx(className, '')}>
      <div className={"mt-3 px-4"}>
        <Input
          name={"street-address address-line1"}
          value={state.address1}
          defaultValue={!address.address1 ? undefined : address.address1}
          onChange={handleChange}
          placeholder={`${Validator.tryGetValue(translationGeneral, 'address', locale.languageCode)} ${locale.languageCode === 'en' ? '1 (In Roman letters)' : ''}`}
        />
      </div>
      <div className={"mt-3 px-4"}>
        <Input
          name={"street-address address-line2"}
          value={state.address2}
          onChange={handleChange}
          defaultValue={!address.address2 ? undefined : address.address2}
          placeholder={`${Validator.tryGetValue(translationGeneral, 'address', locale.languageCode)} ${locale.languageCode === 'en' ? '2 (In Roman letters)' : ''}`}
        />
      </div>
      <div className={"mt-3 px-4"}>
        <select
          disabled={!canChangeSelect}
          name={"country"}
          className={cx("w-full border text-gray-500 h-10.5 px-2 rounded focus:outline-none")}
          value={address.country.name}
          onChange={handleSelect}
        >
          <option value={!address.country.name ? "" : address.country.name}>
            {
              !address.country.name ?
                Validator.tryGetValue(translationGeneral, 'selectYourCountry', locale.languageCode) :
                address.country.name
            }
          </option>
          {!!countries.length && countries.map((country, index) => (
            <option key={index} value={index}>
              {country.name}
            </option>
          ))}
        </select>
      </div>
      <div
        css={css`& > input:last-of-type {margin-right: 0;}`}
        className={"flex flex-row mt-3 px-4"}
      >
        <div className={"flex-1 mr-1"}>
          <Input
            name={"postal-code zip-code zip"}
            value={state.zip}
            onChange={handleChange}
            defaultValue={!address.zip ? undefined : address.zip}
            placeholder={`${Validator.tryGetValue(translationGeneral, 'postalCode', locale.languageCode)}`}
          />
        </div>
        <div className={"flex-1 mx-1"}>
          <Input
            name={"address-level2 city"}
            value={state.city}
            onChange={handleChange}
            defaultValue={!address.city ? undefined : address.city}
            placeholder={`${Validator.tryGetValue(translationGeneral, 'city', locale.languageCode)}`}
          />
        </div>
        <div className={"flex-1 ml-1"}>
        {!!countryStates.length?
          <select
            disabled={!canChangeSelect}
            name={"address-level1 region"}
            className={cx("w-full border text-gray-500 h-10.5 px-2 rounded focus:outline-none")}
            value={state.state}
            onChange={handleChange}
          >
            {
              !state.state &&
              <option>
                {Validator.tryGetValue(translationGeneral, 'state', locale.languageCode)}
              </option>
            }
            {
              countryStates.map((countryState, index) => (
                <option key={index} value={countryState.code}>
                  {countryState.name}
                </option>
              ))
            }
          </select>
          :
          <Input
            name={"address-level1 region"}
            value={state.state}
            onChange={handleChange}
            defaultValue={!address.state ? undefined : address.state}
            placeholder={`${Validator.tryGetValue(translationGeneral, 'state', locale.languageCode)}`}
          />
          }
        </div>
      </div>
    </div>
  )
}

export default InputAddress