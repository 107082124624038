import {TranslationActionType} from "../reducers/translation";
import {readCsvs} from "../../utils/CommonUtil";
import Papa from "papaparse";

export const fetchTranslations = (translations: {[key:string]: string}) => async (dispatch: any, getState: any) => {
  const _translations = Object.keys(translations).map((key:string) => {
    const words = key.split(".")

    return {
      key: words[0],
      path: translations[key]
    }
  })

  readCsvs(..._translations).then((responses: any) => {
    const result: any = {};
    responses.map((response: {request: any, key: string}) => {
      if (response.request) {
        const data = Papa.parse(response.request.responseText, {header: true}).data
        result[response.key] = data
      }
    })

    dispatch({type: TranslationActionType.UpdateTranslation, payload: result})
  })
}