import React, {FC} from "react";
import {Personal} from "../../interfaces/personal";

type Props = {
  personal:Personal
}

const SummarizePersonal:FC<Props> = ({personal}) => {
  return (
    <div className={"p-4"}>
      <div>{personal.email}</div>
      <div>{personal.firstName} {personal.lastName}</div>
      <div>{personal.tel}</div>
    </div>
  )
}

export default SummarizePersonal