import ConfigLocalStorageService from "../../storage/ConfigLocalStorageService";
import locals from '../../components/selectLanguage/data/locales.json';

export interface Locale {
  languageCode: string,
  countryCode: string,
  countryName?: string,
  languageName?: string,
}

export interface ConfigState {
  locale: Locale;
}

const getDefaultLang = () => {
  // jp <-- ja
  // cn <-- zh
  const lang = getLanguage();

  if (!!lang) {
    if (lang.startsWith('zh')) {
      return 'cn';
    }

    if (lang.startsWith('ja')) {
      return 'jp';
    }

    return lang.split('-')[0]

  }
  return lang;
};

// https://stackoverflow.com/questions/673905/best-way-to-determine-users-locale-within-browser
// @ts-ignore
const getLanguage = () => navigator.userLanguage || (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || navigator.systemLanguage || 'en';

const lang = getDefaultLang();

const initialSetting = () : Locale => {
  let find = locals.find(l => l.languageCode === lang);

  if (!!find) {
    return find;
  }

  return locals[0];
}


export const initialConfigState: ConfigState = {
  locale: initialSetting(),
};

export enum ConfigActionType {
  UpdateConfig = "config/updateConfig",
  UpdateLocale = "config/updateLocale",
  InitializeConfig = "config/initializeConfig"
}

type ConfigAction =
  | { type: ConfigActionType.UpdateConfig; payload: ConfigState }
  | { type: ConfigActionType.UpdateLocale; payload: ConfigState }
  | { type: ConfigActionType.InitializeConfig; payload: ConfigState };

export const config = (
  state: ConfigState = ConfigLocalStorageService.getConfig(),
  action: ConfigAction
) => {
  switch (action.type) {
    case ConfigActionType.UpdateConfig: {
      ConfigLocalStorageService.setConfig(action.payload);
      return action.payload;
    }
    case ConfigActionType.UpdateLocale: {
      ConfigLocalStorageService.setConfig(action.payload);
      return action.payload;
    }
    case ConfigActionType.InitializeConfig: {
      ConfigLocalStorageService.setConfig(initialConfigState);
      return initialConfigState;
    }
    default:
      return state;
  }
};
