import React, {FC, useEffect, useState} from 'react'
import {cx} from "emotion";

type Props = {
  className?:string,
  onChange?:(value: any) => void,
  onBlur?:(e:any) => void,
  onFocus?:(e:any) => void,
  value?: string | "",
  type?: string,
  placeholder?: string,
}

const InputEmail:FC<Props> = ({placeholder='E-MAIL', value, type='email', onChange, onBlur, onFocus, className=''}) => {
  const [email, setEmail] = useState('')

  const handleChange = (e:any) => {
    const value = e.target.value;
    setEmail(value);
    if (!!onChange) {
      onChange(value)
    }
  }

  const handleBlur = (e:any) => {}

  const handleFocus = (e:any) => {}

  return (
    <input
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      type={type}
      value={value === undefined ? email : value}
      className={cx(className, 'w-full h-full truncate px-4')} />
  )
}

export default InputEmail